import { handleActions } from 'redux-actions';
import {
  addBackgroundAsync,
  deleteBackgroundAsync,
  getBackgroundsAsync,
  clearGoodsErrorsAsync,
  addCamerModelAsync,
  deleteCamerModelAsync,
  getCamerModelsAsync,
  addGoodAsync,
  deleteGoodAsync,
  editGoodAsync,
  getGoodsAsync,
  getCountrysAsync,
  getStatesAsync,
  getDocsTypesAsync,
} from '../actions/goods.actions';

const initialState = {
  countrys: [],
  states: [],
  docsTypes: [],
  backgrounds: [],
  cameraModels: [],
  goods: [],
  isError: false,
  errors: []
};

const deleteElement = (array, elementId) => {
  var idx = array.indexOf(array.find(f => f._id == elementId));
  if (idx != -1) {
    array.splice(idx, 1);
    return JSON.parse(JSON.stringify(array));
  }
  return JSON.parse(JSON.stringify(array));
}

const editElement = (array, editedElement) => {
  if (array.find(f => f._id == editedElement._id))
    array[array.indexOf(array.find(f => f._id == editedElement._id))] = editedElement
  return JSON.parse(JSON.stringify(array));
}

export default handleActions({
  [getCountrysAsync.success]: (s, a) => ({ ...s, countrys: a.payload.data && a.payload.data.success ? a.payload.data.countrys : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getCountrysAsync.failed]: (s, a) => ({ ...s, countrys: a.payload.data && a.payload.data.success ? a.payload.data.countrys : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getStatesAsync.success]: (s, a) => ({ ...s, states: a.payload.data && a.payload.data.success ? a.payload.data.states : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getStatesAsync.failed]: (s, a) => ({ ...s, states: a.payload.data && a.payload.data.success ? a.payload.data.states : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getDocsTypesAsync.success]: (s, a) => ({ ...s, docsTypes: a.payload.data && a.payload.data.success ? a.payload.data.docsTypes : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getDocsTypesAsync.failed]: (s, a) => ({ ...s, docsTypes: a.payload.data && a.payload.data.success ? a.payload.data.docsTypes : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addBackgroundAsync.success]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? [...s.backgrounds, a.payload.data.background] : s.backgrounds, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addBackgroundAsync.failed]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? [...s.backgrounds, a.payload.data.background] : s.backgrounds, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteBackgroundAsync.success]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? deleteElement(s.backgrounds, a.payload.data.background) : s.backgrounds, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteBackgroundAsync.failed]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? deleteElement(s.backgrounds, a.payload.data.background) : s.backgrounds, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getBackgroundsAsync.success]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? a.payload.data.backgrounds : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getBackgroundsAsync.failed]: (s, a) => ({ ...s, backgrounds: a.payload.data && a.payload.data.success ? a.payload.data.backgrounds : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addCamerModelAsync.success]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? [...s.cameraModels, a.payload.data.cameraModel] : s.cameraModels, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addCamerModelAsync.failed]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? [...s.cameraModels, a.payload.data.cameraModel] : s.cameraModels, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteCamerModelAsync.success]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? deleteElement(s.cameraModels, a.payload.data.cameraModel) : s.cameraModels, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteCamerModelAsync.failed]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? deleteElement(s.cameraModels, a.payload.data.cameraModel) : s.cameraModels, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getCamerModelsAsync.success]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? a.payload.data.cameraModels : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getCamerModelsAsync.failed]: (s, a) => ({ ...s, cameraModels: a.payload.data && a.payload.data.success ? a.payload.data.cameraModels : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addGoodAsync.success]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? [...s.goods, a.payload.data.good] : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addGoodAsync.failed]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? [...s.goods, a.payload.data.good] : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteGoodAsync.success]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? deleteElement(s.goods, a.payload.data.good) : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteGoodAsync.failed]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? deleteElement(s.goods, a.payload.data.good) : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [editGoodAsync.success]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? editElement(s.goods, a.payload.data.good) : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [editGoodAsync.failed]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? editElement(s.goods, a.payload.data.good) : s.goods, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getGoodsAsync.success]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? a.payload.data.goods : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getGoodsAsync.failed]: (s, a) => ({ ...s, goods: a.payload.data && a.payload.data.success ? a.payload.data.goods : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [clearGoodsErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [] }),
  [clearGoodsErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [] }),
}, initialState);
