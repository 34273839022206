import React from 'react';

import RecoveryPage from '../components/Default/Recovery';
import Title from '../components/Default/TitleLayout'

export default ({
  match
}) => {

  return <div>
    <Title>
      <title>DOCS PANEL - RECOVERY</title>
    </Title>
    <RecoveryPage recoveryId={match.params.recovery}/>
  </div>;
};