import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { registerAccount, clearErrors } from '../../../store/actions/users.actions';

export default connect((s) => ({
  user: s.user,
  settings: s.settings.settings
}), {
  registerAccount,
  clearErrors
})(
  ({
    user,
    settings,
    registerAccount,
    clearErrors
  }) => {

    const [didMount, setDidMount] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [toRedirect, toRedirectChange] = useState(false)
    const [isError, isErrorChange] = useState(user.errors.length != 0 ? true : false)
    const [newUser, newUserChange] = useState({ login: "", email: "", password: "", rewritePassword: "" })

    const setUserFiled = (name, value) => newUserChange({ ...newUser, [name]: value });

    const submitRegister = () => {
      setIsRegister(true)
      registerAccount({ user: newUser })
    }

    useEffect(() => {
      if (user.errors.length == 0)
        setDidMount(true)
      else
        clearErrors()

      return () => {
        setDidMount(false);
        clearErrors()
      };
    }, [])

    useEffect(() => {
      if (didMount && isRegister && !user.isError) {
        alert('Registration success. Confirm you account, link sended on email');
        toRedirectChange(true)
      } else if (isError && user.errors.length == 0)
        setDidMount(true)

    }, [user.errors])

    useEffect(() => {
      isErrorChange(user.isError)
      setIsRegister(false)
    }, [user.isError])

    if (toRedirect)
      return <Redirect to={'/login'} />

    return (<div className={'registration-page'}>
      {
        settings.blockNewRegistrations && location.hostname == 'new.docs-dev.com'
          ? <div className={'d-flex align-items-center justify-content-center'}>
            <h2>Service is currently down for maintenance.</h2>
          </div>
          : <div>
            <form className={'registration-form'} onSubmit={(e) => { e.preventDefault(); submitRegister() }}>
              <h2 className={'text-center'}>Registration</h2>
              {
                user.isError && user.errors.length > 0
                  ? <div className={'w-100 text-center text-danger'}>
                    {
                      user.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
                    }
                  </div>
                  : null
              }
              <div className={'form-row'}>
                <div className={'form-group col-md-6'}>
                  <label htmlFor={'inpuLogin'}>Login</label>
                  <input type={'text'} value={newUser.login} onChange={(e) => setUserFiled('login', e.target.value)} className={'form-control'} id={'inputLogin'} required />
                </div>
                <div className={'form-group col-md-6'}>
                  <label htmlFor={'inputPassword'}>Password</label>
                  <input type={'password'} value={newUser.password} onChange={(e) => setUserFiled('password', e.target.value)} className={'form-control'} id={'inputPassword'} required />
                </div>
              </div>
              <div className={'form-row'}>
                <div className={'form-group col-md-6'}>
                  <label htmlFor={'inputEmail4'}>Email</label>
                  <input type={'email'} value={newUser.email} onChange={(e) => setUserFiled('email', e.target.value)} className={'form-control'} id={'inputEmail4'} required />
                </div>
                <div className={'form-group col-md-6'}>
                  <label htmlFor={'inputRewritePassword'}>Rewrite password</label>
                  <input type={'password'} value={newUser.rewritePassword} onChange={(e) => setUserFiled('rewritePassword', e.target.value)} className={'form-control'} id={'inputRewritePassword'} required />
                </div>
              </div>
              <div className={'form-row justify-content-center'}>
                <button type={'submit'} className="btn btn-primary">Sign up</button>
                <span className={'w-100 d-inline-block text-center mt-3'}>If u already have an account, <Link to={'/login'}>sign in</Link></span>
              </div>
            </form>
          </div>
      }
    </div>)

  })