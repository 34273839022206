import React from 'react';

import { Link } from 'react-router-dom';

export default () => {


  return (<ul>
    <li>
      <Link to={'/tickets'}>
        <i className={'fa fa-cart-plus'}></i>
        <span>Contact us</span>
      </Link>
    </li>
    <li className={'header-menu'}>
      <span>Orders</span>
    </li>
    <li>
      <Link to={'/newOrder'}>
        <i className={'fa fa-cart-plus'}></i>
        <span>New</span>
      </Link>
    </li>
    <li>
      <Link to={'/orders'}>
        <i className={'fa fa-list'}></i>
        <span>List</span>
      </Link>
    </li>
    <li>
      <Link to={'/history'}>
        <i className={'fa fa-list'}></i>
        <span>History</span>
      </Link>
    </li>
    <li className={'header-menu'}>
      <span>Transactions</span>
    </li>
    <li>
      <Link to={'/topup'} >
        <i className={'fa fa-dollar-sign'} aria-hidden={'true'}></i>
        <span>Top Up</span>
      </Link>
    </li>
    <li>
      <Link to={'/transactions'}>
        <i className={'fa fa-list'}></i>
        <span>List</span>
      </Link>
    </li>
    <li className={'header-menu'}>
      <span>Info</span>
    </li>
    <li>
      <Link to={'/profile'}>
        <i className={'fa fa-info-circle'}></i>
        <span>Profile</span>
      </Link>
    </li>
    <li>
      <Link to={'/faq'}>
        <i className={'fa fa-info-circle'}></i>
        <span>FAQ</span>
      </Link>
    </li>
  </ul>)
}