import { handleActions } from 'redux-actions';
import {
  depositForAsync,
  getLastAsync,
  addNewTransactionAsync,
  clearErrorsAsync
} from '../actions/transactions.actions';

import {
  editOrderAsync,
  takeOrderAsync
} from '../actions/orders.actions';

const initialState = {
  transactions: [],
  allUsersBalance: 0,
  isError: false,
  errors: []
};

export default handleActions({
  [takeOrderAsync.success]: (s, a) => ({ ...s, transactions: a.payload.data && a.payload.data.success ? [...s.transactions, a.payload.data.transaction] : s.transactions }),
  [editOrderAsync.success]: (s, a) => ({ ...s, transactions: a.payload.data && a.payload.data.success ? [...s.transactions, a.payload.data.transaction] : s.transactions }),
  [addNewTransactionAsync.success]: (s, a) => ({ ...s, transactions: [...s.transactions, a.payload.transaction] }),
  [depositForAsync.success]: (s, a) => ({ ...s, transactions: a.payload.data && a.payload.data.success ? [...s.transactions, a.payload.data.transaction] : s.transactions, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [depositForAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [getLastAsync.success]: (s, a) => ({ ...s, allUsersBalance: a.payload.data && a.payload.data.success ? a.payload.data.allUsersBalance : 0, transactions: a.payload.data && a.payload.data.success ? a.payload.data.from ? [...s.transactions, ...a.payload.data.transactions] : a.payload.data.transactions : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [getLastAsync.failed]: (s, a) => ({ ...s, allUsersBalance: a.payload.data && a.payload.data.success ? a.payload.data.allUsersBalance : 0, transactions: a.payload.data && a.payload.data.success ? a.payload.data.from ? [...s.transactions, ...a.payload.data.transactions] : a.payload.data.transactions : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [clearErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [] }),
  [clearErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [] }),
}, initialState);
