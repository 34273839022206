import React from 'react';

import UserOrdersListPage from '../components/Users/Orders/list';
import EmployeeOrdersListPage from '../components/Emplyees/Orders/List';
import AdminOrdersListPage from '../components/Admin/Goods';
import AdminHomePage from '../components/Admin/Home';
import PageNotFound from './NotFound.view'
import Title from './../components/Default/TitleLayout'

export default ({
  role,
  adminHome
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - ORDERS</title>
      </Title>
      <UserOrdersListPage />
    </div>
    : role == 'employee'
      ? <div>
        <Title>
          <title>DOCS PANEL - ORDERS</title>
        </Title>
        <EmployeeOrdersListPage />
      </div>
      : role == 'admin' && adminHome
        ? <div>
          <Title>
            <title>DOCS PANEL - HOME</title>
          </Title>
          <AdminHomePage />
        </div>
        : role == 'admin' && !adminHome
          ? <div>
            <Title>
              <title>DOCS PANEL - HOME</title>
            </Title>
            <AdminOrdersListPage />
          </div>
          : <PageNotFound />
};