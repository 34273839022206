import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('ORDERS');

export const addOrder = factory.create('ADD_ORDER');
export const addOrderAsync = factory.createAsync('ADD_ORDER_ASYNC');

export const addNewOrder = factory.create('ADD_NEW_ORDER');
export const addNewOrderAsync = factory.createAsync('ADD_NEW_ORDER_ASYNC');

export const editOrder = factory.create('EDIT_ORDER');
export const editOrderAsync = factory.createAsync('EDIT_ORDER_ASYNC');

export const editCurrentOrder = factory.create('EDIT_CURRENT_ORDER');
export const editCurrentOrderAsync = factory.createAsync('EDIT_CURRENT_ORDER_ASYNC');

export const addMistake = factory.create('ADD_MISTAKE');
export const addMistakeAsync = factory.createAsync('ADD_MISTAKE_ASYNC');

export const takeOrder = factory.create('TAKE_ORDER');
export const takeOrderAsync = factory.createAsync('TAKE_ORDER_ASYNC');

export const payOrder = factory.create('PAY_ORDER');
export const payOrderAsync = factory.createAsync('PAY_ORDER_ASYNC');

export const deleteOrder = factory.create('DELETE_ORDER');
export const deleteOrderAsync = factory.createAsync('DELETE_ORDER_ASYNC');

export const deleteOldOrder = factory.create('DELETE_OLD_ORDER');
export const deleteOldOrderAsync = factory.createAsync('DELETE_OLD_ORDER_ASYNC');

export const getOrders = factory.create('GET_ORDERS');
export const getOrdersAsync = factory.createAsync('GET_ORDERS_ASYNC');

export const redirectToTopUp = factory.create('REDIRECT_TO_TOPUP');
export const redirectToTopUpAsync = factory.createAsync('REDIRECT_TO_TOPUP_ASYNC');

export const redirectToOrders = factory.create('REDIRECT_TO_ORDERS');
export const redirectToOrdersAsync = factory.createAsync('REDIRECT_TO_ORDERS_ASYNC');

export const clearOrdersErrors = factory.create('CLEAR_ERRORS');
export const clearOrdersErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');