import React from 'react';

import UserOrdersHistoryPage from '../components/Users/Orders/History';
import EmployeeOrdersHistoryPage from '../components/Emplyees/Orders/History';
import PageNotFound from './NotFound.view'
import Title from '../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - ORDERS</title>
      </Title>
      <UserOrdersHistoryPage />
    </div>
    : role == 'employee'
      ? <div>
        <Title>
          <title>DOCS PANEL - ORDERS</title>
        </Title>
        <EmployeeOrdersHistoryPage />
      </div>
      :  <div>
          <Title>
            <title>DOCS PANEL - NOTHING NOT FOUND</title>
          </Title>
          <PageNotFound />
        </div>
};