import React from 'react';

import OrdersNewPage from '../components/Users/Orders/new';
import PageNotFoundPage from './NotFound.view';
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role != 'admin'
    ? <div>
      <Title>
        <title>DOCS PANEL - NEW ORDER</title>
      </Title>
      <OrdersNewPage />
    </div>
    : <PageNotFoundPage />
};