import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('TICKETS');

export const addTicket = factory.create('ADD_TICKET');
export const addTicketAsync = factory.createAsync('ADD_TICKET_ASYNC');

export const getTickets = factory.create('GET_TICKETS');
export const getTicketsAsync = factory.createAsync('GET_TICKETS_ASYNC');

export const closeTicket = factory.create('CLOSE_TICKET');
export const closeTicketAsync = factory.createAsync('CLOSE_TICKET_ASYNC');

export const newTicket = factory.create('NEW_TICKET');
export const newTicketAsync = factory.createAsync('NEW_TICKET_ASYNC');

export const closedTicket = factory.create('CLOSED_TICKET');
export const closedTicketAsync = factory.createAsync('CLOSED_TICKET_ASYNC');

export const clearTicketsErrors = factory.create('CLEAR_ERRORS');
export const clearTicketsErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');