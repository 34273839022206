import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ImageModal from '../../Default/ImageModal';

import { baseUrl } from '../../../common/config';

const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
  let result = '';

  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length));

  return result;
}

export default ({
  show,
  order,
  close,
  editOrder,
  user,
  backgrounds,
  cameraModels,
  goods
}) => {
  const [currentOrder, changeCurrentOrder] = useState(order ? { ...order, services: order.services.map(s => ({ ...s, serviceInformation: s.serviceInformation.map(si => ({ ...si, gid: generateId() })) })) } : order);
  const [sampleModal, changeSampleModal] = useState({
    show: false,
    image: null
  })

  const editCurrentOrderServiceField = (id, name, value, nameSec) => changeCurrentOrder({ ...currentOrder, services: currentOrder.services.map(v => v._id == id ? { ...nameSec ? { ...v, [name]: { ...v[name], [nameSec]: value } } : { ...v, [name]: value } } : v) })

  const setCurrentOrderServiceInfoFiled = (idS, idF, value) => changeCurrentOrder({ ...currentOrder, services: currentOrder.services.map(s => s._id == idS ? { ...s, serviceInformation: s.serviceInformation.map(sf => sf._id == idF ? { ...sf, value } : sf) } : s) })
  const setCurrentOrderAdditionalServiceInfoFiled = (mainServiceId, additionalServiceId, fieldId, value) => changeCurrentOrder({
    ...currentOrder,
    services: currentOrder.services.map(s => s._id == mainServiceId ?
      ({
        ...s,
        additionalServices: s.additionalServices.map(as => as._id == additionalServiceId ? ({
          ...as,
          serviceInformation: as.serviceInformation.map(assf => assf._id == fieldId ?
            ({
              ...assf,
              value
            }) : assf)
        }) : as)
      }) : s)
  })

  const applyPattern = (value, pattern) => pattern == 'chars' ? value.replace(/[^A-Za-zА-Яа-яЁё]+/g, '') : pattern == 'numeric' ? value.replace(/[^0-9]+/g, '') : value;

  const submitEditOrder = () => {
    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('order', JSON.stringify({ _id: currentOrder._id }));
    formData.append('services', JSON.stringify(currentOrder.services));

    for (let i = 0; i < currentOrder.services.length; i++) {
      let currentService = goods.find(g => g._id == currentOrder.services[i]._id)
      for (let j = 0; j < currentOrder.services[i].serviceInformation.length; j++) {
        if (currentService) {
          let currentField = currentService.fields.find(f => f.name == currentOrder.services[i].serviceInformation[j].name)
          if (currentField && currentField.fileUploader)
            formData.append(currentOrder.services[i].serviceInformation[j].gid + currentOrder.services[i].serviceInformation[j].name, currentOrder.services[i].serviceInformation[j].value);
        }
      }
    }

    editOrder(formData)
    close()
  }

  const showSample = (image) => changeSampleModal({ show: true, image: image })

  useEffect(() => {
    changeCurrentOrder(order ? { ...order, services: order.services.map(s => ({ ...s, serviceInformation: s.serviceInformation.map(si => ({ ...si, gid: generateId() })) })) } : order)
  }, [order])

  return (show && currentOrder
    ? <div className={'position-absolute w-100 h-100 see-wrapper d-flex justify-content-center align-items-center'}>
      <div className={'position-absolute w-100 h-100 see-background'}></div>
      <ImageModal
        show={sampleModal.show}
        close={() => changeSampleModal({ show: false, image: null })}
        image={sampleModal.image}
      />
      <div className={'w-50 p-2 border rounded bg-white see-form'}>
        <div className={'w-100'}>
          <div onClick={close} className={'float-right cursor-pointer'}>
            <i className={'fas fa-times'}></i>
          </div>
        </div>
        <div className={'w-100'}>
          <h5>Order</h5>
          <form className={'m-0'} onSubmit={(e) => { e.preventDefault(); submitEditOrder() }}>
            <div className={'form-row'}>
              <div className={'form-group col-md-6'}>
                <label>Status</label>
                <input value={currentOrder.status} type={'text'} className={'form-control'} readOnly />
              </div>
              <div className={'form-group col-md-6'}>
                <label>Created</label>
                <input value={currentOrder.createdAt} type={'text'} className={'form-control'} readOnly />
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-6'}>
                <label>Full price</label>
                <input value={currentOrder.fullPriceUser} type={'text'} className={'form-control'} readOnly />
              </div>
              <div className={'form-group col-md-6'}>
                <label>Mistake</label>
                <input value={currentOrder.mistake ? 'exist' : 'not exist'} type={'text'} className={'form-control'} readOnly />
              </div>
            </div>
            {
              currentOrder.text
                ? <div className={'form-row'}>
                  <div className={'form-group col-md-12'}>
                    <label>Text</label>
                    <textarea value={currentOrder.text} readOnly className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
                  </div>
                </div>
                : null
            }
            {
              currentOrder.images.length > 0
                ? <div className={'form-row'}>
                  <div className={'form-group col-md-12'}>
                    <label>Images</label>
                  </div>
                  {
                    currentOrder.images.map((v, i) => (
                      <div className={'form-group col-md-12'} key={i}>
                        <button className={'btn btn-success'} type={'button'} onClick={() => showSample(baseUrl + 'static/downloads/' + v.link)}>show image №{i}</button>
                      </div>
                    ))
                  }
                </div>
                : null
            }
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label >Services</label>
              </div>
              {
                currentOrder.services.map((v, i) => (
                  <div className={'form-group col-md-12 border-bottom mb-2'} key={i}>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label >Name</label>
                        <input value={v.name} type={'text'} className={'form-control'} readOnly />
                      </div>
                      <div className={'form-group col-md-6'}>
                        <label >Complete time</label>
                        <input value={v.generalInformation.completeTime} type={'text'} className={'form-control'} readOnly />
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label >Service price</label>
                        <input value={v.priceUser} type={'text'} className={'form-control'} readOnly />
                      </div>
                      <div className={'form-group col-md-6'}>
                        <label >Camera model</label>
                        <select value={cameraModels.find(c => c._id == v.generalInformation.cameraModel)._id} onChange={(e) => editCurrentOrderServiceField(v._id, 'generalInformation', e.target.value, 'cameraModel')} className={'form-control'} >
                          {
                            cameraModels.map((v, i) => (
                              <option key={i} value={v._id}>{v.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label className={'w-100'}>Sample</label>
                        <button className={'btn btn-success'} type={'button'} onClick={() => showSample(v.generalInformation.sample)}>show sample</button>
                      </div>
                      <div className={'form-group col-md-3'}>
                        <label className={'w-100'}>Background</label>
                        <select value={backgrounds.find(b => b._id == v.generalInformation.background)._id} onChange={(e) => editCurrentOrderServiceField(v._id, 'generalInformation', e.target.value, 'background')} className={'form-control'} >
                          {
                            backgrounds.map((v, i) => (
                              <option key={i} value={v._id}>{v.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className={'form-group col-md-3 d-flex align-items-end'}>
                        <button className={'btn btn-success'} type={'button'} onClick={() => showSample(backgrounds.find(b => b._id == v.generalInformation.background).image)}>show background</button>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>How take picture</label>
                        <textarea value={v.generalInformation.howTakePicture} onChange={(e) => editCurrentOrderServiceField(v._id, 'generalInformation', e.target.value, 'howTakePicture')} className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>Information</label>
                      </div>
                      {
                        v.serviceInformation.map((s, i) => (
                          <div className={'form-group col-md-6'} key={i}>
                            <label className={'w-100'}>{s.name}</label>
                            {
                              goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).fileUploader
                                ? <div className={'w-100 row d-flex align-items-center'}>
                                  <div className={'col-md-6 d-flex justify-content-center'}>
                                    <button className={'btn btn-success'} type={'button'} onClick={() => showSample(s.value)}>show iamge</button>
                                  </div>
                                  <div className={'col-md-6 d-flex justify-content-center'}>
                                    <input onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setCurrentOrderServiceInfoFiled(v._id, s._id, e.target.files[0]) : e.target.value = null} accept={'image/*, .rar, .zip, .pdf'} type={'file'} className={'w-100'} />
                                  </div>
                                </div>
                                : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).textInput
                                  ? <input value={s.value} onChange={(e) => setCurrentOrderServiceInfoFiled(v._id, s._id, applyPattern(e.target.value, goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).pattern))} type={'text'} className={'form-control'} required={s.required} maxLength={goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).maxLength > 0 ? goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).maxLength : 'false'} />
                                  : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).select
                                    ? <select required={s.required} value={s.value} onChange={(e) => setCurrentOrderServiceInfoFiled(v._id, s._id, e.target.value)} className={'form-control'}>
                                      {
                                        goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).values.map((sfv, i) => (
                                          <option value={sfv} key={i}>{sfv}</option>
                                        ))
                                      }
                                    </select>
                                    : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).datePicker
                                      ? <DatePicker required={s.required} dateFormat={'MMMM d, yyyy'} className={'form-control'} selected={new Date(s.value)} onChange={(date) => setCurrentOrderServiceInfoFiled(v._id, s._id, date)} />
                                      : null
                            }
                          </div>
                        ))
                      }
                    </div>
                    
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>Additional</label>
                      </div>
                      {
                        v.additionalServices.map((asv, asi) => (

                          <div className="w-100 mb-2" key={asi}>
                            {
                              asv.serviceInformation.map((asvsiv, asvsii) => (
                                <div className={'form-group col-md-6'} key={asvsii}>
                                  <label className={'w-100'}>{asvsiv.name}</label>
                                  {
                                    goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).fileUploader
                                      ? <div className={'w-100 row d-flex align-items-center'}>
                                        <div className={'col-md-6 d-flex justify-content-center'}>
                                          <button className={'btn btn-success'} type={'button'} onClick={() => showSample(asvsiv.value)}>show iamge</button>
                                        </div>
                                        <div className={'col-md-6 d-flex justify-content-center'}>
                                          <input onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setCurrentOrderAdditionalServiceInfoFiled(v._id, asv._id, asvsiv._id, e.target.files[0]) : e.target.value = null} accept={'image/*, .rar, .zip, .pdf'} type={'file'} className={'w-100'} />
                                        </div>
                                      </div>
                                      : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).textInput
                                        ? <input value={asvsiv.value} onChange={(e) => setCurrentOrderAdditionalServiceInfoFiled(v._id, asv._id, asvsiv._id, applyPattern(e.target.value, goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).pattern))} type={'text'} className={'form-control'} required={asvsiv.required} maxLength={goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).maxLength > 0 ? goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).maxLength : 'false'} />
                                        : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).select
                                          ? <select required={asvsiv.required} value={asvsiv.value} onChange={(e) => setCurrentOrderAdditionalServiceInfoFiled(v._id, asv._id, asvsiv._id, e.target.value)} className={'form-control'}>
                                            {
                                              goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).values.map((sfv, i) => (
                                                <option value={sfv} key={i}>{sfv}</option>
                                              ))
                                            }
                                          </select>
                                          : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).datePicker
                                            ? <DatePicker required={asvsiv.required} dateFormat={'MMMM d, yyyy'} className={'form-control'} selected={new Date(asvsiv.value)} onChange={(date) => setCurrentOrderAdditionalServiceInfoFiled(v._id, asv._id, asvsiv._id, date)} />
                                            : null
                                  }
                                </div>
                              ))
                            }
                          </div>

                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-12 d-flex justify-content-end pl-3 pr-3'}>
                <button disabled={currentOrder.status == 'waiting for payment' || currentOrder.status == 'accepted' ? false : true} className={'btn btn-primary'} type={'submit'}>edit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div >
    : null)

}