import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { requestConfirmCode, changePassword, changeEmail, changeTelegramId, setNotification } from '../../../store/actions/users.actions';

export default connect((s) => ({
  user: s.user,
  settings: s.settings.settings
}), {
  requestConfirmCode,
  changePassword,
  changeEmail,
  changeTelegramId,
  setNotification
})(
  ({
    user,
    settings,
    requestConfirmCode,
    changePassword,
    changeEmail,
    changeTelegramId,
    setNotification
  }) => {
    const [newPasswordState, changeNewPasswordState] = useState({ newPassword: '', confirmCode: '' })
    const [newEmailState, changeNewEmailState] = useState({ newEmail: '', confirmCode: '' })
    const [newTelegramState, changeNewTelegramState] = useState(user.telegramId)
    const [newNotificationState, changenewNotificationState] = useState(user.sendNotification)
    const [dataToSend, changeDataToSend] = useState(false)

    const setNewPasswordStateFiled = (name, value) => changeNewPasswordState({ ...newPasswordState, [name]: value })
    const setNewEmailStateFiled = (name, value) => changeNewEmailState({ ...newEmailState, [name]: value })
    const setNewTelegramState = (value) => changeNewTelegramState(value)
    const setNewNotificationState = (name) => changenewNotificationState({ ...{ telegram: false, email: false }, [name]: true })

    const submitPasswordChange = () => {
      changeDataToSend(true)
      changeNewPasswordState({ newPassword: '', confirmCode: '' })
      changePassword({ ...newPasswordState, token: user.token })
    };
    const submitEmailChange = () => {
      changeDataToSend(true);
      changeNewEmailState({ newEmail: '', confirmCode: '' })
      changeEmail({ ...newEmailState, token: user.token });
    }
    const submitTelegramChange = () => {
      changeDataToSend(true);
      changeTelegramId({ newTelegramId: newTelegramState, token: user.token });
    }
    const submitNotificationSet = () => {
      changeDataToSend(true);
      setNotification({ notification: newNotificationState, token: user.token });
    }

    useEffect(() => {
      if (dataToSend && !user.isError) {
        alert('Data updated')
        changeDataToSend(false)
      }
    }, [user])

    return (<div className={'w-100 h-100'}>
      {
        settings.closeUsersPage
          ? <div className={'w-100 h-100 d-flex align-items-center justify-content-center'}>
            <h2>Service is currently down for maintenance.</h2>
          </div>
          : <div>
            <h2>Profile</h2>
            <div className={'pb-3 mt-2 border-bottom w-100 d-flex justify-content-center align-items-center'}>
              <button onClick={() => requestConfirmCode(user.token)} className={'btn btn-warning'} type={'button'}>Request confirm code on email</button>
            </div>
            {
              user.isError && user.errors.length > 0
                ? <div className={'w-100 text-center text-danger'}>
                  {
                    user.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
                  }
                </div>
                : null
            }
            <div className={'pb-3 mt-2 border-bottom'}>
              <h4 className={'text-center'}>Change password</h4>
              <form className={'pr-3 pl-3 pt-2 m-0'} onSubmit={(e) => { e.preventDefault(); submitPasswordChange() }}>
                <div className={'form-row'}>
                  <div className={'form-group col-md-6'}>
                    <label htmlFor={'inputPassword'}>New password</label>
                    <input value={newPasswordState.newPassword} onChange={(e) => setNewPasswordStateFiled('newPassword', e.target.value)} type={'password'} autoComplete={'on'} className={'form-control'} id={'inputPassword'} required />
                  </div>
                  <div className={'form-group col-md-6'}>
                    <label htmlFor={'inputConfirmCode'}>Confirm code from email</label>
                    <input value={newPasswordState.confirmCode} onChange={(e) => setNewPasswordStateFiled('confirmCode', e.target.value)} type={'text'} className={'form-control'} id={'inputConfirmCode'} required />
                  </div>
                </div>
                <div className={'form-row d-flex justify-content-center align-items-center'}>
                  <div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
                    <button className={'btn btn-success'} type={'submit'}>change password</button>
                  </div>
                </div>
              </form>
            </div>
            <div className={'pb-3 mt-2 border-bottom'}>
              <h4 className={'text-center'}>Change email</h4>
              <form className={'pr-3 pl-3 pt-2 m-0'} onSubmit={(e) => { e.preventDefault(); submitEmailChange() }}>
                <div className={'form-row'}>
                  <div className={'form-group col-md-6'}>
                    <label htmlFor={'inputNewEmail'}>new email</label>
                    <input value={newEmailState.newEmail} onChange={(e) => setNewEmailStateFiled('newEmail', e.target.value)} type={'email'} className={'form-control'} id={'inputNewEmail'} requ ired />
                  </div>
                  <div className={'form-group col-md-6'}>
                    <label htmlFor={'inputConfirmCode'}>Confirm code from email</label>
                    <input value={newEmailState.confirmCode} onChange={(e) => setNewEmailStateFiled('confirmCode', e.target.value)} type={'text'} className={'form-control'} id={'inputConfirmCode'} required />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
                    <button className={'btn btn-success'} type={'submit'}>change email</button>
                  </div>
                </div>
              </form>
            </div>
            {
              user.accountType != 'employee'
                ? <div>
                  <div className={'pb-3 mt-2 border-bottom'}>
                    <h4 className={'text-center'}>Change telegramId</h4>
                    <form className={'pr-3 pl-3 pt-2 m-0'} onSubmit={(e) => { e.preventDefault(); submitTelegramChange() }}>
                      <div className={'form-row'}>
                        <div className={'form-group col-md-12'}>
                          <label htmlFor={'inputNewTelegramId'}>edit telegramId</label>
                          <input value={newTelegramState} onChange={(e) => setNewTelegramState(e.target.value)} type={'text'} className={'form-control'} id={'inputNewTelegramId'} required />
                        </div>
                      </div>
                      <div className={'form-row'}>
                        <div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
                          <button className={'btn btn-success'} type={'submit'}>change telegramId</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={'pb-3 mt-2 border-bottom'}>
                    <h4 className={'text-center'}>Change notifications</h4>
                    <form className={'pr-3 pl-3 pt-2 m-0'} onSubmit={(e) => { e.preventDefault(); submitNotificationSet() }}>
                      <div className={'form-row'}>
                        <div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
                          <div className={'form-check w-auto d-inline-block'}>
                            <span>pick one: </span>
                          </div>
                          <div className={'form-check ml-2 w-auto d-inline-block'}>
                            <input onChange={(e) => setNewNotificationState(e.target.value)} checked={newNotificationState.telegram} className={'form-check-input'} type={'radio'} name={'notificationVar'} id={'notificationVarTelegram'} value={'telegram'} required />
                            <label className={'form-check-label'} htmlFor={'notificationVarTelegram'}>Telegram</label>
                          </div>
                          <div className={'form-check ml-2 w-auto d-inline-block'}>
                            <input onChange={(e) => setNewNotificationState(e.target.value)} checked={newNotificationState.email} className={'form-check-input'} type={'radio'} name={'notificationVar'} id={'notificationVarEmail'} value={'email'} required />
                            <label className={'form-check-label'} htmlFor={'notificationVarEmail'}>email</label>
                          </div>
                        </div>
                      </div>
                      <div className={'form-row'}>
                        <div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
                          <button className={'btn btn-success'} type={'submit'}>change notification</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                : null
            }
          </div>
      }
    </div>
    )

  })