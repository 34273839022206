import React, { useState, useEffect } from 'react';

export default ({
  show,
  order,
  close,
  addMistake,
  user
}) => {
  const [currentMistake, changeCurrentMistake] = useState(order ? { orderId: order._id, description: '', file: null } : {});

  const editCurrentMistakeField = (name, value) => changeCurrentMistake({ ...currentMistake, [name]: value })

  const submitAddMistake = () => {
    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('mistake', JSON.stringify(currentMistake));

    if (currentMistake.file)
      formData.append('file', currentMistake.file);

    addMistake(formData)
    close()
  }

  useEffect(() => {
    changeCurrentMistake(order ? {
      orderId: order._id,
      description: '',
      file: null
    } : {})
  }, [order])

  return (show && currentMistake
    ? <div className={'position-absolute w-100 h-100 see-wrapper d-flex justify-content-center align-items-center'}>
      <div className={'position-absolute w-100 h-100 see-background'}></div>
      <div className={'w-50 p-2 border rounded bg-white see-form'}>
        <div className={'w-100'}>
          <div onClick={close} className={'float-right cursor-pointer'}>
            <i className={'fas fa-times'}></i>
          </div>
        </div>
        <div className={'w-100'}>
          <h5>Add mistake</h5>
          <form className={'m-0'} onSubmit={(e) => { e.preventDefault(); submitAddMistake() }}>
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label>Description</label>
                <textarea required value={currentMistake.description} onChange={(e) => editCurrentMistakeField('description', e.target.value)} className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label>File</label>
                <input onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? editCurrentMistakeField('file', e.target.files[0]) : e.target.value = null} accept={'image/*, .rar, .zip, .pdf'} type={'file'} className={'w-100'} />
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-12 d-flex justify-content-end pl-3 pr-3'}>
                <button disabled={order && !order.mistake ? false : true} className={'btn btn-primary'} type={'submit'}>add</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div >
    : null)

}