import { handleActions } from 'redux-actions';
import {
  addTicketAsync,
  closeTicketAsync,
  getTicketsAsync,
  newTicketAsync,
  closedTicketAsync,
  clearTicketsErrorsAsync
} from '../actions/tickets.actions';

const initialState = {
  tickets: [],
  isError: false,
  errors: []
};

const deleteElement = (array, elementId) => {
  var idx = array.indexOf(array.find(f => f._id == elementId));
  if (idx != -1) {
    array.splice(idx, 1);
    return JSON.parse(JSON.stringify(array));
  }
  return JSON.parse(JSON.stringify(array));
}

const editElement = (array, editedElement) => {
  if (array.find(f => f._id == editedElement._id))
    array[array.indexOf(array.find(f => f._id == editedElement._id))] = editedElement
  return JSON.parse(JSON.stringify(array));
}

export default handleActions({
  [closedTicketAsync.success]: (s, a) => ({ ...s, tickets: a.payload ? editElement(s.tickets, a.payload) : s.tickets }),
  [newTicketAsync.success]: (s, a) => ({ ...s, tickets: a.payload ? s.tickets.find(t => t._id.toString() == a.payload._id.toString()) ? editElement(s.tickets, a.payload) : [...s.tickets, a.payload] : [] }),
  [addTicketAsync.success]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? s.tickets.find(t => t._id.toString() == a.payload.data.ticket._id.toString()) ? editElement(s.tickets, a.payload.data.ticket) : [...s.tickets, a.payload.data.ticket] : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addTicketAsync.failed]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? s.tickets.find(t => t._id.toString() == a.payload.data.ticket._id.toString()) ? editElement(s.tickets, a.payload.data.ticket) : [...s.tickets, a.payload.data.ticket] : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [closeTicketAsync.success]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? deleteElement(s.tickets, a.payload.data.ticket) : s.tickets, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [closeTicketAsync.failed]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? deleteElement(s.tickets, a.payload.data.ticket) : s.tickets, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getTicketsAsync.success]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? a.payload.data.tickets : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getTicketsAsync.failed]: (s, a) => ({ ...s, tickets: a.payload.data && a.payload.data.success ? a.payload.data.tickets : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [clearTicketsErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [] }),
  [clearTicketsErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [] }),
}, initialState);
