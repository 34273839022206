import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { addOrder, clearOrdersErrors, redirectToOrders } from '../../../store/actions/orders.actions';

import DatePicker from 'react-datepicker';
import ImageModal from '../../Default/ImageModal'

const parseTime = () => {
  let currentTime = new Date(+new Date() - new Date().getTimezoneOffset() + 3600000);
  if (currentTime.getHours() < 18)
    return `10 AM - 7 PM GMT+1, ${currentTime.getDate() < 10 ? '0' + currentTime.getDate() : currentTime.getDate()}/${currentTime.getMonth() < 10 ? '0' + (currentTime.getMonth() + 1) : (currentTime.getMonth() + 1)}/${currentTime.getFullYear()}`
  else
    return `10 AM - 7 PM GMT+1, ${currentTime.getDate() < 10 ? '0' + (currentTime.getDate() + 1) : currentTime.getDate() + 1}/${currentTime.getMonth() < 10 ? '0' + (currentTime.getMonth() + 1) : (currentTime.getMonth() + 1)}/${currentTime.getFullYear()}`
}

const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
  let result = '';

  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length));

  return result;
}

export default connect((s) => ({
  user: s.user,
  goods: s.goods,
  orders: s.orders
}), {
  addOrder,
  redirectToOrders,
  clearOrdersErrors
})(
  ({
    user,
    goods,
    orders,
    addOrder,
    redirectToOrders,
    clearOrdersErrors
  }) => {
    const [OrdersLength, changeOrdersLength] = useState(orders.orders.length)
    const [IsSended, changeIsSended] = useState(false)
    const [showImageModal, changeShowImageModal] = useState(false)
    const [imageModal, changeImageModal] = useState('')
    const [CurrentAdditionalService, changeCurrentAdditionalService] = useState(goods.goods.filter(v => v.additional).length > 0 ? goods.goods.filter(v => v.additional)[0].name : '')
    const [allServices, changeAllServices] = useState([])
    const [currentService, changeCurrentService] = useState({
      _id: goods.goods.length > 0 ? goods.goods[0]._id : '',
      country: goods.countrys.length > 0 ? goods.countrys[0].name : '',
      states: '',
      docType: goods.docsTypes.filter(v => {
        if (goods.countrys.length > 0)
          return v
      }).length > 0 ? goods.docsTypes[0].name : '',
      name: goods.goods.length > 0 ? goods.goods[0].name : '',
      operator: goods.goods.length > 0 ? goods.goods[0].operator : '',
      priceUser: goods.goods.length > 0 ? goods.goods[0].priceForUser : '',
      priceEmplyee: goods.goods.length > 0 ? goods.goods[0].priceForEmployees : '',
      additionalServices: [],
      generalInformation: {
        background: goods.backgrounds.length > 0 ? goods.backgrounds[0]._id : '',
        cameraModel: goods.cameraModels.length > 0 ? goods.cameraModels[0]._id : '',
        howTakePicture: '',
        sample: goods.goods.length > 0 ? goods.goods[0].sample : '',
        completeTime: parseTime()
      },
      serviceInformation: goods.goods.length > 0 ? goods.goods[0].fields.map(v => ({ gid: generateId(), id: v._id, required: v.required, name: v.name, value: v.datePicker ? new Date().toString() : v.select ? goods.goods[0].fields.find(gf => gf._id == v._id).values[0] : '' })) : []
    })

    const setCurrentServiceField = (name, value, nameSec) => changeCurrentService({ ...currentService, ...nameSec ? { [name]: { ...currentService[name], [nameSec]: value } } : { [name]: value } })
    const setServiceInfoFiled = (serviceId, fieldId, value) => changeAllServices(allServices.map(v => v.id == serviceId ? ({ ...v, serviceInformation: v.serviceInformation.map(sv => sv.id == fieldId ? ({ ...sv, value }) : sv) }) : v))
    const setAdditonalServiceInfoFiled = (serviceId, additionalServiceId, fieldId, value) => changeAllServices(allServices.map(asv => asv.id == serviceId ? ({ ...asv, additionalServices: asv.additionalServices.map(asasv => asasv.id == additionalServiceId ? ({ ...asasv, serviceInformation: asasv.serviceInformation.map(asasvsiv => asasvsiv.id == fieldId ? ({ ...asasvsiv, value }) : asasvsiv) }) : asasv) }) : asv))

    const addService = () => {
      if (!currentService._id)
        return alert('take any one service')

      changeCurrentAdditionalService(goods.goods.filter(v => v.additional && v.docType && v.docType.name == currentService.docType).length > 0 ? goods.goods.filter(v => v.additional && v.docType.name == currentService.docType)[0].name : '')

      changeAllServices([...allServices, { ...currentService, id: generateId() }])
      changeCurrentService({
        _id: goods.goods.length > 0 ? goods.goods[0]._id : '',
        country: goods.countrys.length > 0 ? goods.countrys[0].name : '',
        states: '',
        docType: goods.docsTypes.length > 0 ? goods.docsTypes[0].name : '',
        name: goods.goods.length > 0 ? goods.goods[0].name : '',
        operator: goods.goods.length > 0 ? goods.goods[0].operator : '',
        priceUser: goods.goods.length > 0 ? goods.goods[0].priceForUser : '',
        priceEmplyee: goods.goods.length > 0 ? goods.goods[0].priceForEmployees : '',
        additionalServices: [],
        generalInformation: {
          background: goods.backgrounds.length > 0 ? goods.backgrounds[0]._id : '',
          cameraModel: goods.cameraModels.length > 0 ? goods.cameraModels[0]._id : '',
          howTakePicture: '',
          sample: goods.goods.length > 0 ? goods.goods[0].sample : '',
          completeTime: parseTime()
        },
        serviceInformation: goods.goods.length > 0 ? goods.goods[0].fields.map(v => ({ gid: generateId(), id: v._id, required: v.required, name: v.name, value: v.datePicker ? new Date().toString() : v.select ? goods.goods[0].fields.find(gf => gf._id == v._id).values[0] : '' })) : []
      })
    }

    const deleteService = (id, additional = false, secId) => additional ? changeAllServices(allServices.map(v => v.id == id ? ({ ...v, additionalServices: v.additionalServices.filter(av => av.id != secId) }) : v)) : changeAllServices(allServices.filter(v => v.id != id))

    const applyPattern = (value, pattern) => pattern == 'chars' ? value.replace(/[^A-Za-zА-Яа-яЁё]+/g, '') : pattern == 'numeric' ? value.replace(/[^0-9]+/g, '') : value;

    const sumbitAddOrder = () => {
      if (IsSended)
        return alert('order already send. Wait please')
      if (allServices.length <= 0)
        return alert('complete order')
      let formData = new FormData();
      formData.append('token', user.token);
      formData.append('services', JSON.stringify(allServices));

      for (let i = 0; i < allServices.length; i++)
        for (let j = 0; j < allServices[i].serviceInformation.length; j++) {
          let currentService = goods.goods.find(g => g._id == allServices[i]._id)
          if (currentService) {
            let currentField = currentService.fields.find(f => f._id == allServices[i].serviceInformation[j].id)
            if (currentField && currentField.fileUploader)
              formData.append(allServices[i].serviceInformation[j].gid + allServices[i].serviceInformation[j].name, allServices[i].serviceInformation[j].value);
          }

        }
      addOrder(formData)
      changeIsSended(true)
    }

    const addNewAdditionalService = (id, length) => {

      let currentService = allServices.find(v => v.id == id)
      let currentAdditionalService;

      if (length == 1)
        currentAdditionalService = JSON.parse(JSON.stringify(goods.goods.filter(v => v.additional && v.docType.name == currentService.docType)[0]))
      else if (!goods.goods.filter(v => v.additional && v.docType.name == currentService.docType).find(v => v.name == CurrentAdditionalService))
        currentAdditionalService = JSON.parse(JSON.stringify(goods.goods.filter(v => v.additional && v.docType.name == currentService.docType)[0]))
      else
        currentAdditionalService = JSON.parse(JSON.stringify(goods.goods.filter(v => v.additional).find(v => v.name == CurrentAdditionalService)))

      let newAdditionalService = {
        id: generateId(),
        _id: currentAdditionalService._id,
        name: currentAdditionalService.name,
        priceUser: currentAdditionalService.priceForUser,
        priceEmplyee: currentAdditionalService.priceForEmployees,
        serviceInformation: currentAdditionalService.fields.map(v => ({ gid: generateId(), id: v._id, required: v.required, name: v.name, value: v.datePicker ? new Date().toString() : v.select ? currentAdditionalService.fields.find(gf => gf._id == v._id).values[0] : '' }))
      }

      changeAllServices(allServices.map(v => v.id == id ? ({ ...v, additionalServices: [...v.additionalServices, newAdditionalService] }) : v))
      changeCurrentAdditionalService(goods.goods.filter(v => v.additional).length > 0 ? goods.goods.filter(v => v.additional)[0].name : '')

    }

    const openModalImage = (img) => {
      changeImageModal(img);
      changeShowImageModal(true);
    }

    const filterDocsNames = v => {
      let pass = false;
      if (v.states && v.states.length > 0 && currentService.states && currentService.states.length > 0) {
        for (let i = 0; i < v.states.length; i++)
          if (v.states[i].name && currentService.states.toLowerCase().indexOf(v.states[i].name.toLowerCase()) != -1)
            pass = true
      } else if (goods.states.find(s => s.name && s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()) && (!v.states || v.states.length == 0 || !currentService.states || currentService.states.length == 0))
        pass = false
      else {
        pass = true
      }
      if (!v.additional && v.country.name == currentService.country && v.docType.name == currentService.docType && pass)
        return v;
    }

    useEffect(() => {

      if (IsSended) {
        if (orders.orders.length != OrdersLength)
          redirectToOrders()
        else
          changeIsSended(false)
      }

    }, [orders])

    useEffect(() => {
      if (orders.errors.length > 0)
        clearOrdersErrors()
      return () => {
        if (orders.errors.length > 0)
          clearOrdersErrors()
      }
    }, [])

    useEffect(() => {
      changeCurrentService({
        ...currentService,
        name: currentService._id ? goods.goods.find(g => g._id.toString() == currentService._id.toString()).name : '',
        operator: currentService._id ? goods.goods.find(g => g._id.toString() == currentService._id.toString()).operator : '',
        priceUser: currentService._id ? goods.goods.find(g => g._id.toString() == currentService._id.toString()).priceForUser : 0,
        priceEmplyee: currentService._id ? goods.goods.find(g => g._id.toString() == currentService._id.toString()).priceForEmployees : 0,
        generalInformation: {
          ...currentService.generalInformation,
          sample: currentService._id ? goods.goods.find(g => g._id == currentService._id).sample : ''
        },
        serviceInformation: currentService._id ? goods.goods.find(g => g._id == currentService._id).fields.map(v => ({ gid: generateId(), id: v._id, required: v.required, name: v.name, value: v.datePicker ? new Date().toString() : v.select ? goods.goods.find(g => g._id == currentService._id).fields.find(gf => gf._id == v._id).values[0] : '' })) : []
      })
    }, [currentService._id])

    useEffect(() => {
      changeCurrentService({
        ...currentService,
        _id: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0]._id : '',
        name: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].name : '',
        operator: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].operator : '',
        priceUser: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].priceForUser : 0,
        priceEmplyee: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].priceForEmployees : 0,
        docType: goods.docsTypes.filter(v => {
          if (goods.goods.find(g => {

            if (g.additional)
              return null

            if (goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()) && goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()).name) {
              return g.country.name == currentService.country && currentService.states && goods.states.find(s => currentService.states.toLowerCase().indexOf(s.name.toLowerCase()) != -1) && g.docType.name == v.name
            } else
              return g.country.name == currentService.country && g.docType.name == v.name
          }))
            return v
        }).length > 0 ? goods.docsTypes.filter(v => {
          if (goods.goods.find(g => {

            if (g.additional)
              return null

            if (goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()) && goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()).name) {
              return g.country.name == currentService.country && currentService.states && goods.states.find(s => currentService.states.toLowerCase().indexOf(s.name.toLowerCase()) != -1) && g.docType.name == v.name
            } else
              return g.country.name == currentService.country && g.docType.name == v.name
          }))
            return v
        })[0].name : '',
        generalInformation: {
          ...currentService.generalInformation,
          sample: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].sample : ''
        }
      })
    }, [currentService.country, currentService.states])

    useEffect(() => {
      changeCurrentService({
        ...currentService,
        _id: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0]._id : '',
        name: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].name : '',
        operator: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].operator : '',
        priceUser: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].priceForUser : 0,
        priceEmplyee: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].priceForEmployees : 0,
        generalInformation: {
          ...currentService.generalInformation,
          sample: goods.goods.filter(filterDocsNames).length > 0 ? goods.goods.filter(filterDocsNames)[0].sample : ''
        }
      })
    }, [currentService.docType])

    return (<div className={'w-100 h-100'}>
      <ImageModal
        show={showImageModal}
        close={() => { changeImageModal(''); changeShowImageModal(false) }}
        image={imageModal}
      />
      <h2>New order</h2>
      {
        orders.isError && orders.errors.length > 0
          ? <div className={'w-100 text-center text-danger'}>
            {
              orders.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
            }
          </div>
          : null
      }
      <form className={'pr-3 pl-3 pt-2'} onSubmit={(e) => { e.preventDefault(); sumbitAddOrder() }}>
        <div className={'border-bottom'}>
          <div className={'form-row'}>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'inpuBackground'}>Background(<a className={'text-info cursor-pointer'} onClick={() => openModalImage(goods.backgrounds.find(b => b._id == currentService.generalInformation.background) ? goods.backgrounds.find(b => b._id == currentService.generalInformation.background).image : '')}>show preview</a>)</label>
              <select value={currentService.generalInformation.background} onChange={(e) => setCurrentServiceField('generalInformation', e.target.value, 'background')} className={'form-control'} id={'inputBackground'} >
                {
                  goods.backgrounds.map((v, i) => (
                    <option key={i} value={v._id}>{v.name}</option>
                  ))
                }
              </select>
            </div>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'inputCameraModel'}>Camera model</label>
              <select value={currentService.generalInformation.cameraModel} onChange={(e) => setCurrentServiceField('generalInformation', e.target.value, 'cameraModel')} className={'form-control'} id={'inputCameraModel'} >
                {
                  goods.cameraModels.map((v, i) => (
                    <option key={i} value={v._id}>{v.name}</option>
                  ))
                }
              </select>
            </div>
            <div className={'form-group col-md-4'}>
              <label>Country</label>
              <select value={currentService.country} onChange={(e) => setCurrentServiceField('country', e.target.value)} className={'form-control'} required>
                {
                  goods.countrys.sort((prev, next) => {
                    if (prev.name < next.name) return -1;
                    if (prev.name < next.name) return 1;
                  }).map((v, i) => (
                    <option key={i} value={v.name}>{v.name}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'textareaNote'}>Your any note how take picture</label>
              <textarea value={currentService.generalInformation.howTakePicture} onChange={(e) => setCurrentServiceField('generalInformation', e.target.value, 'howTakePicture')} id={'textareaNote'} className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
            </div>
            <div className={'form-group col-md-6'}>
              <label>State</label>
              <select value={currentService.states} onChange={(e) => setCurrentServiceField('states', e.target.value)} className={'form-control'}>
                {
                  !currentService.states
                    ? <option value={null}></option>
                    : null
                }
                {
                  goods.states.filter(v => goods.countrys.find(c => c._id == v.country) && currentService.country == goods.countrys.find(c => c._id == v.country).name ? v : null)
                    .sort((prev, next) => {
                      if (prev.name < next.name) return -1;
                      if (prev.name < next.name) return 1;
                    }).map((v, i) => v.name ? (
                      <option key={i} value={v.name}>{v.name}</option>
                    ) : null)
                }
              </select>
              <label>Type document</label>
              <select value={currentService.docType} onChange={(e) => setCurrentServiceField('docType', e.target.value)} className={'form-control'}>
                {
                  goods.docsTypes.filter(v => {
                    if (goods.goods.find(g => {

                      if (g.additional)
                        return null

                      if (goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()) && goods.states.find(s => s.country.toString() == goods.countrys.find(c => c.name == currentService.country)._id.toString()).name) {
                        return g.country.name == currentService.country && currentService.states && goods.states.find(s => currentService.states.toLowerCase().indexOf(s.name.toLowerCase()) != -1) && g.docType.name == v.name
                      } else
                        return g.country.name == currentService.country && g.docType.name == v.name
                    }))
                      return v
                  }).sort((prev, next) => {
                    if (prev.name < next.name) return -1;
                    if (prev.name < next.name) return 1;
                  }).map((v, i) => (
                    <option key={i} value={v.name}>{v.name}</option>
                  ))
                }
              </select>
              <label>Name document</label>
              <select value={currentService._id} onChange={(e) => setCurrentServiceField('_id', e.target.value)} className={'form-control'}>
                {
                  goods.goods.filter(filterDocsNames).sort((prev, next) => {
                    if (prev.name < next.name) return -1;
                    if (prev.name < next.name) return 1;
                  }).map((v, i) => (
                    <option key={i} value={v._id}>{v.name}</option>
                  ))
                }
              </select>
              <span className={'pt-2 pb-2 d-inline-block'}>Sample: <button className={'btn btn-info'} type={'button'} onClick={() => currentService.generalInformation.sample ? openModalImage(currentService.generalInformation.sample) : null}>show</button></span>
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'selectTime'}>Order completion time</label>
              <input value={currentService.generalInformation.completeTime} type={'text'} className={'form-control'} readOnly />
            </div>
            <div className='form-group col-md-6 d-flex justify-content-start align-items-end'>
              <button type={'button'} className={'btn btn-primary'} onClick={addService}>add service</button>
            </div>
          </div>

          {
            allServices.length > 0
              ? <div className={'form-row d-flex justify-content-center align-items-start'}>
                <div className={'form-group col-md-6'}>
                  <label htmlFor={'inputLocationForPhoto'}>Your order</label>
                </div>
                <div className={'form-group col-md-6'}>
                  <label className={'w-100'} htmlFor={'inputLocationForPhoto'}>Order price</label>
                </div>
                {
                  allServices.map((s, si) => (
                    <div className="row w-100" key={si}>

                      <div className="col-12">
                        <div className={'form-row w-100'} >
                          <div className={'form-group col-md-6'}>
                            <input type={'text'} className={'form-control'} value={s.name} readOnly />
                          </div>
                          <div className={'form-group col-md-6'}>
                            <input type={'text'} className={'form-control w-auto float-left'} value={`${s.priceUser}$`} readOnly />
                            <button type={'button'} className={'btn btn-primary ml-2'} onClick={() => deleteService(s.id)}>delete service</button>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        {
                          s.additionalServices.map((sav, sai) => (
                            <div className={'form-row w-100'} key={sai}>
                              <div className={'form-group col-md-6'}>
                                <input type={'text'} className={'form-control'} value={sav.name} readOnly />
                              </div>
                              <div className={'form-group col-md-6'}>
                                <input type={'text'} className={'form-control w-auto float-left'} value={`${sav.priceUser}$`} readOnly />
                                <button type={'button'} className={'btn btn-primary ml-2'} onClick={() => deleteService(s.id, true, sav.id)}>delete service</button>
                              </div>
                            </div>
                          ))
                        }
                      </div>

                    </div>
                  ))
                }
              </div>
              : null
          }
        </div>
        {
          allServices.map((s, si) => (
            <div className={'border-top border-bottom pt-3'} key={si}>

              <div className="w-100">
                <h5 className={'text-center'}>service '{s.name}'</h5>
                <div className={'form-row'}>
                  {
                    s.serviceInformation.map((sf, sfi) => goods.goods.find(g => g._id.toString() == s._id.toString()).fields.find(gf => gf._id.toString() == sf.id.toString()) ? (
                      <div className={'form-group col-md-4'} key={sfi}>
                        <label className={'w-100'}>{sf.name}</label>
                        {
                          goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).fileUploader
                            ? <input accept={'image/*, .rar, .zip, .pdf'} onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setServiceInfoFiled(s.id, sf.id, e.target.files[0]) : e.target.value = null} type={'file'} className={'w-100'} required={goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).required} />
                            : goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).textInput
                              ? <input value={sf.value} onChange={(e) => setServiceInfoFiled(s.id, sf.id, applyPattern(e.target.value, goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).pattern))} type={'text'} className={'form-control'} required={goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).required} maxLength={goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).maxLength > 0 ? goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).maxLength : 'false'} />
                              : goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).select
                                ? <select value={sf.value} onChange={(e) => setServiceInfoFiled(s.id, sf.id, e.target.value)} className={'form-control'}>
                                  {
                                    goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).values.map((sfv, i) => (
                                      <option value={sfv} key={i}>{sfv}</option>
                                    ))
                                  }
                                </select>
                                : goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == sf.id).datePicker
                                  ? <DatePicker dateFormat={'MMMM d, yyyy'} className={'form-control'} selected={new Date(sf.value)} onChange={(date) => setServiceInfoFiled(s.id, sf.id, date.toString())} />
                                  : null
                        }
                      </div>
                    )
                      : null)
                  }
                </div>
              </div>

              {
                goods.goods.filter(v => v.additional && v.docType.name == s.docType ? v : null).length > 0
                  ? <div className="w-100">
                    <div className="row pt-2 pb-2 ">
                      <div className="col-3" />
                      <div className="col-3 d-flex align-items-center">
                        <h6 className={'w-100 text-right'}>Add additional service: </h6>
                      </div>
                      <div className="col-3 d-flex justify-content-center flex-column">
                        <select value={CurrentAdditionalService} onChange={e => changeCurrentAdditionalService(e.target.value)} className={'form-control'}>
                          {
                            goods.goods.filter(v => {

                              if (v.additional)
                                if (v.docType.name == s.docType)
                                  return v;

                            }).map((v, i) => (
                              <option key={i} value={v.name}>{v.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-3">
                        <button type={'button'} className="w-100 btn btn-primary mt-2" onClick={() => addNewAdditionalService(s.id, goods.goods.filter(v => v.additional && v.docType.name == s.docType ? v : null).length)}>Add</button>
                      </div>
                    </div>
                  </div>
                  : null
              }


              {
                s.additionalServices.length > 0
                  ? <div className="w-100 d-flex justify-content-center mb-2">
                    <div className="w-50 border-bottom" />
                  </div>
                  : null
              }

              {
                s.additionalServices.map((asv, asi) => (
                  <div className="w-100" key={asi}>
                    <h5 className={'text-center'}>Additional service '{asv.name}' for '{s.name}'</h5>
                    <div className={'form-row'}>
                      {
                        asv.serviceInformation.map((asvsf, asvsfi) => goods.goods.find(g => g._id.toString() == asv._id.toString()).fields.find(gf => gf._id.toString() == asvsf.id.toString()) ? (
                          <div className={'form-group col-md-4'} key={asvsfi}>
                            <label className={'w-100'}>{asvsf.name}</label>
                            {
                              goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).fileUploader
                                ? <input accept={'image/*, .rar, .zip, .pdf'} onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setAdditonalServiceInfoFiled(s.id, asv.id, asvsf.id, e.target.files[0]) : e.target.value = null} type={'file'} className={'w-100'} required={goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).required} />
                                : goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).textInput
                                  ? <input value={asvsf.value} onChange={(e) => setAdditonalServiceInfoFiled(s.id, asv.id, asvsf.id, applyPattern(e.target.value, goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).pattern))} type={'text'} className={'form-control'} required={goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).required} maxLength={goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).maxLength > 0 ? goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).maxLength : 'false'} />
                                  : goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).select
                                    ? <select value={asvsf.value} onChange={(e) => setAdditonalServiceInfoFiled(s.id, asv.id, asvsf.id, e.target.value)} className={'form-control'}>
                                      {
                                        goods.goods.find(g => g._id == asv._id).fields.find(gf => gf._id == asvsf.id).values.map((asvsfv, i) => (
                                          <option value={asvsfv} key={i}>{asvsfv}</option>
                                        ))
                                      }
                                    </select>
                                    : goods.goods.find(g => g._id == s._id).fields.find(gf => gf._id == asvsf.id).datePicker
                                      ? <DatePicker dateFormat={'MMMM d, yyyy'} className={'form-control'} selected={new Date(asvsf.value)} onChange={(date) => setAdditonalServiceInfoFiled(s.id, asv.id, asvsf.id, date.toString())} />
                                      : null
                            }
                          </div>
                        )
                          : null)
                      }
                    </div>
                  </div>
                ))
              }

            </div>
          ))
        }
        <div className={'pt-3 w-100 d-flex justify-content-center align-items-center'}>
          <button className={'btn btn-success'}>Order</button>
        </div>
      </form>
    </div >
    )

  })