import React from 'react';

import UserTransactionsListPage from '../components/Users/Transactions/list';
import EmployeeTransactionsListPage from '../components/Emplyees/Transactions';
import PageNotFound from './NotFound.view'
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - TRANSACTIONS</title>
      </Title>
      <UserTransactionsListPage />
    </div>
    : role == 'employee'
      ? <div>
        <Title>
          <title>DOCS PANEL - TRANSACTIONS</title>
        </Title>
        <EmployeeTransactionsListPage />
      </div>
      : <PageNotFound />
};