import React from 'react';

export default ({
  show,
  close,
  image
}) => {

  return (
    <div className={show ? 'iamge-wrapper position-absolute w-100 h-100 d-flex justify-content-center align-items-center' : 'd-none'}>
      <div className={'image-background position-absolute w-100 h-100'}></div>
      <div className={'w-50 p-2 border rounded bg-white image-form'}>
        <div className={'w-100'}>
          <div onClick={close} className={'float-right cursor-pointer'}>
            <i className={'fas fa-times'}></i>
          </div>
        </div>
        <div className={'w-100 h-100'}>
          {
            image && (image.indexOf('.pdf') != -1 || image.indexOf('application/pdf') != -1)
              ? <embed src={image} type={'application/pdf'} className={'w-100 h-100'} />
              : image && (image.indexOf('.rar') != -1 || image.indexOf('.zip') != -1)
                ? <span>This .rar or .zip file</span>
                : image
                  ? <img src={image} alt={'img'} className={'w-100 rounded'} />
                  : null
          }
        </div>
      </div>
    </div>
  );

}