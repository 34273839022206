import React, { useState, useRef } from 'react';

import ImageModal from '../../Default/ImageModal'

export default ({
  user,
  tickets,
  CurrentTicket,
  changeCurrentTicket,
  addTicket
}) => {
  const newImageRef = useRef()

  const [showImageModal, changeShowImageModal] = useState(false)
  const [imageModal, changeImageModal] = useState('')
  const [CurrentMessage, changeCurrentMessage] = useState('')
  const [CurrentImages, changeCurrentImages] = useState([])

  const changeCurrentTicketFiled = (name, value) => changeCurrentTicket({ ...CurrentTicket, [name]: value })
  const changeCurrentMessageFiled = (name, value) => changeCurrentMessage({ ...CurrentTicket, [name]: value })

  const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
    let result = '';

    for (let i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * characters.length));

    return result;
  }

  function isString(val) {
    return (typeof val === "string" || val instanceof String);
  }

  const addNewImage = (image) => {

    if (CurrentImages.length >= 6)
      return

    let reader = new FileReader();

    reader.onloadend = () => {

      if (reader.result.indexOf('application/pdf') == -1) {
        let newIamge = new Image()
        newIamge.src = reader.result

        newIamge.onload = () => {

          changeCurrentImages([...CurrentImages, { id: generateId(), file: image, img: newIamge }])

          newImageRef.current.value = null;
        }

      } else {
        changeCurrentImages([...CurrentImages, { id: generateId(), file: image, img: reader.result }])

        newImageRef.current.value = null;
      }

    }

    reader.readAsDataURL(image)

  }

  const deleteImage = (id) => changeCurrentImages(CurrentImages.filter(v => v.id != id))

  const showIamge = (image) => {

    changeShowImageModal(true)
    changeImageModal(image)

  }

  const submitTicketAdd = () => {
    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('ticket', JSON.stringify(CurrentTicket));
    formData.append('message', JSON.stringify(CurrentMessage));

    for (let i = 0; i < CurrentImages.length; i++)
      formData.append(CurrentImages[i].id, CurrentImages[i].file);

    addTicket(formData)
    changeCurrentMessage({
      ...CurrentMessage,
      text: ''
    })
    changeCurrentImages([])
  }

  return (<div className={'w-100 h-100'}>
    <ImageModal
      show={showImageModal}
      close={() => { changeImageModal(''); changeShowImageModal(false) }}
      image={imageModal}
    />
    <h2>Tickets</h2>
    {
      tickets.isError && tickets.errors.length > 0
        ? <div className={'w-100 text-center text-danger'}>
          {
            tickets.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
          }
        </div>
        : null
    }
    <form className={'m-0 w-100 d-flex flex-column ticket-form'} onSubmit={(e) => { e.preventDefault(); submitTicketAdd() }}>
      <div className='w-100 d-flex justify-content-center'>
        <div className={'w-75'}>
          <div className="w-100 row">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <button className="btn btn-primary" onClick={() => changeCurrentTicket(null)}>◄ back</button>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label htmlFor={'inpuSubject'}>Subject</label>
                <input type={'text'} value={CurrentTicket.subject} onChange={(e) => changeCurrentTicketFiled('subject', e.target.value)} className={'form-control'} id={'inputSubject'} required readOnly={CurrentTicket._id ? true : false} />
              </div>
            </div>
            <div className="col-5">
              <div className="form-group">
                <label htmlFor={'inpuOrderNumber'}>Order number(If you have)</label>
                <input type={'text'} value={CurrentTicket.orderNumber} onChange={(e) => changeCurrentTicketFiled('orderNumber', e.target.value)} className={'form-control'} id={'inputOrderNumber'} readOnly={CurrentTicket._id ? true : false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-100 flex-grow-1 d-flex justify-content-center'>
        <div className='w-75 h-100 overflow-auto'>
          {
            CurrentTicket.messages.length > 0
              ? CurrentTicket.messages.map((v, i) => (
                <div key={i} className={'w-100 pt-3 d-inline-block'}>
                  <div className={v.admin ? "w-50 float-left border-dark border p-1 rounded" : "w-50 float-right border-dark border p-1 rounded"}>
                    <h5 className={v.admin ? 'text-left m-0' : 'text-right m-0'}>{v.admin ? 'You' : CurrentTicket.from.email}</h5>
                    <div className="w-100 pb-2 border-bottom"></div>
                    <h6 className={v.admin ? 'text-left' : 'text-right'}>{v.text}</h6>
                    <div className="m-0 g-0 row d-flex justify-content-center align-items-center">
                      {
                        v.images.map((v, i) => (
                          <div key={i} className="m-0 g-0 col-6">
                            <a href="#" onClick={() => showIamge(`${location.host == 'localhost:1234' ? 'http://localhost:8000' : location.origin}/static/downloads/${v.link}`)}>
                              <h5>
                                {v.link}
                              </h5>
                            </a>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              ))
              : <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <h3>Messages list is empty</h3>
              </div>
          }
        </div>
      </div>
      <div className='w-100 d-flex justify-content-center'>
        <div className='w-75'>
          <div className='row'>
            <div className='col-10'>
              <textarea required className={'ticket-textarea'} value={CurrentMessage.text} onChange={(e) => changeCurrentMessageFiled('text', e.target.value)}></textarea>
            </div>
            <div className='col-2 d-flex justify-content-center align-item-center'>
              <button type={'submit'} className='w-100 btn btn-primary'>SEND</button>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-12">
              <label htmlFor={CurrentImages.length < 6 ? 'newImage' : ''} className={CurrentImages.length < 6 ? 'btn btn-primary' : 'btn btn-primary disabled'}>
                Add image
              </label>
              <input accept={'image/x-png,application/pdf,image/jpeg'} ref={newImageRef} onChange={e => addNewImage(e.target.files[0])} id={'newImage'} type="file" className={'invisible-input'} />
            </div>
            {
              CurrentImages.length > 0
                ? <div className="col-12">
                  <h2>Images</h2>
                </div>
                : null
            }
            {
              CurrentImages.map((v, i) => (
                <div key={i} className="pt-2 col-4 d-flex justify-content-center align-items-center">
                  <div className="row ">
                    <div className="col-6  d-flex justify-content-center align-items-center">
                      <a href="#" onClick={() => showIamge(isString(v.img) ? v.img : v.img.src)}>
                        <h5>
                          {v.file.name}
                        </h5>
                      </a>
                    </div>
                    <div className="col-6  d-flex justify-content-center align-items-center">
                      <a href="#" onClick={() => confirm('Delete this image?') ? deleteImage(v.id) : null}>
                        <h5>
                          delete?
                        </h5>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </form>
  </div >
  )

}