import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('FAQS');

export const createNewFaq = factory.create('CREATE_NEW_FAQ');
export const createNewFaqAsync = factory.createAsync('CREATE_NEW_FAQ_ASYNC');

export const editFaq = factory.create('EDIT_FAQ');
export const editFaqAsync = factory.createAsync('EDIT_FAQ_ASYNC');

export const deleteFaq = factory.create('DELETE_FAQ');
export const deleteFaqAsync = factory.createAsync('DELETE_FAQ_ASYNC');

export const getFaqs = factory.create('GET_FAQS');
export const getFaqsAsync = factory.createAsync('GET_FAQS_ASYNC');