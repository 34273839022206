import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { baseUrl } from '../../../common/config';

import EditModal from './EditModal'

import { clearOrdersErrors, takeOrder, editOrder } from '../../../store/actions/orders.actions';

const status = {
  'waiting for payment': 'rgb(255, 0, 0,.4)',
  'accepted': 'rgb(0, 171, 68,.4)',
  'in progress': 'rgb(74, 134, 232,.4)',
  'done': 'rgb(153, 0, 255,.4)',
  'confirm': 'rgb(183, 183, 18,.43)'
}

export default connect((s) => ({
  user: s.user,
  orders: s.orders,
  backgrounds: s.goods.backgrounds,
  cameraModels: s.goods.cameraModels,
  goods: s.goods.goods
}), {
  takeOrder,
  editOrder,
  clearOrdersErrors
})(
  ({
    user,
    orders,
    backgrounds,
    cameraModels,
    goods,
    takeOrder,
    editOrder,
    clearOrdersErrors
  }) => {
    const [searchBy, changeSearchBy] = useState('')
    const [currentOrders, changeCurrentOrders] = useState([])
    const [editModal, changeEditModal] = useState({      show: false,      order: null    })

    const openEditModal = (id) => changeEditModal({ ...editModal, show: true, order: orders.orders.find(o => o._id == id) });

    const applySearchBy = () => changeCurrentOrders(orders.orders.filter(o => o.user.email == searchBy))

    useEffect(() => {
      changeCurrentOrders(orders.orders.filter(o => o.user.email == searchBy))
    }, [orders])

    useEffect(() => {
      if (orders.errors.length > 0)
        clearOrdersErrors()
      return () => {
        if (orders.errors.length > 0)
          clearOrdersErrors()
      }
    }, [])

    return (<div className={'w-100 h-100'}>
      <EditModal
        show={editModal.show}
        order={editModal.order}
        close={() => changeEditModal({ ...editModal, show: false })}
        editOrder={editOrder}
        user={user}
        backgrounds={backgrounds}
        cameraModels={cameraModels}
        goods={goods}
      />
      <h2>Users</h2>
      {
        orders.isError && orders.errors.length > 0
          ? <div className={'w-100 text-center text-danger'}>
            {
              orders.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
            }
          </div>
          : null
      }
      <button
        className={'btn btn-primary m-1'}
        onClick={() => location.href = `${baseUrl}/api/users/emails?token=${user.token}`}
      >download all emails in csv</button>
      <div className={'w-100 d-flex justify-content-end'}>
        <form className={'pr-3 pl-3'} style={{ display: 'inherit' }} onSubmit={(e) => { e.preventDefault(); applySearchBy() }}>
          <input value={searchBy} onChange={(e) => changeSearchBy(e.target.value)} type={'text'} className={'form-control w-auto'} />
          <button className={'btn btn-primary w-auto'} type={'submit'}>search</button>
        </form>
      </div>
      <div className={'w-100 pt-3'}>
        <table className={'table table-striped'}>
          <thead>
            <tr>
              <th scope={'col'} >ID</th>
              <th scope={'col'}>Date</th>
              <th scope={'col'}>Time</th>
              <th scope={'col'}>Status</th>
              <th scope={'col'} >Amount</th>
              <th scope={'col'}>Services</th>
              <th scope={'col'}>Mistake</th>
              <th scope={'col'}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentOrders.map((v, i) => (
                <tr key={i} style={{ backgroundColor: status[v.status] }}>
                  <th className={'text-break'} style={{ width: '150px' }}>{v._id}</th>
                  <td>{v.createdAt}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <span key={i} className={'w-100 d-inline-block'}>{vs.generalInformation.completeTime}</span>
                      ))
                    }
                  </td>
                  <td>{v.status}</td>
                  <td>{v.fullPriceEmployee}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <span key={i} className={'w-100 d-inline-block'}>{vs.name}</span>
                      ))
                    }
                  </td>
                  <td>
                    {
                      v.mistake ? 'yes' : 'no'
                    }
                  </td>
                  <td>
                    <button
                      className={v.status == 'accepted' ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={v.status == 'accepted' ? false : true}
                      onClick={() => takeOrder({ token: user.token, orderId: v._id })}
                    >take</button>
                    <button
                      className={'btn btn-primary m-1'}
                      onClick={() => location.href = `${baseUrl}/api/orders/download?token=${user.token}&orderId=${v._id}`}
                    >download</button>
                    <button
                      className={(v.employee && ((v.employee._id && v.employee._id.toString() == user._id.toString()) || v.employee.toString() == user._id.toString()) && v.status != 'waiting for payment' && v.status != 'done' && v.status != 'confirm') || v.mistake ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={(v.employee && ((v.employee._id && v.employee._id.toString() == user._id.toString()) || v.employee.toString() == user._id.toString()) && v.status != 'waiting for payment' && v.status != 'done' && v.status != 'confirm') || v.mistake ? false : true}
                      onClick={() => openEditModal(v._id)}>edit</button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>)

  })