import React from 'react';

import { connect } from 'react-redux';

export default connect((s) => ({
  faqs: s.faqs.faqs
}), {

})(
  ({
    faqs
  }) => {

    return (<div className={'w-100 h-100'}>
      <h2>FAQ</h2>
      <div className={'tab-content d-flex align-items-start h-100 justify-content-center'} id={'faq-tab-content'}>
        <div className={'tab-pane show active w-75'} id={'tab1'} role={'tabpanel'} aria-labelledby={'tab1'}>
          <div className={'accordion'} id={'accordion-tab-1'}>
            {
              faqs.map((v, i) => <div className={'card'} key={i}>
                <div className={'card-header'} id={'accordion-tab-1-heading-1'}>
                  <h5 >
                    <button className={'btn btn-link float-left'} type={'button'} data-toggle={'collapse'} data-target={`#accordion-tab-1-content-${i}`} aria-expanded={'false'} aria-controls={`accordion-tab-1-content-${i}`}>{v.question}</button>
                  </h5>
                </div>
                <div className={'collapse'} id={`accordion-tab-1-content-${i}`} aria-labelledby={'accordion-tab-1-heading-1'} data-parent={'#accordion-tab-1'}>
                  <div className={'card-body'}>{v.answer}</div>
                </div>
              </div>)
            }
          </div>
        </div>
      </div>
    </div >)

  })