import 'rc-pagination/assets/index.css';

import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';

import { baseUrl } from '../../../common/config';
import { connect } from 'react-redux';
import { clearOrdersErrors, takeOrder, editOrder } from '../../../store/actions/orders.actions';

import EditModal from './EditModal'

const status = {
  'waiting for payment': 'rgb(255, 0, 0,.4)',
  'accepted': 'rgb(0, 171, 68,.4)',
  'in progress': 'rgb(74, 134, 232,.4)',
  'done': 'rgb(153, 0, 255,.4)',
  'confirm': 'rgb(183, 183, 18,.43)'
}

export default connect((s) => ({
  user: s.user,
  orders: s.orders,
  backgrounds: s.goods.backgrounds,
  cameraModels: s.goods.cameraModels,
  goods: s.goods.goods
}), {
  takeOrder,
  editOrder,
  clearOrdersErrors
})(
  ({
    user,
    orders,
    backgrounds,
    cameraModels,
    goods,
    takeOrder,
    editOrder,
    clearOrdersErrors
  }) => {
    const [CurrentPage, changeCurrentPage] = useState(1)
    const [editModal, changeEditModal] = useState({
      show: false,
      order: null
    })
    const [processedOrders, changeProcessedOrders] = useState(orders.orders);
    const [OrderId, changeOrderId] = useState('');
    const [ordersSort, changeOrderssSort] = useState({ date: 'default', amount: 'default' })

    const sortOrders = (name, value) => changeOrderssSort({ ...ordersSort, [name]: value })

    const openEditModal = (id) => changeEditModal({ ...editModal, show: true, order: orders.orders.find(o => o._id.toString() == id.toString()) });

    useEffect(() => {
      let sortedOrders = JSON.parse(JSON.stringify(orders.orders.filter(v => new Date(v.createdAt).getTime() + 24 * 3600 * 1000 < new Date().getTime() && (v.status == 'waiting for payment' || v.status == 'done'))))

      if (ordersSort.date == 'up')
        sortedOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (ordersSort.date == 'down')
        sortedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (ordersSort.amount == 'up')
        sortedOrders.sort((a, b) => a.fullPriceUser - b.fullPriceUser)
      else if (ordersSort.amount == 'down')
        sortedOrders.sort((a, b) => b.fullPriceUser - a.fullPriceUser)

      if (OrderId)
        sortedOrders = sortedOrders.filter(v => v._id.toString().indexOf(OrderId) != -1)

      changeProcessedOrders(sortedOrders)
    }, [orders.orders])

    useEffect(() => {
      let sortedOrders = JSON.parse(JSON.stringify(orders.orders.filter(v => new Date(v.createdAt).getTime() + 24 * 3600 * 1000 < new Date().getTime() && (v.status == 'waiting for payment' || v.status == 'done'))))

      if (ordersSort.date == 'up')
        sortedOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (ordersSort.date == 'down')
        sortedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (ordersSort.amount == 'up')
        sortedOrders.sort((a, b) => a.fullPriceUser - b.fullPriceUser)
      else if (ordersSort.amount == 'down')
        sortedOrders.sort((a, b) => b.fullPriceUser - a.fullPriceUser)

      if (OrderId)
        sortedOrders = sortedOrders.filter(v => v._id.toString().indexOf(OrderId) != -1)

      changeProcessedOrders(sortedOrders)
    }, [ordersSort])

    useEffect(() => {
      let sortedOrders = JSON.parse(JSON.stringify(orders.orders.filter(v => new Date(v.createdAt).getTime() + 24 * 3600 * 1000 < new Date().getTime() && (v.status == 'waiting for payment' || v.status == 'done'))))

      if (ordersSort.date == 'up')
        sortedOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (ordersSort.date == 'down')
        sortedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (ordersSort.amount == 'up')
        sortedOrders.sort((a, b) => a.fullPriceUser - b.fullPriceUser)
      else if (ordersSort.amount == 'down')
        sortedOrders.sort((a, b) => b.fullPriceUser - a.fullPriceUser)

      if (OrderId)
        sortedOrders = sortedOrders.filter(v => v._id.toString().indexOf(OrderId) != -1)

      changeProcessedOrders(sortedOrders)
    }, [OrderId])

    useEffect(() => {
      if (editModal.order)
        changeEditModal({ ...editModal, order: orders.orders.find(o => o._id == editModal.order._id) })
    }, [orders.orders])

    useEffect(() => {
      if (orders.errors.length > 0)
        clearOrdersErrors()
      return () => {
        if (orders.errors.length > 0)
          clearOrdersErrors()
      }
    }, [])

    return (<div className={'w-100 h-100'}>
      <EditModal
        show={editModal.show}
        order={editModal.order}
        close={() => changeEditModal({ ...editModal, show: false })}
        editOrder={editOrder}
        user={user}
        backgrounds={backgrounds}
        cameraModels={cameraModels}
        goods={goods}
      />
      <h2>Orders</h2>
      {
        orders.isError && orders.errors.length > 0
          ? <div className={'w-100 text-center text-danger'}>
            {
              orders.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
            }
          </div>
          : null
      }
      <div className={'w-100 d-inline-block p-2'}>
        <input type={'text'} className={'form-control'} value={OrderId} onChange={(e) => changeOrderId(e.target.value)} />
      </div>
      <table className={'table table-striped'}>
        <thead>
          <tr>
            <th scope={'col'} >ID</th>
            <th onClick={() => sortOrders('date', ordersSort.date == 'default' ? 'up' : ordersSort.date == 'up' ? 'down' : 'default')} scope='col' className={ordersSort.date == 'default' ? 'th-sm' : ordersSort.date == 'down' ? 'th-sm down' : ordersSort.date == 'up' ? 'th-sm up' : 'th-sm'}>Date</th>
            <th scope={'col'}>Time</th>
            <th scope={'col'}>Status</th>
            <th onClick={() => sortOrders('amount', ordersSort.amount == 'default' ? 'up' : ordersSort.amount == 'up' ? 'down' : 'default')} scope='col' className={ordersSort.amount == 'default' ? 'th-sm' : ordersSort.amount == 'down' ? 'th-sm down' : ordersSort.amount == 'up' ? 'th-sm up' : 'th-sm'}>Amount</th>
            <th scope={'col'}>Services</th>
            <th scope={'col'}>Mistake</th>
            <th scope={'col'}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            processedOrders.map((v, i) => i < CurrentPage * 30 && i >= (CurrentPage - 1) * 30
              ? (
                <tr key={i} style={{ backgroundColor: status[v.status] }}>
                  <th className={'text-break'} style={{ width: '150px' }}>{v._id}</th>
                  <td>{v.createdAt}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <span key={i} className={'w-100 d-inline-block'}>{vs.generalInformation.completeTime}</span>
                      ))
                    }
                  </td>
                  <td>{v.status}</td>
                  <td>{v.fullPriceEmployee}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <div key={i} className="w-100 pb-1">
                          <span className={'w-100 d-inline-block'}>{vs.name}{vs.serviceInformation.find(s => s.name == 'Last name') ? ` - ${vs.serviceInformation.find(s => s.name == 'Last name').value}` : vs.serviceInformation.find(s => s.name == 'Last name:') ? ` - ${vs.serviceInformation.find(s => s.name == 'Last name:').value}` : null}</span>
                        </div>
                      ))
                    }
                  </td>
                  <td>
                    {
                      v.mistake ? 'yes' : 'no'
                    }
                  </td>
                  <td>
                    <button
                      className={v.status == 'accepted' ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={v.status == 'accepted' ? false : true}
                      onClick={() => takeOrder({ token: user.token, orderId: v._id })}
                    >take</button>
                    <button
                      className={'btn btn-primary m-1'}
                      onClick={() => location.href = `${baseUrl}/api/orders/download?token=${user.token}&orderId=${v._id}`}
                    >download</button>
                    <button
                      className={(v.status != 'confirm') || v.mistake ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={(v.status != 'confirm') || v.mistake ? false : true}
                      onClick={() => openEditModal(v._id)}>edit</button>
                  </td>
                </tr>
              )
              : null
            )
          }
        </tbody>
      </table>
      <div className={'p-2'}>
        <Pagination className={'ant-pagination'} onChange={(current) => changeCurrentPage(current)} defaultCurrent={1} total={Math.ceil(processedOrders.length / 30) * 10} />
      </div>
    </div>)

  })