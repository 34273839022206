import { handleActions } from 'redux-actions';
import {
  addOrderAsync,
  addNewOrderAsync,
  editOrderAsync,
  editCurrentOrderAsync,
  addMistakeAsync,
  takeOrderAsync,
  payOrderAsync,
  deleteOrderAsync,
  deleteOldOrderAsync,
  getOrdersAsync,
  clearOrdersErrorsAsync,
} from '../actions/orders.actions';

const initialState = {
  orders: [],
  users: 0,
  isError: false,
  errors: []
};

const deleteElement = (array, elementId) => {
  var idx = array.indexOf(array.find(f => f._id == elementId));
  if (idx != -1) {
    array.splice(idx, 1);
    return JSON.parse(JSON.stringify(array));
  }
  return JSON.parse(JSON.stringify(array));
}

const editElement = (array, editedElement) => {
  if (array.find(f => f._id == editedElement._id))
    array[array.indexOf(array.find(f => f._id == editedElement._id))] = editedElement
  return JSON.parse(JSON.stringify(array));
}

export default handleActions({
  [addNewOrderAsync.success]: (s, a) => ({ ...s, orders: [...s.orders, a.payload] }),
  [deleteOldOrderAsync.success]: (s, a) => ({ ...s, orders: deleteElement(s.orders, a.payload._id) }),
  [editCurrentOrderAsync.success]: (s, a) => ({ ...s, orders: editElement(s.orders, a.payload) }),
  [getOrdersAsync.success]: (s, a) => ({ ...s, users: a.payload.data && a.payload.data.success && a.payload.data.users ? a.payload.data.users : 0, orders: a.payload.data && a.payload.data.success ? a.payload.data.orders : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getOrdersAsync.failed]: (s, a) => ({ ...s, users: a.payload.data && a.payload.data.success && a.payload.data.users ? a.payload.data.users : 0, orders: a.payload.data && a.payload.data.success ? a.payload.data.orders : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [editOrderAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? a.payload.data.order.status == 'cancel' ? deleteElement(s.orders, a.payload.data.order._id) : a.payload.data.order.status != 'cancel' ? editElement(s.orders, a.payload.data.order) : s.orders : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [editOrderAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addMistakeAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addMistakeAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [takeOrderAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [takeOrderAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addOrderAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? [...s.orders, a.payload.data.order] : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [addOrderAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? [...s.orders, a.payload.data.order] : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [payOrderAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [payOrderAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? editElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteOrderAsync.success]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? deleteElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteOrderAsync.failed]: (s, a) => ({ ...s, orders: a.payload.data && a.payload.data.success ? deleteElement(s.orders, a.payload.data.order) : s.orders, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [clearOrdersErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [] }),
  [clearOrdersErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [] }),
}, initialState);
