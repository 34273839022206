import React from 'react';

import UserEmplyeeProfilePage from '../components/Users/Profile';
import AdminProfilePage from '../components/Admin/Settings';
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'admin'
    ? <div>
      <Title>
        <title>DOCS PANEL - SETTINFS</title>
      </Title>
      <AdminProfilePage />
    </div>
    : <div>
      <Title>
        <title>DOCS PANEL - PROFILE</title>
      </Title>
      <UserEmplyeeProfilePage />
    </div>;
};