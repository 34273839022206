import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../common/store/helpers';
import {
  depositFor, depositForAsync,
  getLast, getLastAsync,
  addNewTransaction, addNewTransactionAsync,
  clearErrors, clearErrorsAsync
} from '../actions/transactions.actions';

import transactionsApi from '../../common/api/transactions.api';

function plugeWorker() {
  return true;
}

function addNewTransactionWorker(data) {
  return data;
}

export function* transactionsSaga() {
  yield takeEvery(depositFor, bindAsyncActions(depositForAsync)(transactionsApi.depositFor));
  yield takeEvery(getLast, bindAsyncActions(getLastAsync)(transactionsApi.getLast));
  yield takeEvery(addNewTransaction, bindAsyncActions(addNewTransactionAsync)(addNewTransactionWorker));
  yield takeEvery(clearErrors, bindAsyncActions(clearErrorsAsync)(plugeWorker));
}
