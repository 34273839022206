import React from 'react';

import LoginPage from '../components/Default/Login';
import Title from './../components/Default/TitleLayout'

export default ({

}) => {

  return <div>
    <Title>
      <title>DOCS PANEL - LOGIN</title>
    </Title>
    <LoginPage />
  </div>;
};