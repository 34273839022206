import React from 'react';

import { Link } from 'react-router-dom';

export default () => {


  return (<ul>
    <li>
      <Link to={'/tickets'}>
        <i className={'fa fa-cart-plus'}></i>
        <span>Tickets</span>
      </Link>
    </li>
    <li>
      <Link to={'/orders'}>
        <i className={'fa fa-cart-plus'}></i>
        <span>Orders</span>
      </Link>
    </li>
    <li className={'header-menu'}>
      <span>Transactions</span>
    </li>
    <li>
      <Link to={'/topup'}>
        <i className={'fa fa-dollar-sign'} aria-hidden={'true'}></i>
        <span>Payments</span>
      </Link>
    </li>
    <li className={'header-menu'}>
      <span>Info</span>
    </li>
    <li>
      <Link to={'/profile'}>
        <i className={'fa fa-sliders-h'}></i>
        <span>Settings</span>
      </Link>
    </li>
    <li>
      <Link to={'/team'}>
        <i className={'fa fa-user-plus'}></i>
        <span>Team</span>
      </Link>
    </li>
    <li>
      <Link to={'/Users'}>
        <i className={'fa fa-users'}></i>
        <span>Users</span>
      </Link>
    </li>
    <li>
      <Link to={'/faq'}>
        <i className={'fa fa-info-circle'}></i>
        <span>FAQ</span>
      </Link>
    </li>
  </ul>)
}