import axios from 'axios';
import { baseUrl } from '../config';

axios.defaults.withCredentials = true;

export default class usersApi {
  static register = (data) => axios.post(`${baseUrl}/api/users/`, data);
  static login = (data) => axios.post(`${baseUrl}/api/users/login`, data);
  static recovery = (data) => axios.put(`${baseUrl}/api/users/recovery`, data);
  static changePassword = (data) => axios.post(`${baseUrl}/api/users/change/password`, data);
  static changeEmail = (data) => axios.post(`${baseUrl}/api/users/change/email`, data);
  static changeTelegram = (data) => axios.post(`${baseUrl}/api/users/change/telegramId`, data);
  static changeType = (data) => axios.post(`${baseUrl}/api/users/change/type`, data);
  static setNotification = (data) => axios.post(`${baseUrl}/api/users/notification`, data);
  static getUserUpdates = (token) => axios.get(`${baseUrl}/api/users/current?token=${token}`);
  static createDeposit = (data) => axios.post(`${baseUrl}/api/deposit/`, data);
  static requestConfirmCode = (token) => axios.get(`${baseUrl}/api/users/confirmCode?token=${token}`);
  static requestRecovery = (RestoreData) => axios.get(`${baseUrl}/api/users/recovery?RestoreData=${RestoreData}`);
}