import React from 'react';

export default ({
  show = false,
  content = '',
  close
}) => {

  const parseUrl = (text) => text.replace(/((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g, '<a href="$1" target="_blank">$1</a>').split("</a> ").join("</a>");

  return (
    show && content
      ? <div className={'info-modal-wrapper'}>
        <div className="info-modal-bg" />
        <div className={'info-modal'}>
          <div className="info-modal-header">
            <h3 className={'d-inline-block'}>Info message</h3>
            <span className={'ml-5 cursor-pointer d-flex align-items-center'} onClick={close}>close</span>
          </div>
          <div className="info-modal-content">
            <span dangerouslySetInnerHTML={{ __html: parseUrl(content) }}></span>
          </div>
        </div >
      </div >
      : null
  )

}