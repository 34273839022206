import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('SETTINGS');

export const setNewSettings = factory.create('SET_NEW_SETTINGS');
export const setNewSettingsAsync = factory.createAsync('SET_NEW_SETTINGS_ASYNC');

export const getCurrentSettings = factory.create('GET_CURRENT_SETTINGS');
export const getCurrentSettingsAsync = factory.createAsync('GET_CURRENT_SETTINGS_ASYNC');

export const clearSettingsErrors = factory.create('CLEAR_ERRORS');
export const clearSettingsErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');