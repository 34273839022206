import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router'
import { bindAsyncActions } from '../../common/store/helpers';
import {
  addTicket, addTicketAsync,
  getTickets, getTicketsAsync,
  closeTicket, closeTicketAsync,
  newTicket, newTicketAsync,
  closedTicket, closedTicketAsync,
  clearTicketsErrors, clearTicketsErrorsAsync
} from '../actions/tickets.actions';

import ticketsApi from '../../common/api/tickets.api';

function plugeWorker() {
  return true;
}

function passer(data) {
  return data;
}

export function* ticketsSaga() {
  yield takeEvery(addTicket, bindAsyncActions(addTicketAsync)(ticketsApi.addTicket));
  yield takeEvery(getTickets, bindAsyncActions(getTicketsAsync)(ticketsApi.getTickets));
  yield takeEvery(closeTicket, bindAsyncActions(closeTicketAsync)(ticketsApi.closeTicket));
  yield takeEvery(newTicket, bindAsyncActions(newTicketAsync)(passer));
  yield takeEvery(closedTicket, bindAsyncActions(closedTicketAsync)(passer));
  yield takeEvery(clearTicketsErrors, bindAsyncActions(clearTicketsErrorsAsync)(plugeWorker));
}
