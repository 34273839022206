import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('GOODS');

export const addBackground = factory.create('ADD_BACKGROUND');
export const addBackgroundAsync = factory.createAsync('ADD_BACKGROUND_ASYNC');

export const deleteBackground = factory.create('DELETE_BACKGROUND');
export const deleteBackgroundAsync = factory.createAsync('DELETE_BACKGROUND_ASYNC');

export const getBackgrounds = factory.create('GET_BACKGROUNDS');
export const getBackgroundsAsync = factory.createAsync('GET_BACKGROUNDS_ASYNC');

export const addCamerModel = factory.create('ADD_CAMERA_MODEL');
export const addCamerModelAsync = factory.createAsync('ADD_CAMERA_MODEL_ASYNC');

export const deleteCamerModel = factory.create('DELETE_CAMERA_MODEL');
export const deleteCamerModelAsync = factory.createAsync('DELETE_CAMERA_MODEL_ASYNC');

export const getCamerModels = factory.create('GET_CAMERA_MODELS');
export const getCamerModelsAsync = factory.createAsync('GET_CAMERA_MODELS_ASYNC');

export const addGood = factory.create('ADD_GOOD');
export const addGoodAsync = factory.createAsync('ADD_GOOD_ASYNC');

export const deleteGood = factory.create('DELETE_GOOD');
export const deleteGoodAsync = factory.createAsync('DELETE_GOOD_ASYNC');

export const editGood = factory.create('EDIT_GOOD');
export const editGoodAsync = factory.createAsync('EDIT_GOOD_ASYNC');

export const getGoods = factory.create('GET_GOODS');
export const getGoodsAsync = factory.createAsync('GET_GOODS_ASYNC');

export const deleteOldOrders = factory.create('DELETE_OLD_ORDERS');
export const deleteOldOrdersAsync = factory.createAsync('DELETE_OLD_ORDERS_ASYNC');

export const getCountrys = factory.create('GET_COUNTRYS');
export const getCountrysAsync = factory.createAsync('GET_COUNTRYS_ASYNC');

export const getStates = factory.create('GET_STATES');
export const getStatesAsync = factory.createAsync('GET_STATES_ASYNC');

export const getDocsTypes = factory.create('GET_DOCS_TYPES');
export const getDocsTypesAsync = factory.createAsync('GET_DOCS_TYPES_ASYNC');

export const clearGoodsErrors = factory.create('CLEAR_ERRORS');
export const clearGoodsErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');