import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

export default connect((s) => ({
  transactions: s.transactions
}), {

})(
  ({
    transactions
  }) => {

    const [processedTransactions, changeProcessedTransactions] = useState(transactions.transactions);
    const [transactionsSort, changeTransactionsSort] = useState({ date: 'default', amount: 'default' })

    const sortTransactions = (name, value) => changeTransactionsSort({ ...transactionsSort, [name]: value })

    useEffect(() => {
      let sortedTransaction = JSON.parse(JSON.stringify(transactions.transactions))

      if (transactionsSort.date == 'up')
        sortedTransaction.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (transactionsSort.date == 'down')
        sortedTransaction.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (transactionsSort.amount == 'up')
        sortedTransaction.sort((a, b) => a.value - b.value)
      else if (transactionsSort.amount == 'down')
        sortedTransaction.sort((a, b) => b.value - a.value)

      changeProcessedTransactions(sortedTransaction)
    }, [transactions.transactions])

    useEffect(() => {
      let sortedTransaction = JSON.parse(JSON.stringify(transactions.transactions))

      if (transactionsSort.date == 'up')
        sortedTransaction.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (transactionsSort.date == 'down')
        sortedTransaction.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (transactionsSort.amount == 'up')
        sortedTransaction.sort((a, b) => a.value - b.value)
      else if (transactionsSort.amount == 'down')
        sortedTransaction.sort((a, b) => b.value - a.value)

      changeProcessedTransactions(sortedTransaction)
    }, [transactionsSort])

    return (<div className={'w-100 h-100'}>
      <h5 className={'text-center'}>Last 100 transactions</h5>
      <table className={'table table-striped'}>
        <thead>
          <tr>
            <th scope={'col'} >ID</th>
            <th onClick={() => sortTransactions('date', transactionsSort.date == 'default' ? 'up' : transactionsSort.date == 'up' ? 'down' : 'default')} scope={'col'} className={transactionsSort.date == 'default' ? 'th-sm' : transactionsSort.date == 'down' ? 'th-sm down' : transactionsSort.date == 'up' ? 'th-sm up' : 'th-sm'}>Date</th>
            <th scope={'col'}>Status</th>
            <th onClick={() => sortTransactions('amount', transactionsSort.amount == 'default' ? 'up' : transactionsSort.amount == 'up' ? 'down' : 'default')} scope={'col'} className={transactionsSort.amount == 'default' ? 'th-sm' : transactionsSort.amount == 'down' ? 'th-sm down' : transactionsSort.amount == 'up' ? 'th-sm up' : 'th-sm'}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {
            processedTransactions.map((v, i) => <tr key={i}>
              <th scope={'row'}>{i + 1}</th>
              <td>{v.createdAt}</td>
              <td>{v.type}</td>
              <td>{v.value}</td>
            </tr>)
          }
        </tbody>
      </table>
    </div>)

  })