import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../common/store/helpers';
import {
  registerAccount, registerAccountAsync,
  loginIn, loginInAsync,
  getUserUpdates, getUserUpdatesAsync,
  clearErrors, clearErrorsAsync,
  requestConfirmCode, requestConfirmCodeAsync,
  changePassword, changePasswordAsync,
  changeEmail, changeEmailAsync,
  changeTelegramId, changeTelegramIdAsync,
  changeType, changeTypeAsync,
  setNotification, setNotificationAsync,
  createDeposit, createDepositAsync,
  clearLoggedNow, clearLoggedNowAsync,
  clearDepositLink, clearDepositLinkAsync,
  logout, logoutAsync,
  recovery, recoveryAsync,
  requestRecovery, requestRecoveryAsync
} from '../actions/users.actions';

import usersApi from '../../common/api/users.api';

function plugeWorker() {
  return true;
}

export function* usersSaga() {
  yield takeEvery(registerAccount, bindAsyncActions(registerAccountAsync)(usersApi.register));
  yield takeEvery(loginIn, bindAsyncActions(loginInAsync)(usersApi.login));
  yield takeEvery(recovery, bindAsyncActions(recoveryAsync)(usersApi.recovery));
  yield takeEvery(getUserUpdates, bindAsyncActions(getUserUpdatesAsync)(usersApi.getUserUpdates));
  yield takeEvery(requestConfirmCode, bindAsyncActions(requestConfirmCodeAsync)(usersApi.requestConfirmCode));
  yield takeEvery(requestRecovery, bindAsyncActions(requestRecoveryAsync)(usersApi.requestRecovery));
  yield takeEvery(changePassword, bindAsyncActions(changePasswordAsync)(usersApi.changePassword));
  yield takeEvery(changeEmail, bindAsyncActions(changeEmailAsync)(usersApi.changeEmail));
  yield takeEvery(changeTelegramId, bindAsyncActions(changeTelegramIdAsync)(usersApi.changeTelegram));
  yield takeEvery(changeType, bindAsyncActions(changeTypeAsync)(usersApi.changeType));
  yield takeEvery(setNotification, bindAsyncActions(setNotificationAsync)(usersApi.setNotification));
  yield takeEvery(createDeposit, bindAsyncActions(createDepositAsync)(usersApi.createDeposit));
  yield takeEvery(logout, bindAsyncActions(logoutAsync)(plugeWorker));
  yield takeEvery(clearErrors, bindAsyncActions(clearErrorsAsync)(plugeWorker));
  yield takeEvery(clearLoggedNow, bindAsyncActions(clearLoggedNowAsync)(plugeWorker));
  yield takeEvery(clearDepositLink, bindAsyncActions(clearDepositLinkAsync)(plugeWorker));
}
