import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { registerAccount, clearErrors } from '../../../store/actions/users.actions';
import { setNewSettings } from '../../../store/actions/settings.actions';

export default connect((s) => ({
  user: s.user,
  settings: s.settings
}), {
  registerAccount,
  setNewSettings
})(
  ({
    user,
    settings,
    registerAccount,
    setNewSettings
  }) => {

    const [didMount, changeDidMount] = useState(false)
    const [didSettingsUpdate, changeSettingsUpdate] = useState(false)
    const [employeeAccount, changeEmployeeAccount] = useState({ login: '', email: '', password: '', rewritePassword: '', accountType: 'employee' })
    const [newEmployee, changeNewEmployee] = useState({ telegramId: '', user: '' })

    const setEmployeeFiled = (name, value) => changeEmployeeAccount({ ...employeeAccount, [name]: value });

    const setnewEmployeeFiled = (name, value) => changeNewEmployee({ ...newEmployee, [name]: value });

    const sumbitRegisterEmployee = () => {
      registerAccount({ user: employeeAccount, token: user.token })
      setTimeout(() => {
        if (!user.isError) {
          changeEmployeeAccount({ login: '', email: '', password: '', rewritePassword: '', accountType: 'employee' })
          alert('Employee account created')
        }
      }, 2 * 1000)
    }
    const sumbitAddEmployee = () => setNewSettings({ token: user.token, newSettings: { ...settings.settings, emplyees: [...settings.settings.emplyees, newEmployee] } })

    const deleteEmployee = (email) => {
      changeSettingsUpdate(false)
      setNewSettings({ token: user.token, newSettings: { ...settings.settings, emplyees: settings.settings.emplyees.filter(v => v.user != email) } })
    }

    useEffect(() => {
      changeDidMount(true)
      if (user.errors.length > 0)
        clearErrors()
      return () => {
        if (user.errors.length > 0)
          clearErrors()
      }
    }, [])

    useEffect(() => {
      if (didMount && !user.isError) {
        changeEmployeeAccount({ name: '', login: '', email: '', password: '', rewritePassword: '', type: 'employee' })
        alert('Employee account created')
      }

    }, [user.isError]);

    useEffect(() => {
      if (didMount && didSettingsUpdate) {
        changeNewEmployee({
          telegramId: '',
          user: ''
        })
        alert('Employee add')
      } else if (didMount && !didSettingsUpdate) {
        changeSettingsUpdate(true)
      }

    }, [settings.settings.emplyees]);

    return (<div className={'w-100 h-100'}>
      <h2>Team</h2>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>Create employee account</h5>
        {
          user.isError && user.errors.length > 0
            ? <div className={'w-100 text-center text-danger'}>
              {
                user.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
              }
            </div>
            : null
        }
        <form className={'m-0 pr-4 pl-4'} onSubmit={(e) => { e.preventDefault(); sumbitRegisterEmployee() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inpuLogin'}>Login</label>
              <input type={'text'} value={employeeAccount.login} onChange={(e) => setEmployeeFiled('login', e.target.value)} className={'form-control'} id={'inputLogin'} required />
            </div>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputPassword'}>Password</label>
              <input type={'password'} value={employeeAccount.password} onChange={(e) => setEmployeeFiled('password', e.target.value)} className={'form-control'} id={'inputPassword'} required />
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputEmail4'}>Email</label>
              <input type={'email'} value={employeeAccount.email} onChange={(e) => setEmployeeFiled('email', e.target.value)} className={'form-control'} id={'inputEmail4'} required />
            </div>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputRewritePassword'}>Rewrite password</label>
              <input type={'password'} value={employeeAccount.rewritePassword} onChange={(e) => setEmployeeFiled('rewritePassword', e.target.value)} className={'form-control'} id={'inputRewritePassword'} required />
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-12 d-flex justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'}>Create</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100 mt-3 border-bottom mb-2'}>
        <h5 className={'text-center'}>add employee telegram</h5>
        {
          settings.isError && settings.errors.length > 0
            ? <div className={'w-100 text-center text-danger'}>
              {
                settings.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
              }
            </div>
            : null
        }
        <form className={'m-0 pr-4 pl-4'} onSubmit={(e) => { e.preventDefault(); sumbitAddEmployee() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputEmail4'}>Email</label>
              <input type={'email'} value={newEmployee.user} onChange={(e) => setnewEmployeeFiled('user', e.target.value)} className={'form-control'} id={'inputEmail4'} required />
            </div>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputRewritePassword'}>TelegramId</label>
              <input type={'text'} value={newEmployee.telegramId} onChange={(e) => setnewEmployeeFiled('telegramId', e.target.value)} className={'form-control'} id={'inputRewritePassword'} required />
            </div>
          </div>
          <div className={'form-row '}>
            <div className={'form-group col-md-12 d-flex justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'}>Create</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100 mt-3'}>
        <h5 className={'text-center'}>list telegram id of employees</h5>
        <div className={'pr-4 pl-4'}>
          {
            settings.settings.emplyees
              ? settings.settings.emplyees.map((v, i) => <form key={i} className={'m-0'} onSubmit={(e) => e.preventDefault()}>
                <div className={'form-row'}>
                  <div className={'form-group col-md-5'}>
                    <label htmlFor={'inputEmail4'}>Email</label>
                    <input type={'email'} value={v.user} className={'form-control'} id={'inputEmail4'} readOnly />
                  </div>
                  <div className={'form-group col-md-5'}>
                    <label htmlFor={'inputRewritePassword'}>TelegramId</label>
                    <input type={'text'} value={v.telegramId} className={'form-control'} id={'inputRewritePassword'} readOnly />
                  </div>
                  <div className={'form-group col-md-2 d-flex justify-content-center align-items-center'}>
                    <i onClick={() => deleteEmployee(v.user)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                  </div>
                </div>
              </form>)
              : null
          }
        </div>
      </div>
    </div>)

  })