import axios from 'axios';
import { baseUrl } from '../config';

axios.defaults.withCredentials = true;

export default class ordersApi {
  static deleteBackground = (data) => axios.delete(`${baseUrl}/api/backgrounds`, { data },);
  static addBackground = (data) => axios.post(`${baseUrl}/api/backgrounds`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static getBackgrounds = (token) => axios.get(`${baseUrl}/api/backgrounds?token=${token}`);

  static deleteCameraModel = (data) => axios.delete(`${baseUrl}/api/cameraModels`, { data });
  static addCameraModel = (data) => axios.post(`${baseUrl}/api/cameraModels`, data);
  static getCameraModels = (token) => axios.get(`${baseUrl}/api/cameraModels?token=${token}`);

  static deleteGood = (data) => axios.delete(`${baseUrl}/api/goods`, { data });
  static addGood = (data) => axios.post(`${baseUrl}/api/goods`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static editGood = (data) => axios.post(`${baseUrl}/api/goods/edit`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static getGoods = (token) => axios.get(`${baseUrl}/api/goods?token=${token}`);

  static getCountrys = () => axios.get(`${baseUrl}/api/countrys`);
  static getStates = () => axios.get(`${baseUrl}/api/states`);
  static getDocsTypes = () => axios.get(`${baseUrl}/api/docs-types`);

  static deleteOldOrders = (data) => axios.delete(`${baseUrl}/api/orders/old`, { data });
}