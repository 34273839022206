import { handleActions } from 'redux-actions';
import { setNewSettingsAsync, getCurrentSettingsAsync, clearSettingsErrorsAsync } from '../actions/settings.actions';

const initialState = {
	settings: {
		paymentMethods: { coinbase: false, btcpay: false },
		blockNewRegistrations: false,
		closeUsersPage: false,
		telegramChatId: '',
		emplyees: [],
	},
	isError: false,
	errors: [],
};

export default handleActions(
	{
		[setNewSettingsAsync.success]: (s, a) => ({
			...s,
			settings: a.payload.data && a.payload.data.success ? a.payload.data.settings : s.settings,
			isError: a.payload.data && a.payload.data.success ? false : true,
			errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [],
		}),
		[setNewSettingsAsync.failed]: (s, a) => ({
			...s,
			settings: a.payload.data && a.payload.data.success ? a.payload.data.settings : s.settings,
			isError: a.payload.data && a.payload.data.success ? false : true,
			errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [],
		}),
		[getCurrentSettingsAsync.success]: (s, a) => ({
			...s,
			settings: a.payload.data && a.payload.data.success ? a.payload.data.settings : s.settings,
			isError: a.payload.data && a.payload.data.success ? false : true,
			errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [],
		}),
		[getCurrentSettingsAsync.failed]: (s, a) => ({
			...s,
			settings: a.payload.data && a.payload.data.success ? a.payload.data.settings : s.settings,
			isError: a.payload.data && a.payload.data.success ? false : true,
			errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [],
		}),
		[clearSettingsErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [] }),
		[clearSettingsErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [] }),
	},
	initialState
);
