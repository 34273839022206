import { all, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

import { usersSaga } from './users.saga';
import { faqsSaga } from './faqs.saga';
import { transactionsSaga } from './transactions.saga';
import { settingsSaga } from './settings.saga';
import { goodsSaga } from './goods.saga';
import { ordersSaga } from './orders.saga';
import { ticketsSaga } from './tickets.saga';

export default function* () {
  yield take(REHYDRATE);
  yield all([
    usersSaga(),
    faqsSaga(),
    transactionsSaga(),
    settingsSaga(),
    goodsSaga(),
    ordersSaga(),
    ticketsSaga(),

  ]);
}
