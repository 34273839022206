import React, { useEffect, useState, useRef } from 'react';
import Draggable from 'react-draggable';

import ImageModal from '../../Default/ImageModal'
import MultipleSelect from '../../Default/MultipleSelect'

import { connect } from 'react-redux';

import {
  deleteBackground, addBackground,
  addCamerModel, deleteCamerModel,
  addGood, deleteGood, editGood,
  deleteOldOrders,
  clearGoodsErrors
} from '../../../store/actions/goods.actions';

export default connect((s) => ({
  user: s.user,
  goods: s.goods,
  settings: s.settings
}), {
  clearGoodsErrors,
  addBackground,
  deleteBackground,
  addCamerModel,
  deleteCamerModel,
  addGood,
  editGood,
  deleteGood,
  deleteOldOrders
})(
  ({
    user,
    goods,
    settings,
    clearGoodsErrors,
    addBackground,
    deleteBackground,
    addCamerModel,
    deleteCamerModel,
    addGood,
    editGood,
    deleteGood,
    deleteOldOrders
  }) => {
    const formRef = useRef()

    const [showImageModal, changeShowImageModal] = useState(false)
    const [imageModal, changeImageModal] = useState('')
    const [fieldValue, changeFieldValue] = useState('')
    const [Operator, changeOperator] = useState('null')
    const [newGood, changeNewGood] = useState({ priceForUser: 10, priceForEmployees: 10, country: null, states: [], docType: null, name: '', additional: false, sample: '', fields: [] })
    const [newBackground, changeNewBackground] = useState({ name: '', image: '' })
    const [newCameraModel, changeNewCameraModel] = useState({ name: '' })

    const setNewBackgroundField = (name, value) => changeNewBackground({ ...newBackground, [name]: value })
    const setNewCameraModelField = (name, value) => changeNewCameraModel({ ...newCameraModel, [name]: value })
    const setNewGoodField = (name, value) => changeNewGood({ ...newGood, [name]: value })
    const setNewFieldForGood = (id, name, value) => changeNewGood({
      ...newGood,
      fields: newGood.fields.map(v => {
        let patterns = ['fileUploader', 'datePicker', 'select', 'textInput']
        if (v.id == id)
          if (patterns.indexOf(name) != -1) {
            patterns.map(vv => {
              v[vv] = false;
            })
            return { ...v, [name]: value }
          } else
            return { ...v, [name]: value }
        else
          return v
      })
    })

    const addNewFieldForGood = () => changeNewGood({ ...newGood, fields: [...newGood.fields, { id: generateId(), name: '', required: false, fileUploader: false, datePicker: false, select: false, textInput: false, maxLength: 0, pattern: 'default', values: [] }] })
    const addNewValueForFieldForGood = (id) => {
      changeNewGood({ ...newGood, fields: newGood.fields.map(v => v.id == id ? ({ ...v, values: ([...v.values, fieldValue]).sort() }) : v) })
      changeFieldValue('')
    }
    const deleteValueForFieldForGood = (id, value) => {
      changeNewGood({ ...newGood, fields: newGood.fields.map(v => v.id == id ? ({ ...v, values: v.values.filter(v => v != value) }) : v) })
    }
    const deleteFieldForGood = (id) => changeNewGood({ ...newGood, fields: newGood.fields.filter(v => v.id != id) })

    const submitAddBackground = () => {
      let formData = new FormData();
      formData.append('token', user.token);
      formData.append('name', newBackground.name);
      formData.append('image', newBackground.image);
      addBackground(formData)
      changeNewBackground({ name: '', image: '' })
    }
    const submitAddCameraModel = () => {
      addCamerModel({ token: user.token, cameraModel: newCameraModel })
      changeNewCameraModel({ name: '', })
    }
    const submitProcessGood = () => {

      if (!newGood._id) {

        if (!newGood.sample)
          return alert('Add sample')

        let formData = new FormData();
        formData.append('token', user.token);
        formData.append('operator', Operator);
        formData.append('good', JSON.stringify(newGood));
        formData.append('sample', newGood.sample);
        addGood(formData)
        alert('good added')
      } else if (newGood._id) {
        let formData = new FormData();
        formData.append('token', user.token);
        formData.append('operator', Operator);
        formData.append('good', JSON.stringify(newGood));
        formData.append('sample', newGood.sample);
        editGood(formData)
        alert('good edited')
      }
      changeOperator('null')
      changeNewGood({ priceForUser: 10, priceForEmployees: 10, country: null, states: [], docType: null, name: '', additional: false, sample: '', fields: [] })
    }

    const submitDeleteBackground = (id) => deleteBackground({ token: user.token, backgroundId: id })
    const submitDeleteCamerModel = (id) => deleteCamerModel({ token: user.token, cameraModelId: id })
    const submitDeleteGood = (id) => deleteGood({ token: user.token, goodId: id })

    const openImageModal = (img) => {
      changeShowImageModal(true)
      changeImageModal(img)
    }

    const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
      let result = '';

      for (let i = 0; i < length; i++)
        result += characters.charAt(Math.floor(Math.random() * characters.length));

      return result;
    }

    const duplicateGood = (good) => changeNewGood({ ...good, _id: null, fields: good.fields.map(v => ({ ...v, id: generateId() })) })
    const duplicateForEditGood = (good) => {

      changeNewGood({ ...good, fields: good.fields.map(v => ({ ...v, id: generateId() })) })

      location.hash = "#" + 'edit';

    }

    const onStopDragValueInList = (id, dragData) => {

      let valueNum = Array.from(dragData.node.parentNode.children).indexOf(dragData.node)
      let valuePos = 0
      let valuesHeight = 0
      let move = 0
      let newFields = JSON.parse(JSON.stringify(newGood.fields))
      let currentField = newFields[valueNum]

      for (let i = 0; i < valueNum; i++)
        valuePos += dragData.node.parentNode.children[i].clientHeight

      move = valuePos + dragData.y

      for (let i = 0; i < Array.from(dragData.node.parentNode.children).length; i++) {
        if (move < valuesHeight) {
          newFields.splice(valueNum, 1)
          newFields.splice(i, 0, currentField)
          break;
        } else if (i == Array.from(dragData.node.parentNode.children).length - 1 && move > valuesHeight) {
          newFields.splice(valueNum, 1)
          newFields.push(currentField)
        }

        valuesHeight += dragData.node.parentNode.children[i].clientHeight
      }

      changeNewGood({ ...newGood, fields: newFields })

    }

    useEffect(() => {
      if (goods.errors.length > 0)
        clearGoodsErrors()
      return () => {
        if (goods.errors.length > 0)
          clearGoodsErrors()
      }
    }, [])

    useEffect(() => {

      if (Operator == 'null' && newGood._id)
        changeOperator(newGood.operator)

    }, [newGood])

    return (<div className={'w-100 h-100'}>
      <ImageModal
        show={showImageModal}
        close={() => changeShowImageModal(false)}
        image={imageModal}
      />
      <h2>ORDERS</h2>
      <div className={'w-100'}>
        {
          goods.isError && goods.errors.length > 0
            ? <div className={'w-100 text-center text-danger'}>
              {
                goods.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
              }
            </div>
            : null
        }
      </div>
      <div className={'w-100 d-flex justify-content-center align-items-center pt-3 pb-3'}>
        <button className={'btn btn-warning'} onClick={() => { if (confirm('Are you sure that you want to delete all orders and files older than 60 days?')) deleteOldOrders({ token: user.token }) }}>Delete all old orders</button>
      </div>
      <div className={'w-100 mb-2'}>
        <h5 className={'text-center'}>add background</h5>
        <form className={'m-0 pr-4 pl-4'} onSubmit={(e) => { e.preventDefault(); submitAddBackground() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-6'}>
              <label>name</label>
              <input type={'text'} value={newBackground.name} onChange={(e) => setNewBackgroundField('name', e.target.value)} className={'form-control'} required />
            </div>
            <div className={'form-group col-md-6'}>
              <label htmlFor={'inputRewritePassword'}>image</label>
              <input type={'file'} accept={'image/*, .rar, .zip, .pdf'} onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setNewBackgroundField('image', e.target.files[0]) : e.target.value = null} className={'w-100'} required />
            </div>
          </div>
          <div className={'form-row '}>
            <div className={'form-group col-md-12 d-flex justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'}>Add</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>all backgrounds</h5>
        <div className={'pr-4 pl-4'}>
          {
            goods.backgrounds.map((v, i) => <form key={i} className={'m-0'} onSubmit={(e) => e.preventDefault()}>
              <div className={'form-row'}>
                <div className={'form-group col-md-5'}>
                  <label htmlFor={'inputEmail4'}>name</label>
                  <input type={'email'} value={v.name} className={'form-control'} id={'inputEmail4'} readOnly />
                </div>
                <div className={'form-group col-md-5'}>
                  <label htmlFor={'inputRewritePassword'}>image</label>
                  <div className={'w-100'}>
                    <button className={'btn btn-info'} onClick={() => openImageModal(v.image)}>show</button>
                  </div>
                </div>
                <div className={'form-group col-md-2 d-flex justify-content-center align-items-center'}>
                  <i onClick={() => submitDeleteBackground(v._id)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                </div>
              </div>
            </form>)
          }
        </div>
      </div>
      <div className={'w-100 mb-2'}>
        <h5 className={'text-center'}>add Camera Model</h5>
        <form className={'m-0 pr-4 pl-4'} onSubmit={(e) => { e.preventDefault(); submitAddCameraModel() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-12'}>
              <label>name</label>
              <input type={'text'} value={newCameraModel.name} onChange={(e) => setNewCameraModelField('name', e.target.value)} className={'form-control'} required />
            </div>
          </div>
          <div className={'form-row '}>
            <div className={'form-group col-md-12 d-flex justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'}>Add</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>all Camera Models</h5>
        <div className={'pr-4 pl-4'}>
          {
            goods.cameraModels.map((v, i) => <form key={i} className={'m-0'} onSubmit={(e) => e.preventDefault()}>
              <div className={'form-row'}>
                <div className={'form-group col-md-10'}>
                  <label htmlFor={'inputEmail4'}>name</label>
                  <input type={'email'} value={v.name} className={'form-control'} id={'inputEmail4'} readOnly />
                </div>
                <div className={'form-group col-md-2 d-flex justify-content-center align-items-center'}>
                  <i onClick={() => submitDeleteCamerModel(v._id)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                </div>
              </div>
            </form>)
          }
        </div>
      </div>
      <div className={'w-100 mb-2'}>
        <h5 id={'edit'} className={'text-center'}>{newGood._id ? 'edit service' : 'add service'}</h5>
        <form ref={formRef} className={'m-0 pr-4 pl-4'} onSubmit={(e) => { e.preventDefault(); submitProcessGood() }}>
          {!newGood.additional
            ? <div className={'form-row'}>
              <div className={'form-group col-md-4'}>
                <label>Country</label>
                <MultipleSelect required={true} form={formRef} selected={newGood._id || newGood.country ? [{ label: newGood.country.name }] : []} items={goods.countrys.map(v => ({ label: v.name }))} onChangeValue={(v, s) => s.length > 0 ? setNewGoodField('country', s[0].label) : setNewGoodField('country', v)} selectOnlyOne={true} />
              </div>
              <div className={'form-group col-md-4'}>
                <label>States</label>
                <MultipleSelect form={formRef} selected={newGood._id || newGood.states.length > 0 ? newGood.states.map(v => ({ label: v.name })) : []} items={goods.states.map(v => ({ label: v.name }))} onChangeValue={(v, s) => setNewGoodField('states', s)} />
              </div>
              <div className={'form-group col-md-4'}>
                <label>Type doc</label>
                <MultipleSelect required={true} form={formRef} selected={newGood._id || newGood.docType ? [{ label: newGood.docType.name }] : []} items={goods.docsTypes.map(v => ({ label: v.name }))} onChangeValue={(v, s) => s.length > 0 ? setNewGoodField('docType', s[0].label) : setNewGoodField('docType', v)} selectOnlyOne={true} />
              </div>
            </div>
            : null}
          <div className={'form-row'}>
            <div className={'form-group col-md-3'}>
              <label>Name document</label>
              <input value={newGood.name} onChange={(e) => setNewGoodField('name', e.target.value)} type={'text'} className={'form-control'} required />
            </div>
            <div className={'form-group col-md-3'}>
              <label className={'w-100 text-center'}>Sample</label>
              <div className="w-100 d-flex flex-column justify-content-center">
                <label htmlFor="addSample" className={'btn btn-primary'}>
                  add image
                </label>
                <input id={'addSample'} accept={'image/*, .rar, .zip, .pdf'} onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setNewGoodField('sample', e.target.files[0]) : e.target.value = null} type={'file'} className={'invisible-input'} />
              </div>
            </div>
            <div className={'form-group col-md-3'}>
              <label>Additional service?</label>
              <select value={newGood.additional} onChange={e => setNewGoodField('additional', JSON.parse(e.target.value.toLowerCase()))} className={'form-control'}>
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>
            {newGood.additional
              ? <div className={'form-group col-md-3'}>
                <label>For</label>
                <select value={newGood.docType && newGood.docType.name ? newGood.docType.name : newGood.docType == null ? '' : newGood.docType} onChange={e => setNewGoodField('docType', e.target.value)} className={'form-control'} required>
                  <option value=""></option>
                  {
                    goods.docsTypes.map((v, i) => (
                      <option key={i} value={v.name}>{v.name}</option>
                    ))
                  }
                </select>
              </div>
              : null
            }
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-4'}>
              <label>Price for user</label>
              <input value={newGood.priceForUser} onChange={(e) => setNewGoodField('priceForUser', parseInt(e.target.value) | 0)} type={'text'} className={'form-control'} required />
            </div>
            <div className={'form-group col-md-4'}>
              <label>Price for employees</label>
              <input value={newGood.priceForEmployees} onChange={(e) => setNewGoodField('priceForEmployees', parseInt(e.target.value) | 0)} type={'text'} className={'form-control'} required />
            </div>
            <div className={'form-group col-md-4'}>
              <label>Operator</label>
              <select value={Operator == null ? 'null' : Operator} onChange={e => changeOperator(e.target.value)} className="form-control">
                <option value=""></option>
                {
                  settings.settings.emplyees.map((v, i) => <option key={i} value={v.telegramId}>{v.telegramId}</option>)
                }
              </select>
            </div>
          </div>
          <h6>Fileds</h6>
          <div className={'form-row'}>
            <div className={'form-group col-md-12'}>
              <button type={'button'} className={'btn btn-primary'} onClick={addNewFieldForGood}>add field</button>
            </div>
            <div className={'position-relative'}>
              {
                newGood.fields.map((v, i) => (
                  <Draggable
                    handle={".fields-handle"}
                    key={i}
                    bounds={'parent'}
                    axis="y"
                    defaultPosition={{ x: 0, y: 0 }}
                    onStop={(e, d) => onStopDragValueInList(v._id ? v._id : v.id, d)}
                    position={{ x: 0, y: 0 }}
                  >
                    <div className={'form-group col-md-12 row border-bottom pb-2 mt-2'}>
                      <div className="fields-handle" />
                      <div className={'col-md-2'}>
                        <label htmlFor={'inputFieldName'}>Name</label>
                        <input value={v.name} onChange={(e) => setNewFieldForGood(v.id, 'name', e.target.value)} type={'text'} className={'form-control'} id={'inputFieldName'} required />
                      </div>
                      <div className={'col-md-4 d-flex justify-content-center align-items-center'}>
                        <div className={'form-check ml-2 w-auto d-inline-block'}>
                          <input onChange={(e) => setNewFieldForGood(v.id, e.target.value, e.target.checked)} checked={v.fileUploader} className={'form-check-input'} type={'radio'} name={`field-type-${i}`} id={`field-type-${i}`} value={'fileUploader'} required />
                          <label className={'form-check-label'} htmlFor={`field-type-${i}`}>fileUploader</label>
                        </div>
                        <div className={'form-check ml-2 w-auto d-inline-block'}>
                          <input onChange={(e) => setNewFieldForGood(v.id, e.target.value, e.target.checked)} checked={v.datePicker} className={'form-check-input'} type={'radio'} name={`field-type-${i}`} id={`field-type-${i}`} value={'datePicker'} required />
                          <label className={'form-check-label'} htmlFor={`field-type-${i}`}>datePicker</label>
                        </div>
                        <div className={'form-check ml-2 w-auto d-inline-block'}>
                          <input onChange={(e) => setNewFieldForGood(v.id, e.target.value, e.target.checked)} checked={v.select} className={'form-check-input'} type={'radio'} name={`field-type-${i}`} id={`field-type-${i}`} value={'select'} required />
                          <label className={'form-check-label'} htmlFor={`field-type-${i}`}>select</label>
                        </div>
                        <div className={'form-check ml-2 w-auto d-inline-block'}>
                          <input onChange={(e) => setNewFieldForGood(v.id, e.target.value, e.target.checked)} checked={v.textInput} className={'form-check-input'} type={'radio'} name={`field-type-${i}`} id={`field-type-${i}`} value={'textInput'} required />
                          <label className={'form-check-label'} htmlFor={`field-type-${i}`}>textInput</label>
                        </div>
                      </div>
                      <div className={'col-md-1 d-flex justify-content-center align-items-center'}>
                        <div className={'form-check'}>
                          <input onChange={(e) => setNewFieldForGood(v.id, e.target.value, e.target.checked)} checked={v.required} type={'checkbox'} className={'form-check-input'} name={`field-required-${i}`} id={`field-required-${i}`} value={'required'} />
                          <label className={'form-check-label'} htmlFor={`field-required-${i}`}>required?</label>
                        </div>
                      </div>
                      <div className={'col-md-1'}>
                        <label htmlFor={'inputFieldName'}>Max length</label>
                        <input value={v.maxLength} onChange={(e) => setNewFieldForGood(v.id, 'maxLength', parseInt(e.target.value) | 0)} type={'text'} className={'form-control'} id={'inputFieldName'} required />
                      </div>
                      <div className={'col-md-3'}>
                        <label htmlFor={'selectPatternField'}>Pattern</label>
                        <select value={v.pattern} onChange={(e) => setNewFieldForGood(v.id, 'pattern', e.target.value)} className={'form-control'} id={'selectPatternField'} required>
                          <option value={'default'}>default</option>
                          <option value={'numeric'}>numeric</option>
                          <option value={'chars'}>chars</option>
                        </select>
                      </div>
                      <div className={'col-md-1 d-flex justify-content-center align-items-center'}>
                        <i onClick={() => deleteFieldForGood(v.id)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                      </div>
                      {
                        v.select
                          ? <div className={'col-md-12 '}>
                            <div className={'row mr-2 ml-2'}>
                              <h6>values</h6>
                              <div className={'form-group col-md-12'}>
                                <input value={fieldValue} onChange={(e) => changeFieldValue(e.target.value)} type={'text'} className={'form-control w-auto float-left'} id={'inputFieldName'} />
                                <button type={'button'} className={'btn btn-primary'} onClick={() => addNewValueForFieldForGood(v.id)}>add value</button>
                              </div>
                              {
                                v.values.map((vv, ii) => (
                                  <div className={'form-group col-md-12 row'} key={ii}>
                                    <div className={'form-group col-md-10'}>
                                      <input type={'text'} value={vv} className={'form-control'} readOnly />
                                    </div>
                                    <div className={'form-group col-md-2 d-flex justify-content-center align-items-center'}>
                                      <i onClick={() => deleteValueForFieldForGood(v.id, vv)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                          : null
                      }

                    </div>
                  </Draggable>

                ))
              }
            </div>
          </div>
          <div className={'form-row '}>
            <div className={'form-group col-md-12 d-flex justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'} >{newGood._id ? 'edit good' : 'add one'}</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>all services</h5>
        {
          goods.goods.map((v, i) => <form key={i} className={'m-0 border-bottom border-dark pr-3 pl-3'} onSubmit={(e) => { e.preventDefault(); duplicateForEditGood(v) }}>
            <div className={'form-row'}>
              <div className={'form-group col-md-3'}>
                <label className={'font-weight-bold'}>Name</label>
                <input value={v.name} onChange={(e) => setNewGoodField('name', e.target.value)} type={'text'} className={'form-control'} readOnly />
              </div>
              <div className={'form-group col-md-3'}></div>
              <div className={'form-group col-md-2 d-flex align-items-end justify-content-center'}>
                <button type={'submit'} className={'btn btn-primary mr-2'}>edit</button>
              </div>
              <div className={'form-group col-md-2 d-flex align-items-end justify-content-center'}>
                <button type={'button'} onClick={() => confirm('Delet this service') ? submitDeleteGood(v._id) : null} className={'btn btn-secondary ml-2'}>delete</button>
              </div>
              <div className={'form-group col-md-2 d-flex align-items-end justify-content-center'}>
                <button type={'button'} onClick={() => duplicateGood(v)} className={'btn btn-warning ml-2'}>duplicate</button>
              </div>
            </div>
          </form>)
        }
      </div>
    </div >)

  })