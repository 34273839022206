import React, { useEffect, useState } from 'react';
import Pagination from 'rc-pagination';
import { connect } from 'react-redux';

import { depositFor, getLast, clearErrors } from '../../../store/actions/transactions.actions';

export default connect((s) => ({
  user: s.user,
  transactions: s.transactions
}), {
  depositFor,
  getLast,
  clearErrors
})(
  ({
    user,
    transactions,
    depositFor,
    getLast,
    clearErrors
  }) => {
    const [DidMount, changeDidMount] = useState(false)
    const [CurrentPage, changeCurrentPage] = useState(1)
    const [processedTransactions, changeProcessedTransactions] = useState(transactions.transactions);
    const [transactionsSort, changeTransactionsSort] = useState({ date: 'default', amount: 'default', status: 'default' })
    const [newDeposit, changeNewDeposit] = useState({ user: '', type: 'INCOME', value: 0 })

    const setNewDepositFiled = (name, value) => changeNewDeposit({ ...newDeposit, [name]: value })

    const submitDeposit = () => depositFor({ token: user.token, transaction: newDeposit })

    const sortTransactions = (name, value) => changeTransactionsSort({ ...transactionsSort, [name]: value })

    useEffect(() => {

      changeDidMount(true)

      if (transactions.errors.length > 0)
        clearErrors()
      return () => {
        if (transactions.errors.length > 0)
          clearErrors()
      }
    }, [])

    useEffect(() => {
      changeNewDeposit({ user: '', type: 'INCOME', value: 0 })

      let sortedTransaction = JSON.parse(JSON.stringify(transactions.transactions))

      if (transactionsSort.date == 'up')
        sortedTransaction.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (transactionsSort.date == 'down')
        sortedTransaction.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (transactionsSort.amount == 'up')
        sortedTransaction.sort((a, b) => a.value - b.value)
      else if (transactionsSort.amount == 'down')
        sortedTransaction.sort((a, b) => b.value - a.value)

      if (transactionsSort.status == 'income')
        sortedTransaction = sortedTransaction.filter(v => v.type == transactionsSort.status.toUpperCase())
      else if (transactionsSort.status == 'outcome')
        sortedTransaction = sortedTransaction.filter(v => v.type == transactionsSort.status.toUpperCase())

      if (Math.ceil(sortedTransaction.length / 20) < CurrentPage)
        changeCurrentPage(Math.ceil(sortedTransaction.length / 20))

      changeProcessedTransactions(sortedTransaction.reverse())
    }, [transactions.transactions])

    useEffect(() => {
      let sortedTransaction = JSON.parse(JSON.stringify(transactions.transactions))

      if (transactionsSort.date == 'up')
        sortedTransaction.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (transactionsSort.date == 'down')
        sortedTransaction.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (transactionsSort.amount == 'up')
        sortedTransaction.sort((a, b) => a.value - b.value)
      else if (transactionsSort.amount == 'down')
        sortedTransaction.sort((a, b) => b.value - a.value)

      if (transactionsSort.status == 'income')
        sortedTransaction = sortedTransaction.filter(v => v.type == transactionsSort.status.toUpperCase())
      else if (transactionsSort.status == 'outcome')
        sortedTransaction = sortedTransaction.filter(v => v.type == transactionsSort.status.toUpperCase())

      if (Math.ceil(sortedTransaction.length / 20) < CurrentPage)
        changeCurrentPage(Math.ceil(sortedTransaction.length / 20))

      changeProcessedTransactions(sortedTransaction.reverse())
    }, [transactionsSort])

    useEffect(() => {

      if (DidMount)
        if (CurrentPage == Math.ceil(processedTransactions.length / 20))
          getLast({ token: user.token, from: transactions.transactions.length })

    }, [CurrentPage])

    return (<div className={'w-100 h-100'}>
      <h2>Payments</h2>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>Deposit for</h5>
        {
          transactions.isError && transactions.errors.length > 0
            ? <div className={'w-100 text-center text-danger'}>
              {
                transactions.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
              }
            </div>
            : null
        }
        <form className={'m-0 pr-2 pl-2'} onSubmit={(e) => { e.preventDefault(); submitDeposit() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'textareaQuestion'}>User/emplyee email</label>
              <input value={newDeposit.user} onChange={(e) => setNewDepositFiled('user', e.target.value)} type={'email'} className={'form-control'} required />
            </div>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'textareaQuestion'}>User/emplyee email</label>
              <select value={newDeposit.type} onChange={(e) => setNewDepositFiled('type', e.target.value)} className={'form-control'} required>
                <option value={'INCOME'}>INCOME</option>
                <option value={'OUTCOME'}>OUTCOME</option>
              </select>
            </div>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'textareaAnswer'}>Deposit Amount</label>
              <input value={newDeposit.value} onChange={(e) => setNewDepositFiled('value', parseInt(e.target.value) | 0)} type={'text'} className={'form-control'} required />
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-12 d-flex justify-content-center align-items-center'}>
              <button className={'btn btn-success'} type={'submit'}>deposit</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100'}>
        <h5 className={'text-center'}>All transactions</h5>
        <table className={'table table-striped'}>
          <thead>
            <tr>
              <th scope={'col'} >ID</th>
              <th onClick={() => sortTransactions('date', transactionsSort.date == 'default' ? 'up' : transactionsSort.date == 'up' ? 'down' : 'default')} scope={'col'} className={transactionsSort.date == 'default' ? 'th-sm' : transactionsSort.date == 'down' ? 'th-sm down' : transactionsSort.date == 'up' ? 'th-sm up' : 'th-sm'}>Date</th>
              <th onClick={() => sortTransactions('status', transactionsSort.status == 'default' ? 'income' : transactionsSort.status == 'income' ? 'outcome' : 'default')} scope={'col'} className={transactionsSort.status == 'default' ? 'th-sm' : transactionsSort.status == 'outcome' ? 'th-sm down' : transactionsSort.status == 'income' ? 'th-sm up' : 'th-sm'}>Status</th>
              <th onClick={() => sortTransactions('amount', transactionsSort.amount == 'default' ? 'up' : transactionsSort.amount == 'up' ? 'down' : 'default')} scope={'col'} className={transactionsSort.amount == 'default' ? 'th-sm' : transactionsSort.amount == 'down' ? 'th-sm down' : transactionsSort.amount == 'up' ? 'th-sm up' : 'th-sm'}>Amount</th>
              <th scope={'col'}>User</th>
            </tr>
          </thead>
          <tbody>
            {
              processedTransactions.map((v, i) => i < CurrentPage * 20 && i >= (CurrentPage - 1) * 20
                ? (<tr key={i}>
                  <th scope={'row'}>{i + 1}</th>
                  <td>{v.createdAt}</td>
                  <td>{v.type}</td>
                  <td>{v.value}</td>
                  <td>{v.user.login}-{v.user.email}</td>
                </tr>
                )
                : null
              )
            }
          </tbody>
        </table>
        <div className={'p-2'}>
          <Pagination current={CurrentPage} className={'ant-pagination'} onChange={(current) => changeCurrentPage(current)} defaultCurrent={1} total={Math.ceil(processedTransactions.length / 20) * 10} />
        </div>
      </div>
    </div>)

  })