import React from 'react';

import RegistrationPage from '../components/Default/Registration';
import Title from './../components/Default/TitleLayout'

export default ({

}) => {

  return <div>
    <Title>
      <title>DOCS PANEL - REGISTRATION</title>
    </Title>
    <RegistrationPage />
  </div>;
};