import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { requestRecovery, recovery, clearErrors } from '../../../store/actions/users.actions';

export default connect((s) => ({
  user: s.user
}), {
  requestRecovery,
  recovery,
  clearErrors
})(
  ({
    recoveryId,
    user,
    requestRecovery,
    recovery,
    clearErrors
  }) => {

    const [didMount, setDidMount] = useState(false)
    const [isRecovery, setIsRecovery] = useState(false)
    const [toRedirect, toRedirectChange] = useState(false)
    const [RestoreData, RestoreDataChange] = useState('')

    const submitRecovery = () => {
      setIsRecovery(true)

      if (recoveryId)
        recovery({ recovery: recoveryId, newPassword: RestoreData })
      else
        requestRecovery(RestoreData)

    }

    useEffect(() => {

      setDidMount(true)
      clearErrors()

      return () => {
        setDidMount(false);
        clearErrors()
      };
    }, [])

    useEffect(() => {

      if (didMount && user.inRecovery && !user.isError)
        alert('Recovery success. Recovery you account, link sended on email');

    }, [user.inRecovery])

    useEffect(() => {

      if (didMount && user.recovered && !user.isError) {
        toRedirectChange(true)
        clearErrors()
      }

    }, [user.recovered])

    useEffect(() => {

      if (user.errors.length > 0)
        setIsRecovery(false)

    }, [user.errors])

    if (toRedirect)
      return <Redirect to={'/login'} />

    return (<div className={'registration-page'}>
      <form className={'registration-form'} onSubmit={(e) => { e.preventDefault(); submitRecovery() }}>
        <h2 className={'text-center'}>Recovery</h2>
        {
          user.isError && user.errors.length > 0
            ? <div className={'w-100 text-center text-danger'}>
              {
                user.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
              }
            </div>
            : null
        }
        {
          !recoveryId
            ? <div>
              <div className={'form-row'}>
                <div className={'form-group col-md-12'}>
                  <label htmlFor={'inpuLogin'}>Login or Email</label>
                  <input type={'text'} value={RestoreData} onChange={(e) => RestoreDataChange(e.target.value)} className={'form-control'} id={'inputLogin'} required />
                </div>
              </div>
            </div>
            : <div>
              <div className={'form-row'}>
                <div className={'form-group col-md-12'}>
                  <label htmlFor={'inpuLogin'}>New password</label>
                  <input type={'password'} value={RestoreData} onChange={(e) => RestoreDataChange(e.target.value)} className={'form-control'} id={'inputLogin'} required />
                </div>
              </div>
            </div>
        }
        <div className={'form-row justify-content-center'}>
          <button disabled={isRecovery} type={'submit'} className="btn btn-primary">Recovery</button>
        </div>
        {
          !recoveryId
            ? <div className="w-100 mt-2 text-center">
              <span className={'w-100 d-inline-block text-center mt-3'}>If u already have an account, <Link to={'/login'}>sign in</Link></span>
            </div>
            : null
        }

      </form>
    </div>)

  })