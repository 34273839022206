import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';

import InfoModal from './../../Default/infoModal';

import { clearSettingsErrors, setNewSettings } from '../../../store/actions/settings.actions';
import { clearErrors, changePassword } from '../../../store/actions/users.actions';

export default connect(
	(s) => ({
		user: s.user,
		settings: s.settings,
	}),
	{
		clearSettingsErrors,
		setNewSettings,
		clearErrors,
		changePassword,
	}
)(({ user, settings, clearSettingsErrors, setNewSettings, clearErrors, changePassword }) => {
	const [didMount, changeDidMount] = useState(false);
	const [passwordUpdated, changePasswordUpdated] = useState(true);
	const [newSettings, changeNewSettings] = useState({
		blockNewRegistrations: settings.settings.blockNewRegistrations,
		paymentMethods: settings.settings.paymentMethods,
		closeUsersPage: settings.settings.closeUsersPage,
		loginNotification: settings.settings.loginNotification,
	});
	const [newPasswordState, changeNewPasswordState] = useState({ newPassword: '' });
	const [InfoModalShow, changeInfoModalShow] = useState(false);

	const switchSettings = (name, value) => changeNewSettings({ ...newSettings, [name]: value });
	const setNewPasswordStateFiled = (name, value) => changeNewPasswordState({ ...newPasswordState, [name]: value });

	const sumbitEditSettings = () => setNewSettings({ token: user.token, newSettings: { ...settings, ...newSettings } });
	const submitPasswordChange = () => {
		changePassword({ ...newPasswordState, token: user.token });
		setTimeout(() => {
			if (passwordUpdated) {
				changeNewPasswordState({ newPassword: '' });
				alert('Password changed');
			}
		}, 2 * 1000);
	};

	useEffect(() => {
		if (didMount) {
			changeNewSettings({
				blockNewRegistrations: settings.settings.blockNewRegistrations,
				closeUsersPage: settings.settings.closeUsersPage,
			});
			alert('Settings saved');
		}
	}, [settings.settings.blockNewRegistrations, settings.settings.closeUsersPage]);

	useEffect(() => {
		if (didMount) changePasswordUpdated(!user.isError);
	}, [user]);

	useEffect(() => {
		changeDidMount(true);
		if (settings.errors.length > 0) {
			clearSettingsErrors();
		}
		if (user.errors.length > 0) {
			clearErrors();
		}
		return () => {
			if (settings.errors.length > 0) {
				clearSettingsErrors();
			}
			if (user.errors.length > 0) {
				clearErrors();
			}
		};
	}, []);

	return (
		<div className={'w-100 h-100'}>
			<h2>SETTINGS</h2>
			<div className={'w-100 border-bottom mb-2'}>
				<h5 className={'text-center'}>site settings</h5>
				{settings.isError && settings.errors.length > 0 ? (
					<div className={'w-100 text-center text-danger'}>
						{settings.errors.map((v, i) => (
							<span
								key={i}
								className={'w-100 d-inline-block'}
							>
								{v}
							</span>
						))}
					</div>
				) : null}
				<form
					className={'m-0 pr-4 pl-4'}
					onSubmit={(e) => {
						e.preventDefault();
						sumbitEditSettings();
					}}
				>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<div className={'d-flex align-items-center'}>
								<label htmlFor={'textareaQuestion'}>Close user page</label>
								<div className={'ml-3'}>
									<Switch
										onChange={(checked) => switchSettings('closeUsersPage', checked)}
										checked={newSettings.closeUsersPage}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<div className={'d-flex align-items-center'}>
								<label htmlFor={'textareaQuestion'}>Block new registration</label>
								<div className={'ml-3'}>
									<Switch
										onChange={(checked) => switchSettings('blockNewRegistrations', checked)}
										checked={newSettings.blockNewRegistrations}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<div className={'d-flex align-items-center'}>
								<label htmlFor={'textareaQuestion'}>Coinbase payment</label>
								<div className={'ml-3'}>
									<Switch
										onChange={(checked) =>
											changeNewSettings({ ...newSettings, paymentMethods: { ...newSettings.paymentMethods, coinbase: checked } })
										}
										checked={newSettings.paymentMethods.coinbase}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<div className={'d-flex align-items-center'}>
								<label htmlFor={'textareaQuestion'}>BTCpay payment</label>
								<div className={'ml-3'}>
									<Switch
										onChange={(checked) =>
											changeNewSettings({ ...newSettings, paymentMethods: { ...newSettings.paymentMethods, btcpay: checked } })
										}
										checked={newSettings.paymentMethods.btcpay}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<label>
								Login notification({' '}
								<a
									href={'#'}
									className={'cursor-pointer'}
									onClick={() => changeInfoModalShow(true)}
								>
									check
								</a>{' '}
								)
							</label>
							<textarea
								onChange={(e) => switchSettings('loginNotification', e.target.value)}
								value={newSettings.loginNotification}
								className={'login-notification-textarea'}
							></textarea>
						</div>
					</div>
					<div className={'form-row'}>
						<div className={'form-group col-md-12 d-flex justify-content-center align-items-center'}>
							<button
								className={'btn btn-success'}
								type={'submit'}
							>
								save
							</button>
						</div>
					</div>
				</form>
			</div>
			<div className={'w-100'}>
				<h5 className={'text-center'}>Change admin password</h5>
				{user.isError && user.errors.length > 0 ? (
					<div className={'w-100 text-center text-danger'}>
						{user.errors.map((v, i) => (
							<span
								key={i}
								className={'w-100 d-inline-block'}
							>
								{v}
							</span>
						))}
					</div>
				) : null}
				<form
					className={'pr-3 pl-3 pt-2 m-0'}
					onSubmit={(e) => {
						e.preventDefault();
						submitPasswordChange();
					}}
				>
					<div className={'form-row'}>
						<div className={'form-group col-md-12'}>
							<label htmlFor={'inputPassword'}>New password</label>
							<input
								value={newPasswordState.newPassword}
								onChange={(e) => setNewPasswordStateFiled('newPassword', e.target.value)}
								type={'password'}
								autoComplete={'on'}
								className={'form-control'}
								id={'inputPassword'}
								required
							/>
						</div>
					</div>
					<div className={'form-row d-flex justify-content-center align-items-center'}>
						<div className={'form-group col-md-12 d-flex align-items-end justify-content-center'}>
							<button
								className={'btn btn-success'}
								type={'submit'}
							>
								change password
							</button>
						</div>
					</div>
				</form>
			</div>
			<InfoModal
				show={InfoModalShow}
				content={newSettings.loginNotification}
				close={() => changeInfoModalShow(false)}
			/>
		</div>
	);
});
