import React from 'react';
import { connect } from 'react-redux';

export default connect((s) => ({
  orders: s.orders.orders,
  users: s.orders.users,
  allUsersBalance: s.transactions.allUsersBalance
}), {

})(
  ({
    orders,
    users,
    allUsersBalance
  }) => {

    return (<div className={'w-100 h-100 d-flex flex-column'}>
      <h2>HOME</h2>
      <div className={'flex-grow-1 d-flex align-items-center h-100'}>
        <div className={'row pr-3 pl-3'}>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>Orderds in '<span style={{ color: 'rgb(255, 0, 0,.8)' }}>waiting for payment</span>'</span>
            <input type={'text'} readOnly className={'form-control'} value={orders.filter(o => o.status == 'waiting for payment').length} />
          </div>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>Orderds in <span style={{ color: 'rgb(0, 171, 68,.8)' }}>'accepted'</span></span>
            <input type={'text'} readOnly className={'form-control'} value={orders.filter(o => o.status == 'accepted').length} />
          </div>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>Orderds in <span style={{ color: 'rgb(74, 134, 232,.8)' }}>'in progress'</span></span>
            <input type={'text'} readOnly className={'form-control'} value={orders.filter(o => o.status == 'in progress').length} />
          </div>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>Orderds in <span style={{ color: 'rgb(153, 0, 255,.8)' }}>'done'</span></span>
            <input type={'text'} readOnly className={'form-control'} value={orders.filter(o => o.status == 'done').length} />
          </div>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>All users</span>
            <input type={'text'} readOnly className={'form-control'} value={users} />
          </div>
          <div className={'col-md-4'}>
            <span className={'w-100 d-inline-block'}>All users balance</span>
            <input type={'text'} readOnly className={'form-control'} value={allUsersBalance} />
          </div>
        </div>
      </div>
    </div>)

  })