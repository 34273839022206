import React from 'react';

import UserFAQPage from '../components/Users/FAQ';
import EmplayeeFAQPage from '../components/Emplyees/FAQ';
import AdminFAQPage from '../components/Admin/FAQ';
import PageNotFound from './NotFound.view'
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - FAQ</title>
      </Title>
      <UserFAQPage />
    </div>
    : role == 'employee'
      ? <div>
        <Title>
          <title>DOCS PANEL - FAQ</title>
        </Title>
        <EmplayeeFAQPage />
      </div>
      : role == 'admin'
        ? <div>
          <Title>
            <title>DOCS PANEL - FAQ</title>
          </Title>
          <AdminFAQPage />
        </div>
        : <PageNotFound />
};