import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import userReducer from './users.reducer';
import faqsReducer from './faqs.reducer';
import transactionsReducer from './transactions.reducer';
import settingsReducer from './settings.reducer';
import goodsReducer from './goods.reducer';
import ordersReducer from './orders.reducer';
import ticketsReducer from './tickets.reducer';

export default (history) => combineReducers({
  user: userReducer,
  faqs: faqsReducer,
  transactions: transactionsReducer,
  settings: settingsReducer,
  goods: goodsReducer,
  orders: ordersReducer,
  tickets: ticketsReducer,
  router: connectRouter(history),
});
