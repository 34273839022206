import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { loginIn, clearErrors } from '../../../store/actions/users.actions';

export default connect((s) => ({
  user: s.user,
  settings: s.settings.settings,
}), {
  loginIn,
  clearErrors
})(
  ({
    user,
    settings,
    loginIn,
    clearErrors
  }) => {
    const [currentUser, currentUserChange] = useState({ login: '', password: '' })

    const setUserFiled = (name, value) => currentUserChange({ ...currentUser, [name]: value });

    const submitLoginIn = () => loginIn({ user: currentUser })

    useEffect(() => {
      clearErrors()
    }, [])

    return (<div className={'login-page'}>
      {
        settings.closeUsersPage && location.hostname == 'new.docs-dev.com'
          ? <div className="d-flex justify-content-center aling-items-center">
            <h2>Service is currently down for maintenance</h2>
          </div>
          : <form className={'login-form'} onSubmit={(e) => { e.preventDefault(); submitLoginIn() }}>
            <h2 className={'text-center'}>Login</h2>
            {
              user.isError && user.errors.length > 0
                ? <div className={'w-100 text-center text-danger'}>
                  {
                    user.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
                  }
                </div>
                : null
            }
            <div className={'form-group'}>
              <label htmlFor={'inputLogin'}>Login or email</label>
              <input type={'text'} value={currentUser.login} onChange={(e) => setUserFiled('login', e.target.value)} className={'form-control'} id={'inputLogin'} required />
            </div>
            <div className={'form-group'}>
              <label htmlFor={'inputPassword'}>Password</label>
              <input type={'password'} value={currentUser.password} onChange={(e) => setUserFiled('password', e.target.value)} className={'form-control'} id={'inputNPassword'} required />
            </div>
            <div className={'form-row justify-content-center'}>
              <button type={'submit'} className={'btn btn-primary'}>Sign in</button>
              {
                location.hostname == 'new.docs-dev.com'
                  ? <span className={'w-100 d-inline-block text-center mt-3'}>If you don't have an account yet, <Link to={'/reg'}>sign up</Link></span>
                  : null
              }
            </div>
            <div className="w-100 mt-2 text-center">
              <span>
                If you forgot your password, then <Link to={'/recovery'}>restore</Link> it
              </span>
            </div>
          </form>
      }

    </div>)

  })