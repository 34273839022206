import React, { useState, useEffect } from 'react';

export default ({
  required = false,
  onlyFromItems = false,
  form = null,
  items = [],
  selected = [],
  onChangeValue,
  selectOnlyOne = false
}) => {

  const [SelectedChanged, changeSelectedChanged] = useState(false)
  const [InputValue, changeInputValue] = useState('')
  const [FindedItems, changeFindedItems] = useState([])
  const [SelectedItems, changeSelectedItems] = useState([])

  const handleToggleItemsState = (add, id) => {

    if (add && FindedItems.find(i => i.id == id))
      if (!selectOnlyOne)
        changeSelectedItems([...SelectedItems, FindedItems.find(i => i.id == id)])
      else
        changeSelectedItems([FindedItems.find(i => i.id == id)])
    else if (!add)
      if (!selectOnlyOne)
        changeSelectedItems(SelectedItems.filter(i => i.id != id))
      else
        changeSelectedItems([])
  }

  const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
    let result = '';

    for (let i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * characters.length));

    return result;
  }

  useEffect(() => {
    
    form?.current?.addEventListener('submit', (e) => {
      e.preventDefault();
      changeSelectedItems([])
      changeSelectedChanged(false)
      changeInputValue('')
    });

    return () => {
      form?.current?.removeEventListener('submit', (e) => {
        e.preventDefault();
        changeSelectedItems([])
        changeSelectedChanged(false)
        changeInputValue('')
      });
    }

  }, [form])

  useEffect(() => {

    if (selected.length > 0 && !SelectedChanged) {
      changeSelectedChanged(true)
      changeSelectedItems(selected.map(v => ({ label: v.label, id: generateId() })))
    }
  }, [selected])

  useEffect(() => {

    if (!InputValue) {
      changeFindedItems([])
      if (onChangeValue)
        onChangeValue(InputValue, SelectedItems)
    } else {
      changeFindedItems(items.filter(v => v.label.toLowerCase().indexOf(InputValue.toLowerCase()) != -1).map(v => ({ ...v, id: generateId() })))
      if (onChangeValue)
        onChangeValue(InputValue, SelectedItems)
    }

  }, [InputValue])

  useEffect(() => {

    if (onChangeValue)
      onChangeValue(InputValue, SelectedItems)
  }, [SelectedItems])

  return (
    <div className={'multiple-select'}>
      <div className={'select-input-wrapper'}>
        <div className={'select-input-content'}>
          {
            SelectedItems.map((v, i) => (
              <div className={'selected'} key={i}>
                <span>{v.label}</span>
                <div className={'x'} onClick={() => handleToggleItemsState(false, v.id)}>
                  <span>✖</span>
                </div>
              </div>
            ))
          }
          <input type={'text'}
            required={SelectedItems.length > 0 ? false : required}
            onKeyDown={(e) => {
              if (e.keyCode == 13 && !onlyFromItems) {
                e.preventDefault()
                if (!selectOnlyOne)
                  changeSelectedItems([...SelectedItems, { label: e.target.value, id: generateId() }])
                else
                  changeSelectedItems([{ label: e.target.value, id: generateId() }])
              }
            }} className={'select-input'} value={InputValue} onChange={e => changeInputValue(e.target.value)} />
        </div>
      </div>
      <div className={FindedItems.length > 0 ? 'select-content-wrapper' : 'select-content-wrapper none'}>
        <ul className={'select-content'}>
          {
            FindedItems.map((v, i) => (
              <li className={'select-content-item'} key={i}>
                <label>
                  <input type={'checkbox'} checked={SelectedItems.find(si => si.id == v.id) ? true : false} onChange={e => SelectedItems.find(si => si.id == v.id) ? handleToggleItemsState(false, v.id) : handleToggleItemsState(true, v.id)} />
                  <span>{v.label}</span>
                </label>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )

}