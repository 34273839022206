import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';

import { addTicket, closeTicket, clearTicketsErrors } from '../../../store/actions/tickets.actions';
import { connect } from 'react-redux';
import { baseUrl } from '../../../common/config';

import CurrentTicketComponenet from './current'

export default connect((s) => ({
  user: s.user,
  tickets: s.tickets,
}), {
  addTicket,
  closeTicket,
  clearTicketsErrors
})(
  ({
    user,
    tickets,
    addTicket,
    closeTicket,
    clearTicketsErrors
  }) => {
    const [CurrentPage, changeCurrentPage] = useState(1)
    const [CurrentTicket, changeCurrentTicket] = useState(null)

    const closeCurrentTicket = (_id) => closeTicket({ token: user.token, ticketId: _id })

    useEffect(() => {

      clearTicketsErrors()

    }, [])

    useEffect(() => {

      if (CurrentTicket && tickets.tickets.find(t => t._id == CurrentTicket._id))
        changeCurrentTicket(tickets.tickets.find(t => t._id == CurrentTicket._id))
      else if (CurrentTicket)
        changeCurrentTicket(tickets.tickets[tickets.tickets.length - 1])


    }, [tickets.tickets])

    return (CurrentTicket
      ? <CurrentTicketComponenet
        user={user}
        tickets={tickets}
        CurrentTicket={CurrentTicket}
        changeCurrentTicket={changeCurrentTicket}
        addTicket={addTicket}
      />
      : <div className={'w-100 h-100'}>
        <h2>Tickets</h2>
        <div className="w-100 p-2">
          <button
            className={'btn btn-primary m-1'}
            onClick={() => location.href = `${baseUrl}/api/tickets/emails?token=${user.token}`}
          >Download the mail of all users who wrote the ticket</button>
        </div>
        <table className={'table table-striped'}>
          <thead>
            <tr>
              <th scope={'col'}>ID</th>
              <th scope={'col'}>Subject</th>
              <th scope={'col'}>Date</th>
              <th scope={'col'}>Updated</th>
              <th scope={'col'}>From</th>
              <th scope={'col'}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              tickets.tickets.map((v, i) => i < CurrentPage * 20 && i >= (CurrentPage - 1) * 20
                ? (
                  <tr key={i}>
                    <th><strong>{v._id}</strong></th>
                    <td>{v.subject}</td>
                    <td>{v.createdAt}</td>
                    <td>{v.messages[v.messages.length - 1].date}</td>
                    <td>{v.from.email}</td>
                    <td>
                      <button
                        className={'btn btn-primary m-1'}
                        onClick={() => changeCurrentTicket(v)}
                      >open</button>
                      <button
                        className={'btn btn-primary m-1'}
                        onClick={() => closeCurrentTicket(v._id)}
                      >close</button>
                    </td>
                  </tr>
                )
                : null)
            }
          </tbody>
        </table>
        {
          tickets.tickets.length == 0
            ? <div className="w-100 d-flex align-items-center justify-content-center">
              <h3>Tickets not found</h3>
            </div>
            : null
        }
        <div className={'p-2'}>
          <Pagination className={'ant-pagination'} onChange={(current) => changeCurrentPage(current)} defaultCurrent={1} total={Math.ceil(tickets.tickets.length / 20) * 10} />
        </div>
      </div >
    )

  })