import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { createDeposit, clearDepositLink, clearErrors } from '../../../store/actions/users.actions';

export default connect(
	(s) => ({
		settings: s.settings.settings,
		user: s.user,
	}),
	{
		createDeposit,
		clearDepositLink,
		clearErrors,
	}
)(({ user, settings, createDeposit, clearDepositLink, clearErrors }) => {
	const [paymentMethod, paymentMethodChange] = useState(settings.paymentMethods.coinbase ? 'coinbase' : 'btcpay');
	const [depositAmount, depositAmountChange] = useState(0);

	const sumbitDeposit = () =>
		createDeposit({ token: user.token, depositInfo: { amount: depositAmount, paymentMethod: paymentMethod } });

	useEffect(() => {
		if (user.errors.length > 0) clearErrors();

		return () => {
			if (user.errors.length > 0) clearErrors();
		};
	}, []);

	useEffect(() => {
		if (user.depositLink) {
			location.href = user.depositLink;
			clearDepositLink();
		}
	}, [user.depositLink]);

	return (
		<div className={'h-100 w-100 d-flex justify-content-center align-items-center'}>
			<div className={'p-3 rounded border-dark border'}>
				<form
					className={'m-0'}
					onSubmit={(e) => {
						e.preventDefault();
						sumbitDeposit();
					}}
				>
					<h2 className={'w-100'}>Top Up</h2>
					{user.isError && user.errors.length > 0 ? (
						<div className={'w-100 text-center text-danger'}>
							{user.errors.map((v, i) => (
								<span
									key={i}
									className={'w-100 d-inline-block'}
								>
									{v}
								</span>
							))}
						</div>
					) : null}
					<div className={'input-group'}>
						<input
							type={'text'}
							value={depositAmount}
							onChange={(e) => depositAmountChange(parseInt(e.target.value) | 0)}
							className={'form-control'}
						/>
						<div className={'input-group-append'}>
							<span className={'input-group-text'}>$</span>
						</div>
					</div>
					<div className='d-flex flex-column pt-2'>
						{settings.paymentMethods.coinbase && (
							<label htmlFor='coinbase'>
								<input
									type='radio'
									value='coinbase'
									id='coinbase'
									name='paymentMethod'
									defaultChecked={paymentMethod === 'coinbase'}
									onChange={(e) => paymentMethodChange(e.target.value)}
									disabled={!settings.paymentMethods.coinbase}
								/>
								<strong>LTC, BCH, USDT USDC, ETH, DOGE, MATIC</strong>
							</label>
						)}
						{settings.paymentMethods.btcpay && (
							<label htmlFor='btcpay'>
								<input
									type='radio'
									value='btcpay'
									id='btcpay'
									name='paymentMethod'
									defaultChecked={paymentMethod === 'btcpay'}
									onChange={(e) => paymentMethodChange(e.target.value)}
								/>
								<strong>XMR, BTC, LBTC</strong>
							</label>
						)}
					</div>
					<div className={'pt-2 pb-2 w-100 d-flex justify-content-center align-items-center'}>
						<button
							disabled={!settings.paymentMethods.coinbase && !settings.paymentMethods.btcpay}
							className={'btn btn-success'}
							type={'submit'}
						>
							Deposit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
});
