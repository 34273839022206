import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('USERS');

export const registerAccount = factory.create('REGISTER_ACCOUNT');
export const registerAccountAsync = factory.createAsync('REGISTER_ACCOUNT_ASYNC');

export const loginIn = factory.create('LOGIN_IN');
export const loginInAsync = factory.createAsync('LOGIN_IN_ASYNC');

export const recovery = factory.create('RECOVERY');
export const recoveryAsync = factory.createAsync('RECOVERY_ASYNC');

export const getUserUpdates = factory.create('GET_USER_UPDATES');
export const getUserUpdatesAsync = factory.createAsync('GET_USER_UPDATES_ASYNC');

export const requestConfirmCode = factory.create('REQUEST_CONFIRM_CODE');
export const requestConfirmCodeAsync = factory.createAsync('REQUEST_CONFIRM_CODE_ASYNC');

export const requestRecovery = factory.create('REQUEST_RECOVERY');
export const requestRecoveryAsync = factory.createAsync('REQUEST_RECOVERY_ASYNC');

export const changePassword = factory.create('CHANGE_PASSWORD');
export const changePasswordAsync = factory.createAsync('CHANGE_PASSWORD_ASYNC');

export const changeEmail = factory.create('CHANGE_EMAIL');
export const changeEmailAsync = factory.createAsync('CHANGE_EMAIL_ASYNC');

export const changeTelegramId = factory.create('CHANGE_TELEGRAM');
export const changeTelegramIdAsync = factory.createAsync('CHANGE_TELEGRAM_ASYNC');

export const changeType = factory.create('CHANGE_TYPE');
export const changeTypeAsync = factory.createAsync('CHANGE_TYPE_ASYNC');

export const setNotification = factory.create('SET_NOTIFICATION');
export const setNotificationAsync = factory.createAsync('SET_NOTIFICATION_ASYNC');

export const createDeposit = factory.create('CREATE_DEPOSIT');
export const createDepositAsync = factory.createAsync('CREATE_DEPOSIT_ASYNC');

export const logout = factory.create('LOGOUT');
export const logoutAsync = factory.createAsync('LOGOUT_ASYNC');

export const clearErrors = factory.create('CLEAR_ERRORS');
export const clearErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');

export const clearLoggedNow = factory.create('CLEAR_LOGGED_NOW');
export const clearLoggedNowAsync = factory.createAsync('CLEAR_LOGGED_NOW_ASYNC');

export const clearDepositLink = factory.create('CLEAR_DEPOSIT_LINK');
export const clearDepositLinkAsync = factory.createAsync('CLEAR_DEPOSIT_LINK_ASYNC');