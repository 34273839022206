import React from 'react';

import { Link } from 'react-router-dom';

import Title from './../components/Default/TitleLayout'

import _404 from '../img/page_not_found.png'

export default ({ }) => {

  return <div className={'_404-page'}>
    <Title>
      <title>DOCS PANEL - PAGE NOT FOUND</title>
    </Title>
    <div className={'d-inline-block text-center w-100'}>
      <img src={_404} alt='NOT FOUND :"(' className={'w-100'} />
      <h1 className={'text-body'}>Page not found</h1>
      <Link to='/' className={'text-body'}>На главную?</Link>
    </div>
  </div>
};