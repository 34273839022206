import React from 'react';

import UsersPage from '../components/Admin/Users';
import Title from './../components/Default/TitleLayout'

export default ({

}) => {

  return <div>
    <Title>
      <title>DOCS PANEL - USERS</title>
    </Title>
    <UsersPage />
  </div>
};