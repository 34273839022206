import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../common/store/helpers';
import {
  setNewSettings, setNewSettingsAsync,
  getCurrentSettings, getCurrentSettingsAsync,
  clearSettingsErrors, clearSettingsErrorsAsync
} from '../actions/settings.actions';

import settingsApi from '../../common/api/settings.api';

function plugeWorker() {
  return true;
}

export function* settingsSaga() {
  yield takeEvery(setNewSettings, bindAsyncActions(setNewSettingsAsync)(settingsApi.setNewSettings));
  yield takeEvery(getCurrentSettings, bindAsyncActions(getCurrentSettingsAsync)(settingsApi.getCurrentSettings));
  yield takeEvery(clearSettingsErrors, bindAsyncActions(clearSettingsErrorsAsync)(plugeWorker));
}
