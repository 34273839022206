import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createNewFaq, editFaq, deleteFaq } from '../../../store/actions/faqs.actions';

export default connect((s) => ({
  user: s.user,
  faqs: s.faqs.faqs
}), {
  createNewFaq,
  editFaq,
  deleteFaq
})(
  ({
    user,
    faqs,
    createNewFaq,
    editFaq,
    deleteFaq
  }) => {

    const [currentFaq, currentFaqCahnge] = useState({ question: '', answer: '', for: 'user' })

    const setCurrentFaqFiled = (name, value) => currentFaqCahnge({ ...currentFaq, [name]: value })

    const setFaqForEdit = (faq) => currentFaqCahnge(faq)

    const sumbitProcessFaq = () => {
      if (currentFaq._id)
        editFaq({ token: user.token, faq: currentFaq })
      else
        createNewFaq({ token: user.token, faq: currentFaq })

      currentFaqCahnge({ question: '', answer: '', for: 'user' })
    }

    const submitDeleteFaq = (faqId) => {
      if (confirm('Delete current FAQ?'))
        deleteFaq({ token: user.token, faqId: faqId })
    }

    return (<div className={'w-100 h-100'}>
      <h2>FAQ</h2>
      <div className={'w-100 border-bottom mb-2'}>
        <h5 className={'text-center'}>Create new FAQ</h5>
        <form className={'m-0 pr-2 pl-2'} onSubmit={(e) => { e.preventDefault(); sumbitProcessFaq() }}>
          <div className={'form-row'}>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'textareaQuestion'}>Question</label>
              <textarea value={currentFaq.question} onChange={(e) => setCurrentFaqFiled('question', e.target.value)} id={'textareaQuestion'} className={'w-100'} style={{ height: '100px', resize: 'none' }} required></textarea>
            </div>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'textareaAnswer'}>Answer</label>
              <textarea value={currentFaq.answer} onChange={(e) => setCurrentFaqFiled('answer', e.target.value)} id={'textareaAnswer'} className={'w-100'} style={{ height: '100px', resize: 'none' }} required></textarea>
            </div>
            <div className={'form-group col-md-4'}>
              <label htmlFor={'selectFor'}>Select for</label>
              <select value={currentFaq.for} onChange={(e) => setCurrentFaqFiled('for', e.target.value)} className={'form-control'} id={'selectFor'} required >
                <option value={'user'}>Users</option>
                <option value={'employee'}>Emplyees</option>
              </select>
            </div>
          </div>
          <div className={'form-row'}>
            <div className={'form-group col-md-12 d-flex justify-content-center align-items-center'}>
              <button className={'btn btn-success'} type={'submit'}>{currentFaq._id ? 'edit current' : 'Create one'}</button>
            </div>
          </div>
        </form>
      </div>
      <div className={'w-100'}>
        <div className={'tab-content d-flex align-items-start h-100 justify-content-center'} id={'faq-tab-content'}>
          <div className={'tab-pane show active w-100'} id={'tab1'} role={'tabpanel'} aria-labelledby={'tab1'}>
            <h5 className={'text-center'}>All FAQ`s</h5>
            <div className={'accordion'} id={'accordion-tab-1'}>
              {
                faqs.map((v, i) => <div className={'card'} key={i}>
                  <div className={'card-header'} id={'accordion-tab-1-heading-1'}>
                    <h5 className={'d-flex'}>
                      <button className={'btn btn-link w-auto float-left flex-grow-1'} type={'button'} data-toggle={'collapse'} data-target={`#accordion-tab-1-content-${i}`} aria-expanded={'false'} aria-controls={`accordion-tab-1-content-${i}`}>{v.question}</button>
                      <div className={'float-right pr-2'}>
                        <span className={'d-inline-block pl-2 pr-2 text-warning'}>{v.for}</span>
                        <i onClick={() => setFaqForEdit(v)} className={'cursor-pointer pr-2 pl-2 fa fa-edit'}></i>
                        <i onClick={() => submitDeleteFaq(v._id)} className={'cursor-pointer pr-2 pl-2 fa fa-minus-circle'}></i>
                      </div>
                    </h5>
                  </div>
                  <div className={'collapse'} id={`accordion-tab-1-content-${i}`} aria-labelledby={'accordion-tab-1-heading-1'} data-parent={'#accordion-tab-1'}>
                    <div className={'card-body'}>{v.answer}</div>
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>)

  })