import React from 'react';

import TeamPage from '../components/Admin/Team';
import Title from '../components/Default/TitleLayout'

export default ({

}) => {

  return <div>
    <Title>
      <title>DOCS PANEL - TEAM</title>
    </Title>
    <TeamPage />
  </div>
};