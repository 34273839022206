import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { logout } from '../../../store/actions/users.actions';

import userImg from './../../../img/user.png'

import UserNavMenu from './UserNavMenu'
import EmployeeNavMenu from './EmployeeNavMenu'
import AdminNavMenu from './AdminNavMenu'

const cookies = new Cookies();

export default connect((s) => ({

}), {
  logout
})(
  ({
    show,
    user,
    role,
    logout
  }) => {

    const toggle = () => {
      document.getElementById('page-wrapper').classList.remove('toggled');
      if (cookies.get('sidebar') == 'true')
        cookies.set('sidebar', 'false')
      else
        cookies.set('sidebar', 'true')
    }

    return (show
      ? <nav id={'sidebar'} className={'sidebar-wrapper'}>
        <div className={'sidebar-content'}>
          <div className={'sidebar-brand'}>
            <Link to={'/'}>DOCS PANEL</Link>
            <div id={'close-sidebar'} onClick={() => toggle()}>
              <i className={'fas fa-times'}></i>
            </div>
          </div>
          <div className={'sidebar-header'}>
            <div className={'user-pic'}>
              <img className={'img-responsive img-rounded'} src={userImg} alt={'User picture'} />
            </div>
            <div className={'user-info'}>
              <span className={'user-name'}><strong>{user.login}</strong></span>
              <span className={'user-role'}>{user.accountType}</span>
              <span className={'user-status'}>Balance: <span className={'font-weight-bold'}>{user.balance}$</span></span>
            </div>
          </div>
          <div className='sidebar-menu'>
            {
              role == 'user'
                ? <UserNavMenu />
                : role == 'employee'
                  ? <EmployeeNavMenu />
                  : role == 'admin'
                    ? <AdminNavMenu />
                    : null
            }
          </div>
        </div>
        <div className={'sidebar-footer'}>
          <a className={'sidebar-footer-a cursor-pointer'} onClick={() => logout()}>
            <i className={'fa fa-power-off sidebar-footer-a-i'}></i>
          </a>
        </div>
      </nav>
      : null
    )

  })