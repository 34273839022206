import React from 'react';

import UserTicketsPage from '../components/Users/Tickets';
import AdminTicketsPage from '../components/Admin/Tickets';
import PageNotFound from './NotFound.view'
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - TICKETS</title>
      </Title>
      <UserTicketsPage />
    </div>
    : role == 'admin'
      ? <div>
        <Title>
          <title>DOCS PANEL - TICKETS</title>
        </Title>
        <AdminTicketsPage />
      </div>
      : <PageNotFound />
};