import { handleActions } from 'redux-actions';
import {
  registerAccountAsync,
  loginInAsync,
  getUserUpdatesAsync,
  clearErrorsAsync,
  requestConfirmCodeAsync,
  changePasswordAsync,
  changeEmailAsync,
  changeTelegramIdAsync,
  changeTypeAsync,
  setNotificationAsync,
  createDepositAsync,
  recoveryAsync,
  requestRecoveryAsync,
  logoutAsync,
  clearDepositLinkAsync,
  clearLoggedNowAsync
} from '../actions/users.actions';

import {
  payOrderAsync,
  deleteOrderAsync,
  takeOrderAsync
} from '../actions/orders.actions';

import {
  addNewTransactionAsync
} from '../actions/transactions.actions';

const initialState = {
  token: '',
  accountType: '',
  name: '',
  login: '',
  email: '',
  telegramId: '',
  loggedNow: false,
  sendNotification: {
    telegram: false,
    email: false
  },
  balance: 0,
  depositLink: '',
  inRecovery: false,
  recovered: false,
  isError: false,
  errors: []
};

export default handleActions({
  [takeOrderAsync.success]: (s, a) => ({ ...s, balance: a.payload.data && a.payload.data.success ? a.payload.data.balance : s.balance }),
  [addNewTransactionAsync.success]: (s, a) => ({ ...s, balance: a.payload.balance }),
  [payOrderAsync.success]: (s, a) => ({ ...s, balance: a.payload.data && a.payload.data.success ? a.payload.data.balance : s.balance, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [payOrderAsync.failed]: (s, a) => ({ ...s, balance: a.payload.data && a.payload.data.success ? a.payload.data.balance : s.balance, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [deleteOrderAsync.success]: (s, a) => ({ ...s, balance: a.payload.data && a.payload.data.success ? a.payload.data.balance : s.balance, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [deleteOrderAsync.failed]: (s, a) => ({ ...s, balance: a.payload.data && a.payload.data.success ? a.payload.data.balance : s.balance, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [requestConfirmCodeAsync.success]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [requestConfirmCodeAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [registerAccountAsync.success]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [registerAccountAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [requestRecoveryAsync.success]: (s, a) => ({ ...s, inRecovery: a.payload.data.success ? true : false, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [requestRecoveryAsync.failed]: (s, a) => ({ ...s, inRecovery: a.payload.data.success ? true : false, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [recoveryAsync.success]: (s, a) => ({ ...s, recovered: a.payload.data.success ? true : false, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [recoveryAsync.failed]: (s, a) => ({ ...s, recovered: a.payload.data.success ? true : false, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [createDepositAsync.success]: (s, a) => ({ ...s, depositLink: a.payload.data && a.payload.data.success ? a.payload.data.link : '', isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [createDepositAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [loginInAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, loggedNow: a.payload.data && a.payload.data.success ? true : false, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [loginInAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [getUserUpdatesAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : { token: '', accountType: '', name: '', login: '', email: '', telegramId: '', sendNotification: { telegram: false, email: false }, balance: 0, isError: false, errors: [] } }),
  [getUserUpdatesAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : { token: '', accountType: '', name: '', login: '', email: '', telegramId: '', sendNotification: { telegram: false, email: false }, balance: 0, isError: false, errors: [] } }),
  [changePasswordAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changePasswordAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeEmailAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeEmailAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeTelegramIdAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeTelegramIdAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeTypeAsync.success]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [changeTypeAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [setNotificationAsync.success]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [setNotificationAsync.failed]: (s, a) => ({ ...s, ...a.payload.data && a.payload.data.success ? a.payload.data.user : {}, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : [] }),
  [logoutAsync.success]: (s, a) => ({ ...s, token: '', accountType: '', name: '', login: '', email: '', telegramId: '', sendNotification: { telegram: false, email: false }, balance: 0, depositLink: '', isError: false, errors: [] }),
  [logoutAsync.failed]: (s, a) => ({ ...s, token: '', accountType: '', name: '', login: '', email: '', telegramId: '', sendNotification: { telegram: false, email: false }, balance: 0, depositLink: '', isError: false, errors: [] }),
  [clearErrorsAsync.success]: (s, a) => ({ ...s, isError: false, errors: [], inRecovery: false, recovered: false }),
  [clearErrorsAsync.failed]: (s, a) => ({ ...s, isError: false, errors: [], inRecovery: false, recovered: false }),
  [clearDepositLinkAsync.success]: (s, a) => ({ ...s, depositLink: '' }),
  [clearDepositLinkAsync.failed]: (s, a) => ({ ...s, depositLink: '' }),
  [clearLoggedNowAsync.success]: (s, a) => ({ ...s, loggedNow: false }),
  [clearLoggedNowAsync.failed]: (s, a) => ({ ...s, loggedNow: false }),
}, initialState);
