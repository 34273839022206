import axios from 'axios';
import { baseUrl } from '../config';

axios.defaults.withCredentials = true;

export default class faqsApi {
  static newFaq = (data) => axios.post(`${baseUrl}/api/faqs`, data);
  static editFaq = (data) => axios.post(`${baseUrl}/api/faqs/edit`, data);
  static deleteFaq = (data) => axios.delete(`${baseUrl}/api/faqs`, { data });
  static getFaqs = (token) => axios.get(`${baseUrl}/api/faqs?token=${token}`);
}