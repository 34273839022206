import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import RegistrationView from '../../../views/Registration.view';
import LoginView from '../../../views/Login.view';
import RecoveryView from '../../../views/Recovery.view';
import ProfileView from '../../../views/Profile.view';
import TransactionsListView from '../../../views/Transactions-list.view';
import TransactionsTopUpView from '../../../views/Transactions-topUp.view';
import OrdersListView from '../../../views/Orders-list.view';
import OrdersHistoryView from '../../../views/Orders-history.view';
import OrdersNewView from '../../../views/Orders-new.view';
import FAQView from '../../../views/FAQ.view';
import UsersView from '../../../views/Users.view';
import TeamView from '../../../views/Teams.view';
import TicketsView from '../../../views/Tickets.view';
import NotFoundView from '../../../views/NotFound.view';

import SideBar from '../Sidebar';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import { getUserUpdates } from './../../../store/actions/users.actions'
import { getFaqs } from './../../../store/actions/faqs.actions'
import { getLast, addNewTransaction } from './../../../store/actions/transactions.actions'
import { getCurrentSettings } from './../../../store/actions/settings.actions'
import { getBackgrounds, getCamerModels, getGoods, getCountrys, getStates, getDocsTypes } from '../../../store/actions/goods.actions'
import { getOrders, addNewOrder, editCurrentOrder, deleteOldOrder } from '../../../store/actions/orders.actions'
import { getTickets, newTicket, closedTicket } from '../../../store/actions/tickets.actions'

const cookies = new Cookies();
const adminType = 'admin';
const RestrictAccessRoute = ({ exact, path, render, forAuth = true, forAdmin = false, accountType, auth, to }) => forAuth && auth && forAdmin && accountType == adminType ? <Route exact={exact} path={path} render={render} /> : forAuth && auth && !forAdmin ? <Route exact={exact} path={path} render={render} /> : !forAuth && !auth ? <Route exact={exact} path={path} render={render} /> : path == location.pathname ? <Redirect to={to} /> : null;

export default connect((s) => ({
  user: s.user
}), {
  getUserUpdates,
  getFaqs,
  getLast,
  addNewTransaction,
  getCurrentSettings,
  getBackgrounds,
  getCamerModels,
  getGoods,
  getOrders,
  getTickets,
  newTicket,
  closedTicket,
  addNewOrder,
  editCurrentOrder,
  deleteOldOrder,
  getCountrys,
  getStates,
  getDocsTypes
})(
  ({
    socket,
    user,
    getUserUpdates,
    getFaqs,
    getLast,
    addNewTransaction,
    getCurrentSettings,
    getBackgrounds,
    getCamerModels,
    getGoods,
    getOrders,
    getTickets,
    newTicket,
    closedTicket,
    addNewOrder,
    editCurrentOrder,
    deleteOldOrder,
    getCountrys,
    getStates,
    getDocsTypes
  }) => {
    const [didMount, didMountChange] = useState(false);

    const isAuth = () => user.token ? true : false;

    const isToggle = () => cookies.get('sidebar') == 'true' ? "page-wrapper chiller-theme toggled" : "page-wrapper chiller-theme"

    const toggle = () => {
      document.getElementById('page-wrapper').classList.add('toggled');
      if (cookies.get('sidebar') == 'true')
        cookies.set('sidebar', 'false');
      else
        cookies.set('sidebar', 'true');
    }

    useEffect(() => {

      socket.on('order-new', order => user.accountType == 'employee' ? addNewOrder(order) : null)
      socket.on('order-edit', order => editCurrentOrder(order))
      socket.on('order-delete', order => {
        if (user.accountType == 'employee')
          deleteOldOrder(order)
        if (order.transaction)
          addNewTransaction({ transaction: order.transaction })
      })
      socket.on('transaction-new', (data) => addNewTransaction(data))
      socket.on('orders-delete', (data) => getOrders(user.token))
      socket.on('new-ticket', (data) => newTicket(data))
      socket.on('ticket-closed', (data) => closedTicket(data))
    }, [])

    useEffect(() => {
      if (!user.token)
        getCurrentSettings()

      getUserUpdates(user.token)
      didMountChange(true)
    }, [])

    useEffect(() => {

      if (didMount && user.token) {
        getFaqs(user.token)
        getLast({ token: user.token })
        getCurrentSettings(user.token)
        getBackgrounds(user.token)
        getCamerModels(user.token)
        getGoods(user.token)
        getOrders(user.token)
        getTickets(user.token)
        getCountrys()
        getStates()
        getDocsTypes()
        socket.emit('init', user.token)
      }

    }, [user])

    return (
      <div id={'page-wrapper'} className={isToggle()}>
        <a id={'show-sidebar'} className={'btn btn-sm btn-dark'} href={'#'} onClick={() => toggle()}>
          <i className="fas fa-bars"></i>
        </a>
        <SideBar show={isAuth()} user={user} role={user.accountType} />
        <main className={isAuth() ? 'h-100 page-content' : 'h-100 page-content pr-3 pl-3'}>
          <div className={'h-100 container-fluid p-0'}>
            <div className={'app'}>
              <div className={'app-content'}>
                <Switch>
                  <RestrictAccessRoute exact={true} path={'/'} render={() => <OrdersListView role={user.accountType} adminHome={true} />} forAuth={true} auth={isAuth()} to={'/login'} />

                  {/* start default routes */}
                  <RestrictAccessRoute exact={true} path={'/login'} render={() => <LoginView />} forAuth={false} auth={isAuth()} to={'/'} />
                  <RestrictAccessRoute exact={true} path={'/recovery/:recovery'} render={(props) => <RecoveryView {...props} />} forAuth={false} auth={isAuth()} to={'/'} />
                  <RestrictAccessRoute exact={true} path={'/recovery'} render={(props) => <RecoveryView {...props} />} forAuth={false} auth={isAuth()} to={'/'} />
                  {
                    location.hostname == 'new.docs-dev.com' || location.hostname == 'localhost'
                      ? <RestrictAccessRoute exact={true} path={'/reg'} render={() => <RegistrationView />} forAuth={false} auth={isAuth()} to={'/'} />
                      : null
                  }
                  {/* end default routes */}

                  {/* start roles routes */}
                  <RestrictAccessRoute exact={true} path={'/faq'} render={() => <FAQView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/profile'} render={() => <ProfileView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/transactions'} render={() => <TransactionsListView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/topup'} render={() => <TransactionsTopUpView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/orders'} render={() => <OrdersListView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/history'} render={() => <OrdersHistoryView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/newOrder'} render={() => <OrdersNewView role={user.accountType} />} forAuth={true} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/users'} render={() => <UsersView />} forAuth={true} forAdmin={true} accountType={user.accountType} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/team'} render={() => <TeamView />} forAuth={true} forAdmin={true} accountType={user.accountType} auth={isAuth()} to={'/login'} />
                  <RestrictAccessRoute exact={true} path={'/tickets'} render={() => <TicketsView role={user.accountType} />} forAuth={true} forAdmin={false} accountType={user.accountType} auth={isAuth()} to={'/login'} />
                  {/* end roles routes */}

                  <Route render={() => <NotFoundView />} />
                </Switch>
              </div>
            </div>
          </div>
        </main>
      </div>
    )

  }
)
