import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router'
import { bindAsyncActions } from '../../common/store/helpers';
import {
  addOrder, addOrderAsync,
  addNewOrder, addNewOrderAsync,
  editOrder, editOrderAsync,
  editCurrentOrder, editCurrentOrderAsync,
  addMistake, addMistakeAsync,
  takeOrder, takeOrderAsync,
  payOrder, payOrderAsync,
  deleteOrder, deleteOrderAsync,
  deleteOldOrder, deleteOldOrderAsync,
  getOrders, getOrdersAsync,
  redirectToTopUp, redirectToTopUpAsync,
  redirectToOrders, redirectToOrdersAsync,
  clearOrdersErrors, clearOrdersErrorsAsync
} from '../actions/orders.actions';

import ordersApi from '../../common/api/orders.api';

function plugeWorker() {
  return true;
}

function* redirectToTopUpWorker() {
  yield put(push('/topup'))
}

function* redirectToOrdersWorker() {
  yield put(push('/orders'))
}

function addNewOrderWorker(data) {
  return data;
}

function deleteOldOrderWorker(data) {
  return data;
}

function editCurrentOrderWorker(data) {
  return data;
}

export function* ordersSaga() {
  yield takeEvery(addOrder, bindAsyncActions(addOrderAsync)(ordersApi.addOrder));
  yield takeEvery(addNewOrder, bindAsyncActions(addNewOrderAsync)(addNewOrderWorker));
  yield takeEvery(editOrder, bindAsyncActions(editOrderAsync)(ordersApi.editOrder));
  yield takeEvery(editCurrentOrder, bindAsyncActions(editCurrentOrderAsync)(editCurrentOrderWorker));
  yield takeEvery(addMistake, bindAsyncActions(addMistakeAsync)(ordersApi.addMistake));
  yield takeEvery(takeOrder, bindAsyncActions(takeOrderAsync)(ordersApi.takeOrder));
  yield takeEvery(payOrder, bindAsyncActions(payOrderAsync)(ordersApi.payOrder));
  yield takeEvery(deleteOrder, bindAsyncActions(deleteOrderAsync)(ordersApi.deleteOrder));
  yield takeEvery(deleteOldOrder, bindAsyncActions(deleteOldOrderAsync)(deleteOldOrderWorker));
  yield takeEvery(getOrders, bindAsyncActions(getOrdersAsync)(ordersApi.getOrders));
  yield takeEvery(redirectToTopUp, bindAsyncActions(redirectToTopUpAsync)(redirectToTopUpWorker));
  yield takeEvery(redirectToOrders, bindAsyncActions(redirectToOrdersAsync)(redirectToOrdersWorker));
  yield takeEvery(clearOrdersErrors, bindAsyncActions(clearOrdersErrorsAsync)(plugeWorker));
}
