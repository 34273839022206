import React from 'react';

import UserTransactionsTopUpPage from '../components/Users/Transactions/topUp';
import AdminTransactionsTopUpPage from '../components/Admin/Payments';
import PageNotFound from './NotFound.view'
import Title from './../components/Default/TitleLayout'

export default ({
  role
}) => {

  return role == 'user'
    ? <div>
      <Title>
        <title>DOCS PANEL - TOP UP</title>
      </Title>
      <UserTransactionsTopUpPage />
    </div>
    : role == 'employee'
      ? <PageNotFound />
      : role == 'admin'
        ? <div>
          <Title>
            <title>DOCS PANEL - PAYMENTS</title>
          </Title>
          <AdminTransactionsTopUpPage />
        </div>
        : <PageNotFound />
};