import { handleActions } from 'redux-actions';
import {
  createNewFaqAsync,
  editFaqAsync,
  deleteFaqAsync,
  getFaqsAsync
} from '../actions/faqs.actions';

const initialState = {
  faqs: [],
  isError: false,
  errors: []
};

const editFaq = (faqs, editedFaq) => {
  if (faqs.find(f => f._id == editedFaq._id))
    faqs[faqs.indexOf(faqs.find(f => f._id == editedFaq._id))] = editedFaq
  return JSON.parse(JSON.stringify(faqs));
}
const deleteFaq = (faqs, faqId) => {
  var idx = faqs.indexOf(faqs.find(f => f._id == faqId));
  if (idx != -1) {
    faqs.splice(idx, 1);
    return JSON.parse(JSON.stringify(faqs));
  }
  return JSON.parse(JSON.stringify(faqs));
}

export default handleActions({
  [createNewFaqAsync.success]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? [...s.faqs, a.payload.data.faq] : s.faqs, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [createNewFaqAsync.failed]: (s, a) => ({ ...s, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [editFaqAsync.success]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? editFaq(s.faqs, a.payload.data.faq) : s.faqs, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [editFaqAsync.failed]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? editFaq(s.faqs, a.payload.data.faq) : s.faqs, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [deleteFaqAsync.success]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? deleteFaq(s.faqs, a.payload.data.faq) : s.faqs, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [deleteFaqAsync.failed]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? deleteFaq(s.faqs, a.payload.data.faq) : s.faqs, isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [getFaqsAsync.success]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? a.payload.data.faqs : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
  [getFaqsAsync.failed]: (s, a) => ({ ...s, faqs: a.payload.data && a.payload.data.success ? a.payload.data.faqs : [], isError: a.payload.data && a.payload.data.success ? false : true, errors: a.payload.data && !a.payload.data.success ? a.payload.data.errors : ['something went wrong'] }),
}, initialState);
