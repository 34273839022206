import { createActionFactory } from '../../common/store/helpers';

const factory = createActionFactory('TRANSACTIONS');

export const depositFor = factory.create('DEPOSIT_FOR');
export const depositForAsync = factory.createAsync('DEPOSIT_FOR_ASYNC');

export const getLast = factory.create('GET_LAST');
export const getLastAsync = factory.createAsync('GET_LAST_ASYNC');

export const addNewTransaction = factory.create('ADD_NEW_TRANSACTION');
export const addNewTransactionAsync = factory.createAsync('ADD_NEW_TRANSACTION_ASYNC');

export const clearErrors = factory.create('CLEAR_ERRORS');
export const clearErrorsAsync = factory.createAsync('CLEAR_ERRORS_ASYNC');