import axios from 'axios';
import { baseUrl } from '../config';

axios.defaults.withCredentials = true;

export default class ordersApi {
  static addOrder = (data) => axios.post(`${baseUrl}/api/orders`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static editOrder = (data) => axios.post(`${baseUrl}/api/orders/edit`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static addMistake = (data) => axios.post(`${baseUrl}/api/orders/mistake`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  static takeOrder = (data) => axios.post(`${baseUrl}/api/orders/take`, data);
  static deleteOrder = (data) => axios.delete(`${baseUrl}/api/orders`, { data });
  static payOrder = (data) => axios.post(`${baseUrl}/api/orders/pay`, data);
  static getOrders = (token) => axios.get(`${baseUrl}/api/orders?token=${token}`);
}