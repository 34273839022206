import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../common/store/helpers';
import {
  createNewFaq, createNewFaqAsync,
  editFaq, editFaqAsync,
  deleteFaq, deleteFaqAsync,
  getFaqs, getFaqsAsync
} from '../actions/faqs.actions';

import faqsApi from '../../common/api/faqs.api';

export function* faqsSaga() {
  yield takeEvery(createNewFaq, bindAsyncActions(createNewFaqAsync)(faqsApi.newFaq));
  yield takeEvery(editFaq, bindAsyncActions(editFaqAsync)(faqsApi.editFaq));
  yield takeEvery(deleteFaq, bindAsyncActions(deleteFaqAsync)(faqsApi.deleteFaq));
  yield takeEvery(getFaqs, bindAsyncActions(getFaqsAsync)(faqsApi.getFaqs));
}
