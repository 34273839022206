import 'rc-pagination/assets/index.css';

import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';

import { baseUrl } from '../../../common/config';
import { connect } from 'react-redux';
import { editOrder, addMistake, deleteOrder, payOrder, clearOrdersErrors, redirectToTopUp } from '../../../store/actions/orders.actions';

import SeeModal from './seeModal';
import MistakeModal from './MistakeModal';

const status = {
  'waiting for payment': 'rgb(255, 0, 0,.4)',
  'accepted': 'rgb(0, 171, 68,.4)',
  'in progress': 'rgb(74, 134, 232,.4)',
  'done': 'rgb(153, 0, 255,.4)',
  'confirm': 'rgb(183, 183, 18,.43)'
}

export default connect((s) => ({
  user: s.user,
  orders: s.orders,
  backgrounds: s.goods.backgrounds,
  cameraModels: s.goods.cameraModels,
  goods: s.goods.goods
}), {
  editOrder,
  addMistake,
  deleteOrder,
  payOrder,
  redirectToTopUp,
  clearOrdersErrors
})(
  ({
    user,
    orders,
    backgrounds,
    cameraModels,
    goods,
    editOrder,
    addMistake,
    deleteOrder,
    payOrder,
    redirectToTopUp,
    clearOrdersErrors
  }) => {
    const [CurrentPage, changeCurrentPage] = useState(1)
    const [seeModal, changeSeeModal] = useState({ show: false, order: null })
    const [mistakeModal, changeMistakeModal] = useState({ show: false, order: null })
    const [processedOrders, changeProcessedOrders] = useState(orders.orders);
    const [ordersSort, changeOrderssSort] = useState({ date: 'default', amount: 'default' })

    const sortOrders = (name, value) => changeOrderssSort({ ...ordersSort, [name]: value })

    const openSeeModal = (id) => changeSeeModal({ ...seeModal, show: true, order: orders.orders.find(o => o._id == id) });

    const openMistakeModal = (id) => changeMistakeModal({ ...seeModal, show: true, order: orders.orders.find(o => o._id == id) });

    useEffect(() => {
      if (seeModal.order)
        changeSeeModal({ ...seeModal, order: orders.orders.find(o => o._id == seeModal.order._id) })
    }, [orders.orders])

    useEffect(() => {
      let sortedOrders = JSON.parse(JSON.stringify(orders.orders.filter(v => new Date(v.createdAt).getTime() + 24 * 3600 * 1000 < new Date().getTime() && (v.status == 'waiting for payment' || v.status == 'done'))))

      if (ordersSort.date == 'up')
        sortedOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (ordersSort.date == 'down')
        sortedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (ordersSort.amount == 'up')
        sortedOrders.sort((a, b) => a.fullPriceUser - b.fullPriceUser)
      else if (ordersSort.amount == 'down')
        sortedOrders.sort((a, b) => b.fullPriceUser - a.fullPriceUser)

      changeProcessedOrders(sortedOrders)
    }, [orders.orders])

    useEffect(() => {
      let sortedOrders = JSON.parse(JSON.stringify(orders.orders.filter(v => new Date(v.createdAt).getTime() + 24 * 3600 * 1000 < new Date().getTime() && (v.status == 'waiting for payment' || v.status == 'done'))))

      if (ordersSort.date == 'up')
        sortedOrders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      else if (ordersSort.date == 'down')
        sortedOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (ordersSort.amount == 'up')
        sortedOrders.sort((a, b) => a.fullPriceUser - b.fullPriceUser)
      else if (ordersSort.amount == 'down')
        sortedOrders.sort((a, b) => b.fullPriceUser - a.fullPriceUser)

      changeProcessedOrders(sortedOrders)
    }, [ordersSort])

    useEffect(() => {

      if (orders.errors.indexOf('invalid user balance') != -1) {
        clearOrdersErrors()
        redirectToTopUp()
      }

    }, [orders.errors])

    useEffect(() => {
      if (orders.errors.length > 0)
        clearOrdersErrors()
      return () => {
        if (orders.errors.length > 0)
          clearOrdersErrors()
      }
    }, [])

    return (<div className={'w-100 h-100'}>
      <SeeModal
        show={seeModal.show}
        order={seeModal.order}
        close={() => changeSeeModal({ ...seeModal, show: false })}
        editOrder={editOrder}
        user={user}
        backgrounds={backgrounds}
        cameraModels={cameraModels}
        goods={goods}
      />
      <MistakeModal
        show={mistakeModal.show}
        order={mistakeModal.order}
        close={() => changeMistakeModal({ ...mistakeModal, show: false })}
        addMistake={addMistake}
        user={user}
      />
      <h2>Orders</h2>
      {
        orders.isError && orders.errors.length > 0
          ? <div className={'w-100 text-center text-danger'}>
            {
              orders.errors.map((v, i) => <span key={i} className={'w-100 d-inline-block'}>{v}</span>)
            }
          </div>
          : null
      }
      <table className={'table table-striped'}>
        <thead>
          <tr>
            <th scope={'col'} >ID</th>
            <th onClick={() => sortOrders('date', ordersSort.date == 'default' ? 'up' : ordersSort.date == 'up' ? 'down' : 'default')} scope='col' className={ordersSort.date == 'default' ? 'th-sm' : ordersSort.date == 'down' ? 'th-sm down' : ordersSort.date == 'up' ? 'th-sm up' : 'th-sm'}>Date</th>
            <th scope={'col'}>Ready Time</th>
            <th scope={'col'}>Status</th>
            <th onClick={() => sortOrders('amount', ordersSort.amount == 'default' ? 'up' : ordersSort.amount == 'up' ? 'down' : 'default')} scope='col' className={ordersSort.amount == 'default' ? 'th-sm' : ordersSort.amount == 'down' ? 'th-sm down' : ordersSort.amount == 'up' ? 'th-sm up' : 'th-sm'}>Amount</th>
            <th scope={'col'}>Services</th>
            <th scope={'col'}>Info</th>
            <th scope={'col'}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            processedOrders.map((v, i) => i < CurrentPage * 5 && i >= (CurrentPage - 1) * 5
              ? (
                <tr key={i} style={{ backgroundColor: status[v.status] }}>
                  <th>{i + 1}</th>
                  <td>{v.createdAt}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <span key={i} className={'w-100 d-inline-block'}>{vs.generalInformation.completeTime}</span>
                      ))
                    }
                  </td>
                  <td>{v.status}</td>
                  <td>{v.fullPriceUser}</td>
                  <td>
                    {
                      v.services.map((vs, i) => (
                        <span key={i} className={'w-100 d-inline-block'}>{vs.name}</span>
                      ))
                    }
                  </td>
                  <td>
                    {
                      !v.employee
                        ? <span className={'w-100 d-inline-block'}>Order no in process</span>
                        : v.employee && v.status == 'in progress'
                          ? <span className={'w-100 d-inline-block'}>Order in process</span>
                          : <span className={'w-100 d-inline-block'}>Order ready</span>
                    }
                  </td>
                  <td>
                    <button
                      className={v.status == 'waiting for payment' ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={v.status == 'waiting for payment' ? false : true}
                      onClick={() => confirm('pay this order?') ? payOrder({ token: user.token, orderId: v._id }) : null}
                    >pay</button>
                    <button
                      className={v.status == 'done' || v.status == 'confirm' ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={v.status == 'done' || v.status == 'confirm' ? false : true}
                      onClick={() => location.href = `${baseUrl}/api/orders/download?token=${user.token}&orderId=${v._id}`}
                    >download</button>
                    <button className={'btn btn-primary m-1'} onClick={() => openSeeModal(v._id)}>see</button>
                    <button
                      className={(v.status == 'done' || v.status == 'confirm') && !v.mistake ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={(v.status == 'done' || v.status == 'confirm') && !v.mistake ? false : true}
                      onClick={() => openMistakeModal(v._id)}
                    >order mistake</button>
                    <button
                      className={v.status == 'waiting for payment' || v.status == 'accepted' ? 'btn btn-primary m-1' : 'btn btn-secondary m-1'}
                      disabled={v.status == 'waiting for payment' || v.status == 'accepted' ? false : true}
                      onClick={() => confirm('delete this order?') ? deleteOrder({ token: user.token, orderId: v._id }) : null}
                    >remove</button>
                  </td>
                </tr>
              )
              : null)
          }
        </tbody>
      </table>
      <div className={'p-2'}>
        <Pagination className={'ant-pagination'} onChange={(current) => changeCurrentPage(current)} defaultCurrent={1} total={Math.ceil(processedOrders.length / 5) * 10} />
      </div>
    </div>)

  })