import 'babel-polyfill';
import './css/style.sass';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import ReactDOM from 'react-dom';
import io from 'socket.io-client';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { baseUrl } from './common/config';

import createStore from './store/createStore';

import AppLayout from './components/Default/AppLayout';

const history = createBrowserHistory();
const { store, persistor } = createStore(history);

const socket = io(baseUrl);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <AppLayout socket={socket} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
