import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ImageModal from '../../Default/ImageModal';

import { baseUrl } from '../../../common/config';

const generateId = (length = 13, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => {
  let result = '';

  for (let i = 0; i < length; i++)
    result += characters.charAt(Math.floor(Math.random() * characters.length));

  return result;
}

export default ({
  show,
  order,
  close,
  editOrder,
  user,
  backgrounds,
  cameraModels,
  goods
}) => {
  const [IsSended, changeIsSended] = useState(false);
  const [currentOrder, changeCurrentOrder] = useState(order ? { ...order, data: { status: order.status, images: order.images.map(v => ({ _id: v._id, id: generateId(), img: v.link })), text: order.text, _id: order._id }, services: order.services.map(s => ({ ...s, serviceInformation: s.serviceInformation.map(si => ({ ...si, gid: generateId() })) })) } : order);
  const [sampleModal, changeSampleModal] = useState({
    show: false,
    image: null
  })

  const editCurrentOrderField = (name, value, nameSec) => changeCurrentOrder(nameSec ? { ...currentOrder, [name]: { ...currentOrder[name], [nameSec]: value } } : { ...currentOrder, [name]: value })

  const editCurrentOrderServiceField = (id, name, value, nameSec) => changeCurrentOrder({ ...currentOrder, services: currentOrder.services.map(v => v._id == id ? { ...nameSec ? { ...v, [name]: { ...v[name], [nameSec]: value } } : { ...v, [name]: value } } : v) })

  const addOrderImages = () => changeCurrentOrder({ ...currentOrder, data: { ...currentOrder.data, images: [...currentOrder.data.images, { id: generateId(), img: null }] } })

  const setOrderNewImage = (id, file) => changeCurrentOrder({ ...currentOrder, data: { ...currentOrder.data, images: currentOrder.data.images.map(v => v.id == id ? { ...v, img: file } : v) } })

  const deleteOrderImage = (id) => changeCurrentOrder({ ...currentOrder, data: { ...currentOrder.data, images: currentOrder.data.images.filter(v => v.id != id) } })

  const submitEditOrder = () => {
    if (IsSended)
      return alert('Order editing. Wait please')
    let formData = new FormData();
    formData.append('token', user.token);
    formData.append('order', JSON.stringify(currentOrder.data));
    formData.append('services', JSON.stringify(currentOrder.services));

    for (let i = 0; i < currentOrder.data.images.length; i++)
      formData.append(generateId(), currentOrder.data.images[i].img);

    editOrder(formData)
    changeIsSended(true)
    // close()
  }

  const showSample = (image) => changeSampleModal({ show: true, image: image })

  useEffect(() => {

    if (IsSended) {
      close()
      changeIsSended(false)
    } else
      changeCurrentOrder(order ? { ...order, data: { status: order.status, images: order.images.map(v => ({ _id: v._id, id: generateId(), img: v.link })), text: order.text, _id: order._id }, services: order.services.map(s => ({ ...s, serviceInformation: s.serviceInformation.map(si => ({ ...si, gid: generateId() })) })) } : order)

  }, [order])

  return (show && currentOrder
    ? <div className={'position-absolute w-100 h-100 see-wrapper d-flex justify-content-center align-items-center'}>
      <div className={'position-absolute w-100 h-100 see-background'}></div>
      <ImageModal
        show={sampleModal.show}
        close={() => changeSampleModal({ show: false, image: null })}
        image={sampleModal.image}
      />
      <div className={'w-50 p-2 border rounded bg-white see-form'}>
        <div className={'w-100'}>
          <div onClick={close} className={'float-right cursor-pointer'}>
            <i className={'fas fa-times'}></i>
          </div>
        </div>
        <div className={'w-100'}>
          {
            currentOrder.mistake ?
              <form className={'m-0'} onSubmit={(e) => e.preventDefault()}>
                <div className={'form-row'}>
                  <div className={'form-group col-md-12'}>
                    <label>Description</label>
                    <textarea required value={currentOrder.mistake.description} readOnly className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className={'form-group col-md-12'}>
                    <label className={'w-100'}>File</label>
                    <button className={'btn btn-success'} type={'button'} onClick={() => showSample(currentOrder.mistake.file)}>show</button>
                  </div>
                </div>
              </form>
              : null
          }
          <h5>Order</h5>
          <form className={'m-0'} onSubmit={(e) => { e.preventDefault(); submitEditOrder() }}>
            <div className={'form-row'}>
              <div className={'form-group col-md-6'}>
                <label>Status</label>
                <select className={'form-control'} required value={currentOrder.data.status} onChange={(e) => editCurrentOrderField('data', e.target.value, 'status')}>
                  <option value={'cancel'}>cancel</option>
                  <option value={'in progress'}>in progress</option>
                  <option value={'done'}>done</option>
                </select>
              </div>
              <div className={'form-group col-md-6'}>
                <label>Created</label>
                <input value={currentOrder.createdAt} type={'text'} className={'form-control'} readOnly />
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-6'}>
                <label>Full price</label>
                <input value={currentOrder.fullPriceEmployee} type={'text'} className={'form-control'} readOnly />
              </div>
              <div className={'form-group col-md-6'}>
                <label>Mistake</label>
                <input value={currentOrder.mistake ? 'exist' : 'not exist'} type={'text'} className={'form-control'} readOnly />
              </div>
            </div>
            {
              currentOrder.text
                ? <div className={'form-row'}>
                  <div className={'form-group col-md-12'}>
                    <label>Text</label>
                    <textarea value={currentOrder.text} readOnly className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
                  </div>
                </div>
                : null
            }
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label >Services</label>
              </div>
              {
                currentOrder.services.map((v, i) => (
                  <div className={'form-group col-md-12 border-bottom mb-2'} key={i}>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label >Name</label>
                        <input value={v.name} type={'text'} className={'form-control'} readOnly />
                      </div>
                      <div className={'form-group col-md-6'}>
                        <label >Complete time</label>
                        <input value={v.generalInformation.completeTime} required onChange={(e) => editCurrentOrderServiceField(v._id, 'generalInformation', e.target.value, 'completeTime')} type={'text'} className={'form-control'} />
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label >Service price</label>
                        <input value={v.priceEmplyee} type={'text'} className={'form-control'} readOnly />
                      </div>
                      <div className={'form-group col-md-6'}>
                        <label >Camera model</label>
                        <select value={cameraModels.find(c => c._id == v.generalInformation.cameraModel)._id} readOnly className={'form-control'} >
                          {
                            cameraModels.map((v, i) => (
                              <option key={i} value={v._id}>{v.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-6'}>
                        <label className={'w-100'}>Sample</label>
                        <button className={'btn btn-success'} type={'button'} onClick={() => showSample(v.generalInformation.sample)}>show sample</button>
                      </div>
                      <div className={'form-group col-md-6'}>
                        <label className={'w-100'}>Background</label>
                        <button className={'btn btn-success'} type={'button'} onClick={() => showSample(backgrounds.find(b => b._id == v.generalInformation.background).image)}>show background</button>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>How take picture</label>
                        <textarea value={v.generalInformation.howTakePicture} readOnly className={'w-100'} style={{ height: '100px', resize: 'none' }} ></textarea>
                      </div>
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>Information</label>
                      </div>
                      {
                        v.serviceInformation.map((s, i) => (
                          <div className={'form-group col-md-6'} key={i}>
                            <label className={'w-100'}>{s.name}</label>
                            {
                              goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).fileUploader
                                ? <div className={'w-100 row d-flex align-items-center'}>
                                  <div className={'col-md-6 d-flex justify-content-center'}>
                                    <button className={'btn btn-success'} type={'button'} onClick={() => showSample(s.value)}>show iamge</button>
                                  </div>
                                </div>
                                : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).textInput
                                  ? <input value={s.value} readOnly type={'text'} className={'form-control'} required maxLength={goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).maxLength > 0 ? goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).maxLength : 'false'} />
                                  : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).select
                                    ? <select value={s.value} readOnly className={'form-control'}>
                                      {
                                        goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).values.map((sfv, i) => (
                                          <option value={sfv} key={i}>{sfv}</option>
                                        ))
                                      }
                                    </select>
                                    : goods.find(g => g._id == v._id).fields.find(gf => gf.name == s.name).datePicker
                                      ? <input value={s.value} type="text" className={'form-control'} readOnly />
                                      : null
                            }
                          </div>
                        ))
                      }
                    </div>
                    <div className={'form-row'}>
                      <div className={'form-group col-md-12'}>
                        <label className={'w-100'}>Additional</label>
                      </div>
                      {
                        v.additionalServices.map((asv, asi) => (

                          <div className="w-100 mb-2" key={asi}>
                            {
                              asv.serviceInformation.map((asvsiv, asvsii) => (
                                <div className={'form-group col-md-6'} key={asvsii}>
                                  <label className={'w-100'}>{asvsiv.name}</label>
                                  {
                                    goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).fileUploader
                                      ? <div className={'w-100 row d-flex align-items-center'}>
                                        <div className={'col-md-6 d-flex justify-content-center'}>
                                          <button className={'btn btn-success'} type={'button'} onClick={() => showSample(asvsiv.value)}>show iamge</button>
                                        </div>
                                      </div>
                                      : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).textInput
                                        ? <input value={asvsiv.value} readOnly type={'text'} className={'form-control'} required={asvsiv.required} maxLength={goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).maxLength > 0 ? goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).maxLength : 'false'} />
                                        : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).select
                                          ? <select required={asvsiv.required} value={asvsiv.value} readOnly className={'form-control'}>
                                            {
                                              goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).values.map((sfv, i) => (
                                                <option value={sfv} key={i}>{sfv}</option>
                                              ))
                                            }
                                          </select>
                                          : goods.find(g => g._id == asv._id).fields.find(gf => gf.name == asvsiv.name).datePicker
                                            ? <DatePicker required={asvsiv.required} dateFormat={'MMMM d, yyyy'} className={'form-control'} selected={new Date(asvsiv.value)} readOnly />
                                            : null
                                  }
                                </div>
                              ))
                            }
                          </div>

                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            <h5>Order settings</h5>
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label>text</label>
                <textarea value={currentOrder.data.text} onChange={(e) => editCurrentOrderField('data', e.target.value, 'text')} className={'w-100'} style={{ height: '100px', resize: 'none' }}></textarea>
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-12'}>
                <label className={'w-100'}>Images</label>
                <button className={'btn btn-primary'} type={'button'} onClick={() => addOrderImages()}>add</button>
              </div>
              {
                currentOrder.data.images.map((v, i) => (
                  <div className={'form-group col-md-12 row'} key={i}>
                    <div className={'col-md-3 d-flex justify-content-center align-items-center'}>
                      <input onChange={(e) => e.target.files[0].size / 1024 / 1024 <= 150 ? setOrderNewImage(v.id, e.target.files[0]) : e.target.value = null} required={v.img != null ? false : true} accept={'image/*, .rar, .zip, .pdf'} type={'file'} className={'w-100'} />
                    </div>
                    <div className={'col-md-3 d-flex justify-content-center align-items-center'}>
                      <button className={'btn btn-success'} type={'button'} onClick={() => v.img && typeof v.img == 'string' ? showSample(baseUrl + 'static/downloads/' + v.img) : null}>open image</button>
                    </div>
                    <div className={'col-md-6 d-flex justify-content-center align-items-center'}>
                      <button className={'btn btn-success'} type={'button'} onClick={() => deleteOrderImage(v.id)}>delete</button>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={'form-row'}>
              <div className={'form-group col-md-12 d-flex justify-content-end pl-3 pr-3'}>
                <button disabled={(currentOrder.status != 'confirm') || currentOrder.mistake ? false : true} className={'btn btn-primary'} type={'submit'}>edit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div >
    : null)

}