import { takeEvery } from 'redux-saga/effects';
import { bindAsyncActions } from '../../common/store/helpers';
import {
  addBackground, addBackgroundAsync,
  deleteBackground, deleteBackgroundAsync,
  getBackgrounds, getBackgroundsAsync,
  addCamerModel, addCamerModelAsync,
  deleteCamerModel, deleteCamerModelAsync,
  getCamerModels, getCamerModelsAsync,
  clearGoodsErrors, clearGoodsErrorsAsync,
  addGood, addGoodAsync,
  deleteGood, deleteGoodAsync,
  editGood, editGoodAsync,
  getGoods, getGoodsAsync,
  deleteOldOrders, deleteOldOrdersAsync,
  getCountrys, getCountrysAsync,
  getStates, getStatesAsync,
  getDocsTypes, getDocsTypesAsync,
} from '../actions/goods.actions';

import ordersApi from '../../common/api/goods.api';

function plugeWorker() {
  return true;
}

export function* goodsSaga() {
  yield takeEvery(addBackground, bindAsyncActions(addBackgroundAsync)(ordersApi.addBackground));
  yield takeEvery(deleteBackground, bindAsyncActions(deleteBackgroundAsync)(ordersApi.deleteBackground));
  yield takeEvery(getBackgrounds, bindAsyncActions(getBackgroundsAsync)(ordersApi.getBackgrounds));
  yield takeEvery(addCamerModel, bindAsyncActions(addCamerModelAsync)(ordersApi.addCameraModel));
  yield takeEvery(deleteCamerModel, bindAsyncActions(deleteCamerModelAsync)(ordersApi.deleteCameraModel));
  yield takeEvery(getCamerModels, bindAsyncActions(getCamerModelsAsync)(ordersApi.getCameraModels));
  yield takeEvery(addGood, bindAsyncActions(addGoodAsync)(ordersApi.addGood));
  yield takeEvery(deleteGood, bindAsyncActions(deleteGoodAsync)(ordersApi.deleteGood));
  yield takeEvery(editGood, bindAsyncActions(editGoodAsync)(ordersApi.editGood));
  yield takeEvery(getGoods, bindAsyncActions(getGoodsAsync)(ordersApi.getGoods));
  yield takeEvery(deleteOldOrders, bindAsyncActions(deleteOldOrdersAsync)(ordersApi.deleteOldOrders));
  yield takeEvery(getCountrys, bindAsyncActions(getCountrysAsync)(ordersApi.getCountrys));
  yield takeEvery(getStates, bindAsyncActions(getStatesAsync)(ordersApi.getStates));
  yield takeEvery(getDocsTypes, bindAsyncActions(getDocsTypesAsync)(ordersApi.getDocsTypes));
  yield takeEvery(clearGoodsErrors, bindAsyncActions(clearGoodsErrorsAsync)(plugeWorker));
}
